import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import {
  downloadDocumentById,
  getInsuranceDocumentsList,
} from "../../../services/documentService";
import { getEmailHistoryByEmployeeId } from "../../../services/employeeService";
import { Dialog } from "@mui/material";
import Loading from "../../Utility/Loading";
import { sendViactivEmailApi } from "../../../services/insuranceFormService";

const EmployeeEmailHistory = ({ employeeId }) => {
  const [documents, setDocuments] = useState([]);
  const [additionalDocuments, setAdditionalDocuments] = useState([]);
  const [emails, setEmails] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isResend, setIsResend] = useState(false);
  const [sendDisabled, setSendDisabled] = useState(false);
  const { t } = useTranslation("common");

  useEffect(() => {
    if (employeeId !== undefined) {
      fetchInsuranceDocumentsList(employeeId);
      fetchEmailHistory(employeeId);
    }
  }, [employeeId]);

  useEffect(() => {}, [documents, additionalDocuments]);

  const fetchInsuranceDocumentsList = async (employeeId) => {
    try {
      if (!employeeId) return;
      const response = await getInsuranceDocumentsList(
        localStorage.token,
        employeeId
      );
      setDocuments(response.required);
      setAdditionalDocuments(response.additional);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchEmailHistory = async (employeeId) => {
    try {
      if (!employeeId) return;
      const response = await getEmailHistoryByEmployeeId(
        localStorage.token,
        employeeId
      );
      if (localStorage.role === "ROLE_ADMIN") {
        response = response.filter((e) => e.type !== "MANAGER_EMAIL");
      }
      const resend = response.some((e) => e.type === "FORM_EMAIL");
      setIsResend(resend);
      setEmails(response);
    } catch (error) {
      console.log(error);
    }
  };

  const convertToGermanDateTime = (inputDateTimeString) => {
    const inputDate = new Date(inputDateTimeString + " UTC");
    const germanFormattedDateTime = inputDate.toLocaleString("de-DE", {
      timeZone: "Europe/Berlin", // German timezone
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: false, // Use 12-hour format
    });

    return germanFormattedDateTime;
  };

  const handleMouseEnter = (e) => {
    e.target.style.textDecoration = "underline";
  };

  const handleMouseLeave = (e) => {
    e.target.style.textDecoration = "none";
  };

  const getEmailTypeText = (emailType) => {
    if (emailType === "FORM_EMAIL") {
      return t("applicationFormEmail");
    } else if (emailType === "PENSION_INSURANCE_EMAIL") {
      return t("pensionInsuranceEmail");
    } else if (emailType === "BILLING_REF_NUMBER_EMAIL") {
      return t("billingRefNumberEmail");
    } else if (emailType === "MANAGER_EMAIL") {
      return t("managerEmail");
    }
  };

  const handleDownload = async (docId, fileName) => {
    console.log("download docId ", docId);
    try {
      setIsLoading(true);
      const response = await downloadDocumentById(
        employeeId,
        docId,
        localStorage.token
      );
      const blob = new Blob([response.data], {
        type: response.headers["content-type"],
      });
      const fileURL = URL.createObjectURL(blob);
      window.open(fileURL);
      const link = document.createElement("a");
      link.href = fileURL;
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      setIsLoading(false);
      link.click();
      link.parentNode.removeChild(link);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  const getEmailStatusText = (status) => {
    if (status === "EMAIL_SENT") {
      return (
        <span style={{ fontWeight: 500, color: "green" }}>
          {" "}
          {t("emailSent")}
        </span>
      );
    } else if (status === "EMAIL_SEND_IN_PROGRESS") {
      return (
        <span style={{ fontWeight: 500, color: "blue" }}>
          {" "}
          {t("emailSendingInProgress")}
        </span>
      );
    } else if (status === "EMAIL_PENDING") {
      return (
        <span style={{ fontWeight: 500, color: "blue" }}>
          {" "}
          {t("emailPending")}
        </span>
      );
    } else if (status === "EMAIL_ERROR") {
      return (
        <span style={{ fontWeight: 500, color: "red" }}>
          {" "}
          Email {t("error")}
        </span>
      );
    } else if (status === "EMAIL_REPLY_RECEIVED") {
      return (
        <span style={{ fontWeight: 500, color: "orange" }}>
          {" "}
          {t("replyReceived")}
        </span>
      );
    }
  };

  const sendEmail = (employeeId, providerTypeName) => {
    setSendDisabled(true);
    if (providerTypeName === "VIACTIVKRANKENKASSE") {
      sendViactivEmail(employeeId);
    } else if (providerTypeName === "MOBILKRANKENKASSE") {
      //To implement
      alert(t("notImplemented"));
    }
  };

  const sendViactivEmail = async (employeeId) => {
    try {
      setIsLoading(true);
      const response = await sendViactivEmailApi(
        employeeId,
        localStorage.token
      );
      if (response) {
        toast.success(t("mailWillBeSent"));
      } else {
        toast.error(t("mailRequestFailed"));
      }
      setIsLoading(false);
      setSendDisabled(false);
    } catch (error) {
      console.log(error);
      setSendDisabled(false);
      setIsLoading(false);
    }
  };

  return (
    <div className="card" style={{ padding: "5px" }}>
      <div className="card-body">
        <div className="card-alert alert alert-info mb-3">
          {t("in_case_edited_or_resend")}
          <button
            className="btn btn-success ml-4"
            disabled={sendDisabled}
            onClick={() => {
              sendEmail(employeeId, "VIACTIVKRANKENKASSE");
            }}
          >
            {isResend ? t("resend") : t("sendEmail")}
          </button>
        </div>
        <div className="card-title" style={{ fontWeight: 600 }}>
          {t("email_sent_history")}
        </div>
        <div className="table-responsive">
          <table className="table table-vcenter table-hover text-nowrap mb-0">
            <thead className="thead-light">
              <tr>
                <th style={{ fontWeight: 600 }}>Email</th>
                {/* <th style={{ fontWeight: 600 }}>Subject</th> */}
                <th style={{ fontWeight: 600 }}>{t("attachments")}</th>
                <th style={{ fontWeight: 600 }}>Status</th>
              </tr>
            </thead>
            <tbody className="table-hover">
              {emails &&
                emails.map((email, index) => (
                  <tr key={index}>
                    <td>
                      <div className="font-15" style={{ fontWeight: 600 }}>
                        {getEmailTypeText(email.type)}
                        <br />
                        <span style={{ fontWeight: 400, fontSize: "14px" }}>
                          <strong>{t("to")} : </strong>
                          {email.sentTo}
                        </span>{" "}
                        <br />
                        <span style={{ fontWeight: 400, fontSize: "14px" }}>
                          <strong>{t("onCol")} : </strong>
                          {convertToGermanDateTime(email.emailSentTime)}
                        </span>
                      </div>
                    </td>
                    {/* <td>
                      <div
                        className="font-15"
                        style={{
                          //width: "300px",
                          overflow: "hidden",
                          wordWrap: "break-word",
                          overflowWrap: "break-word",
                          whiteSpace: "normal",
                        }}
                      >
                        {email.subject}
                      </div>
                    </td> */}
                    <td>
                      <div className="font-15">
                        {email.noOfAttachments > 0 &&
                          Object.entries(email.attachmentMap).map(
                            ([key, value]) => (
                              <div
                                onClick={() => handleDownload(value, key)}
                                style={{ cursor: "pointer", color: "blue" }}
                                onMouseEnter={handleMouseEnter}
                                onMouseLeave={handleMouseLeave}
                                key={key}
                              >
                                {key}
                              </div>
                            )
                          )}
                      </div>
                    </td>
                    <td>{getEmailStatusText(email.status)}</td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
      <Dialog
        open={isLoading}
        style={{ backdropFilter: "blur(2px)" }}
        fullWidth
      >
        <div
          className="flex flex-col justify-center"
          style={{ backgroundColor: "transparent" }}
        >
          <div className="mx-auto" style={{ backgroundColor: "transparent" }}>
            <Loading />
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default EmployeeEmailHistory;

import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import {
  activateEmployeeById,
  disableEmployeeById,
  editEmployeeProfile,
  editEmployeePublicProfile,
  editPersonalInfo,
  getEmployeeDetailsById,
  getMyEmployeeDetails,
} from "../../../services/employeeService";
import Select from "react-select";
import ImageUploadDialog from "../../Utility/ImageUploadDialog";
import { Briefcase, Camera, MapPin } from "lucide-react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { useStateContext } from "../../../context/Context";
import DocumentUploader from "../../Utility/DocumentUploader";
import ApplicationForm from "../../HRMS/InsuranceProvider/Viactiv/ApplicationForm";
import PreviousInsurance from "../InsuranceProvider/Viactiv/PreviousInsurance";
import FamilyInsuranceForm from "../InsuranceProvider/Viactiv/FamilyInsuranceForm";
import CardDeliveryForm from "../InsuranceProvider/Viactiv/CardDeliveryForm";
import EmployeeEmailHistory from "./EmployeeEmailHistory";

const EmployeeDetails = () => {
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const id = queryParams.get("id");
  const { t, i18n } = useTranslation("employee");
  const [employeeData, setEmployeeData] = useState({});
  const [employeeId, setEmployeeId] = useState("");
  const [uploadedImageUrl, setUploadedImageUrl] = useState(null);
  const [isUploadDialogOpen, setIsUploadDialogOpen] = useState(false);
  const fixNavbar = useSelector((state) => state.settings.isFixNavbar);
  const [isEditMode, setIsEditMode] = useState(false);

  const [title, setTitle] = useState();
  const [firstName, setFirstName] = useState();
  const [lastName, setLastName] = useState();
  const [gender, setGender] = useState();
  const [mobile, setMobile] = useState();
  const [phone, setPhone] = useState();
  const [position, setPosition] = useState();
  const [employmentType, setEmploymentType] = useState("");
  const [birthDate, setBirthDate] = useState();
  const [birthName, setBirthName] = useState();
  const [placeOfBirth, setPlaceOfBirth] = useState();
  const [countryOfBirth, setCountryOfBirth] = useState();
  // const [maritalStatus, setMaritalStatus] = useState();
  const [nationality, setNationality] = useState("");
  const [street, setStreet] = useState();
  const [location, setLocation] = useState();
  const [houseNumber, setHouseNumber] = useState();
  const [postcode, setPostcode] = useState();
  const [careOf, setCareOf] = useState();
  const [idNumber, setIdNumber] = useState();
  const [pensionInsuranceNumber, setPensionInsuranceNumber] = useState();
  const { role, setRole, authorities, setAuthorities } = useStateContext();
  const [age, setAge] = useState(null);
  const [disabled, setDisabled] = useState(true);
  const [email, setEmail] = useState();
  const [options, setOptions] = useState();
  const [employmentDate, setEmploymentDate] = useState();
  const [employmentEndDate, setEmploymentEndDate] = useState();
  const [employeeStatusActive, setEmployeeStatusActive] = useState(true);
  const [employeeStatus, setEmployeeStatus] = useState("ACTIVE");

  useEffect(() => {
    setEmployeeId(id);
    getEmployeeDetails();
  }, []);

  useEffect(() => {
    if (isEditMode && street && houseNumber) {
      fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?address=${street},${houseNumber}&key=AIzaSyDkY2B02fMYLIeidKuLtW0jcuodt2a-b54`
      )
        .then((response) => response.json())
        .then((data) => {
          const results = data.results;
          const options = results.map((result) => {
            const postcode = result.address_components.find((component) =>
              component.types.includes("postal_code")
            )?.long_name;
            const location = result.address_components.find((component) =>
              component.types.includes("locality")
            )?.long_name;
            return { postcode, location };
          });
          setOptions(options);
          if (options.length > 0) {
            setPostcode(options[0].postcode);
            setLocation(options[0].location);
          }
        });
    }
  }, [street, houseNumber]);

  useEffect(() => {
    setAuthorities(localStorage.authorities);
  }, [employeeId]);

  useEffect(() => {}, [employeeStatus]);

  const handleImageUpload = (imageUrl) => {
    setUploadedImageUrl(imageUrl);
    setIsUploadDialogOpen(false);
  };

  const locale = {
    en: "en-US",
    de: "de-DE",
  };

  const formatDate = (date) => {
    if (date === undefined) return "";
    const dateObject = new Date(date);
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
    };
    return new Intl.DateTimeFormat(locale[i18n.language], options).format(
      dateObject
    );
  };

  const getEmployeeDetails = async () => {
    try {
      let response;
      if (id) {
        response = await getEmployeeDetailsById(localStorage.token, id);
      } else {
        response = await getMyEmployeeDetails(localStorage.token);
        setEmployeeId(response.id);
      }
      console.log("employee data ", response);
      setEmployeeData(response);
      setAge(calculateAge(response.personalInfoDto?.birthDate));
      setPublicProfile(response);
      setPersonalInfo(response);
    } catch (error) {
      console.log(error);
    }
  };
  const calculateAge = (birthdate) => {
    if (!birthdate) {
      return null;
    }

    const today = new Date();
    const birthdateArray = birthdate.split("-");
    const birthYear = parseInt(birthdateArray[0], 10);
    const birthMonth = parseInt(birthdateArray[1], 10) - 1; // Months are zero-based
    const birthDay = parseInt(birthdateArray[2], 10);

    const birthdateObj = new Date(birthYear, birthMonth, birthDay);

    let age = today.getFullYear() - birthdateObj.getFullYear();
    const monthDiff = today.getMonth() - birthdateObj.getMonth();

    if (
      monthDiff < 0 ||
      (monthDiff === 0 && today.getDate() < birthdateObj.getDate())
    ) {
      age--;
    }

    return age;
  };

  const setPersonalInfo = (employee) => {
    setBirthDate(employee.personalInfoDto?.birthDate);
    setBirthName(employee.personalInfoDto?.birthName);
    setPlaceOfBirth(employee.personalInfoDto?.placeOfBirth);
    setCountryOfBirth(employee.personalInfoDto?.countryOfBirth);
    // setMaritalStatus(employee.personalInfoDto?.maritalStatus);
    setStreet(employee.personalInfoDto?.address?.street);
    setHouseNumber(employee.personalInfoDto?.address?.houseNumber);
    setPostcode(employee.personalInfoDto?.address?.postcode);
    setLocation(employee.personalInfoDto?.address?.location);
    setCareOf(employee.personalInfoDto?.address?.careOf);
    setNationality(employee.personalInfoDto?.nationality);
  };

  const setPublicProfile = (employee) => {
    setFirstName(employee.firstName);
    setLastName(employee.lastName);
    setGender(employee.gender);
    setPosition(employee.position);
    setMobile(employee.mobile);
    setPhone(employee.phone);
    setTitle(employee.title);
    setIdNumber(employee.idNumber);
    setPensionInsuranceNumber(employee.pensionInsuranceNumber);
    setEmploymentType(employee.employmentType);
    setEmail(employee.email);
    setEmploymentDate(employee.employmentDate);
    setEmploymentEndDate(employee.employmentEndDate);
    setEmployeeStatusActive(employee.status === "ACTIVE" ? true : false);
    setEmployeeStatus(employee.status);
  };

  const handleEdit = (e) => {
    e.preventDefault();
    setIsEditMode(true);
    setDisabled(false);
  };

  const handleCancel = () => {
    setIsEditMode(false);
    setDisabled(true);
    window.location.reload(false);
  };

  const handleEmployeeStatusToggle = () => {
    console.log("employeeStatus - ", employeeStatus);
    if (employeeStatus === "ACTIVE") {
      handleDisableClick();
    } else {
      handleActivateClick();
    }
    setEmployeeStatusActive(!employeeStatusActive);
  };

  const handleActivateClick = async () => {
    try {
      console.log("clicked activate");
      const response = await activateEmployeeById(
        localStorage.token,
        employeeId
      );
      if (response) {
        setEmployeeStatus("ACTIVE");
        toast.success(t("activateSuccess"));
      } else {
        toast.error(t("somethingWentWrong"));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleDisableClick = async () => {
    try {
      const response = await disableEmployeeById(
        localStorage.token,
        employeeId
      );
      if (response) {
        setEmployeeStatus("INACTIVE");
        toast.success(t("disableSuccess"));
      } else {
        toast.error(t("somethingWentWrong"));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const GENDER_OPTIONS = [
    { value: "MALE", label: t("male") },
    { value: "FEMALE", label: t("female") },
    { value: "OTHER", label: t("other") },
  ];

  const MARITAL_STATUS_OPTIONS = [
    { value: "SINGLE", label: "Ledig" },
    { value: "MARRIED", label: "Verheiratet" },
    { value: "LIVING_SEPARATELY", label: "Getrennt Lebend" },
    { value: "DIVORCED", label: "Geschieden" },
    { value: "WIDOWED", label: "Verwitwet" },
    {
      value: "CIVIL_PARTNERSHIP",
      label: "Eingetragene Lebenspartnerschaft nach dem LPartG",
    },
  ];

  const NATIONALITY_OPTIONS = [
    { value: "AFGHAN", label: t("AFGHAN") },
    { value: "ALBANIAN", label: t("ALBANIAN") },
    { value: "ALGERIAN", label: t("ALGERIAN") },
    { value: "AMERICAN", label: t("AMERICAN") },
    { value: "ANDORRAN", label: t("ANDORRAN") },
    { value: "ANGOLAN", label: t("ANGOLAN") },
    { value: "ANTIGUANS", label: t("ANTIGUANS") },
    { value: "ARGENTINEAN", label: t("ARGENTINEAN") },
    { value: "ARMENIAN", label: t("ARMENIAN") },
    { value: "AUSTRALIAN", label: t("AUSTRALIAN") },
    { value: "AUSTRIAN", label: t("AUSTRIAN") },
    { value: "AZERBAIJANI", label: t("AZERBAIJANI") },
    { value: "BAHAMIAN", label: t("BAHAMIAN") },
    { value: "BAHRAINI", label: t("BAHRAINI") },
    { value: "BANGLADESHI", label: t("BANGLADESHI") },
    { value: "BARBADIAN", label: t("BARBADIAN") },
    { value: "BARBUDANS", label: t("BARBUDANS") },
    { value: "BATSWANA", label: t("BATSWANA") },
    { value: "BELARUSIAN", label: t("BELARUSIAN") },
    { value: "BELGIAN", label: t("BELGIAN") },
    { value: "BELIZEAN", label: t("BELIZEAN") },
    { value: "BENINESE", label: t("BENINESE") },
    { value: "BHUTANESE", label: t("BHUTANESE") },
    { value: "BOLIVIAN", label: t("BOLIVIAN") },
    { value: "BOSNIAN", label: t("BOSNIAN") },
    { value: "BRAZILIAN", label: t("BRAZILIAN") },
    { value: "BRITISH", label: t("BRITISH") },
    { value: "BRUNEIAN", label: t("BRUNEIAN") },
    { value: "BULGARIAN", label: t("BULGARIAN") },
    { value: "BURKINABE", label: t("BURKINABE") },
    { value: "BURMESE", label: t("BURMESE") },
    { value: "BURUNDIAN", label: t("BURUNDIAN") },
    { value: "CAMBODIAN", label: t("CAMBODIAN") },
    { value: "CAMEROONIAN", label: t("CAMEROONIAN") },
    { value: "CANADIAN", label: t("CANADIAN") },
    { value: "CAPE_VERDEAN", label: t("CAPE_VERDEAN") },
    { value: "CENTRAL_AFRICAN", label: t("CENTRAL_AFRICAN") },
    { value: "CHADIAN", label: t("CHADIAN") },
    { value: "CHILEAN", label: t("CHILEAN") },
    { value: "CHINESE", label: t("CHINESE") },
    { value: "COLOMBIAN", label: t("COLOMBIAN") },
    { value: "COMORAN", label: t("COMORAN") },
    { value: "CONGOLESE", label: t("CONGOLESE") },
    { value: "COSTA_RICAN", label: t("COSTA_RICAN") },
    { value: "CROATIAN", label: t("CROATIAN") },
    { value: "CUBAN", label: t("CUBAN") },
    { value: "CYPRIOT", label: t("CYPRIOT") },
    { value: "CZECH", label: t("CZECH") },
    { value: "DANISH", label: t("DANISH") },
    { value: "DJIBOUTI", label: t("DJIBOUTI") },
    { value: "DOMINICAN", label: t("DOMINICAN") },
    { value: "DUTCH", label: t("DUTCH") },
    { value: "EAST_TIMORESE", label: t("EAST_TIMORESE") },
    { value: "ECUADORIAN", label: t("ECUADORIAN") },
    { value: "EGYPTIAN", label: t("EGYPTIAN") },
    { value: "EMIRIAN", label: t("EMIRIAN") },
    { value: "EQUATORIAL_GUINEAN", label: t("EQUATORIAL_GUINEAN") },
    { value: "ERITREAN", label: t("ERITREAN") },
    { value: "ESTONIAN", label: t("ESTONIAN") },
    { value: "ETHIOPIAN", label: t("ETHIOPIAN") },
    { value: "FIJIAN", label: t("FIJIAN") },
    { value: "FILIPINO", label: t("FILIPINO") },
    { value: "FINNISH", label: t("FINNISH") },
    { value: "FRENCH", label: t("FRENCH") },
    { value: "GABONESE", label: t("GABONESE") },
    { value: "GAMBIAN", label: t("GAMBIAN") },
    { value: "GEORGIAN", label: t("GEORGIAN") },
    { value: "GERMAN", label: t("GERMAN") },
    { value: "GHANAIAN", label: t("GHANAIAN") },
    { value: "GREEK", label: t("GREEK") },
    { value: "GRENADIAN", label: t("GRENADIAN") },
    { value: "GUATEMALAN", label: t("GUATEMALAN") },
    { value: "GUINEA_BISSAUAN", label: t("GUINEA_BISSAUAN") },
    { value: "GUINEAN", label: t("GUINEAN") },
    { value: "GUYANESE", label: t("GUYANESE") },
    { value: "HAITIAN", label: t("HAITIAN") },
    { value: "HERZEGOVINIAN", label: t("HERZEGOVINIAN") },
    { value: "HONDURAN", label: t("HONDURAN") },
    { value: "HUNGARIAN", label: t("HUNGARIAN") },
    { value: "I_KIRIBATI", label: t("I-KIRIBATI") },
    { value: "ICELANDER", label: t("ICELANDER") },
    { value: "INDIAN", label: t("INDIAN") },
    { value: "INDONESIAN", label: t("INDONESIAN") },
    { value: "IRANIAN", label: t("IRANIAN") },
    { value: "IRAQI", label: t("IRAQI") },
    { value: "IRISH", label: t("IRISH") },
    { value: "ISRAELI", label: t("ISRAELI") },
    { value: "ITALIAN", label: t("ITALIAN") },
    { value: "IVORIAN", label: t("IVORIAN") },
    { value: "JAMAICAN", label: t("JAMAICAN") },
    { value: "JAPANESE", label: t("JAPANESE") },
    { value: "JORDANIAN", label: t("JORDANIAN") },
    { value: "KAZAKHSTANI", label: t("KAZAKHSTANI") },
    { value: "KENYAN", label: t("KENYAN") },
    { value: "KITTIAN_AND_NEVISIAN", label: t("KITTIAN_AND_NEVISIAN") },
    { value: "KUWAITI", label: t("KUWAITI") },
    { value: "KYRGYZ", label: t("KYRGYZ") },
    { value: "LAOTIAN", label: t("LAOTIAN") },
    { value: "LATVIAN", label: t("LATVIAN") },
    { value: "LEBANESE", label: t("LEBANESE") },
    { value: "LIBERIAN", label: t("LIBERIAN") },
    { value: "LIBYAN", label: t("LIBYAN") },
    { value: "LIECHTENSTEINER", label: t("LIECHTENSTEINER") },
    { value: "LITHUANIAN", label: t("LITHUANIAN") },
    { value: "LUXEMBOURGER", label: t("LUXEMBOURGER") },
    { value: "MACEDONIAN", label: t("MACEDONIAN") },
    { value: "MALAGASY", label: t("MALAGASY") },
    { value: "MALAWIAN", label: t("MALAWIAN") },
    { value: "MALAYSIAN", label: t("MALAYSIAN") },
    { value: "MALDIVIAN", label: t("MALDIVIAN") },
    { value: "MALIAN", label: t("MALIAN") },
    { value: "MALTESE", label: t("MALTESE") },
    { value: "MARSHALLESE", label: t("MARSHALLESE") },
    { value: "MAURITANIAN", label: t("MAURITANIAN") },
    { value: "MAURITIAN", label: t("MAURITIAN") },
    { value: "MEXICAN", label: t("MEXICAN") },
    { value: "MICRONESIAN", label: t("MICRONESIAN") },
    { value: "MOLDOVAN", label: t("MOLDOVAN") },
    { value: "MONACAN", label: t("MONACAN") },
    { value: "MONGOLIAN", label: t("MONGOLIAN") },
    { value: "MOROCCAN", label: t("MOROCCAN") },
    { value: "MOSOTHO", label: t("MOSOTHO") },
    { value: "MOTSWANA", label: t("MOTSWANA") },
    { value: "MOZAMBICAN", label: t("MOZAMBICAN") },
    { value: "NAMIBIAN", label: t("NAMIBIAN") },
    { value: "NAURUAN", label: t("NAURUAN") },
    { value: "NEPALESE", label: t("NEPALESE") },
    { value: "NEW_ZEALANDER", label: t("NEW_ZEALANDER") },
    { value: "NI_VANUATU", label: t("NI_VANUATU") },
    { value: "NICARAGUAN", label: t("NICARAGUAN") },
    { value: "NIGERIAN", label: t("NIGERIAN") },
    { value: "NIGERIEN", label: t("NIGERIEN") },
    { value: "NORTH_KOREAN", label: t("NORTH_KOREAN") },
    { value: "NORTHERN_IRISH", label: t("NORTHERN_IRISH") },
    { value: "NORWEGIAN", label: t("NORWEGIAN") },
    { value: "OMANI", label: t("OMANI") },
    { value: "PAKISTANI", label: t("PAKISTANI") },
    { value: "PALAUAN", label: t("PALAUAN") },
    { value: "PANAMANIAN", label: t("PANAMANIAN") },
    { value: "PAPUA_NEW_GUINEAN", label: t("PAPUA_NEW_GUINEAN") },
    { value: "PARAGUAYAN", label: t("PARAGUAYAN") },
    { value: "PERUVIAN", label: t("PERUVIAN") },
    { value: "POLISH", label: t("POLISH") },
    { value: "PORTUGUESE", label: t("PORTUGUESE") },
    { value: "QATARI", label: t("QATARI") },
    { value: "ROMANIAN", label: t("ROMANIAN") },
    { value: "RUSSIAN", label: t("RUSSIAN") },
    { value: "RWANDAN", label: t("RWANDAN") },
    { value: "SAINT_LUCIAN", label: t("SAINT_LUCIAN") },
    { value: "SALVADORAN", label: t("SALVADORAN") },
    { value: "SAMOAN", label: t("SAMOAN") },
    { value: "SAN_MARINESE", label: t("SAN_MARINESE") },
    { value: "SAO_TOMEAN", label: t("SAO_TOMEAN") },
    { value: "SAUDI", label: t("SAUDI") },
    { value: "SCOTTISH", label: t("SCOTTISH") },
    { value: "SENEGALESE", label: t("SENEGALESE") },
    { value: "SERBIAN", label: t("SERBIAN") },
    { value: "SEYCHELLOIS", label: t("SEYCHELLOIS") },
    { value: "SIERRA_LEONEAN", label: t("SIERRA_LEONEAN") },
    { value: "SINGAPOREAN", label: t("SINGAPOREAN") },
    { value: "SLOVAKIAN", label: t("SLOVAKIAN") },
    { value: "SLOVENIAN", label: t("SLOVENIAN") },
    { value: "SOLOMON_ISLANDER", label: t("SOLOMON_ISLANDER") },
    { value: "SOMALI", label: t("SOMALI") },
    { value: "SOUTH_AFRICAN", label: t("SOUTH_AFRICAN") },
    { value: "SOUTH_KOREAN", label: t("SOUTH_KOREAN") },
    { value: "SPANISH", label: t("SPANISH") },
    { value: "SRI_LANKAN", label: t("SRI_LANKAN") },
    { value: "SUDANESE", label: t("SUDANESE") },
    { value: "SURINAMER", label: t("SURINAMER") },
    { value: "SWAZI", label: t("SWAZI") },
    { value: "SWEDISH", label: t("SWEDISH") },
    { value: "SWISS", label: t("SWISS") },
    { value: "SYRIAN", label: t("SYRIAN") },
    { value: "TAIWANESE", label: t("TAIWANESE") },
    { value: "TAJIK", label: t("TAJIK") },
    { value: "TANZANIAN", label: t("TANZANIAN") },
    { value: "THAI", label: t("THAI") },
    { value: "TOGOLESE", label: t("TOGOLESE") },
    { value: "TONGAN", label: t("TONGAN") },
    {
      value: "TRINIDADIAN_OR_TOBAGONIAN",
      label: t("TRINIDADIAN_OR_TOBAGONIAN"),
    },
    { value: "TUNISIAN", label: t("TUNISIAN") },
    { value: "TURKISH", label: t("TURKISH") },
    { value: "TUVALUAN", label: t("TUVALUAN") },
    { value: "UGANDAN", label: t("UGANDAN") },
    { value: "UKRAINIAN", label: t("UKRAINIAN") },
    { value: "URUGUAYAN", label: t("URUGUAYAN") },
    { value: "UZBEKISTANI", label: t("UZBEKISTANI") },
    { value: "VENEZUELAN", label: t("VENEZUELAN") },
    { value: "VIETNAMESE", label: t("VIETNAMESE") },
    { value: "WELSH", label: t("WELSH") },
    { value: "YEMENITE", label: t("YEMENITE") },
    { value: "ZAMBIAN", label: t("ZAMBIAN") },
    { value: "ZIMBABWEAN", label: t("ZIMBABWEAN") },
  ];

  const EMPLOYMENT_TYPE_OPTIONS = [
    { value: "FULL_TIME", label: t("fullTime") },
    { value: "PART_TIME", label: t("partTime") },
    { value: "MINI_JOB", label: t("miniJob") },
  ];

  const handleSave = () => {
    const profile = {
      title: title,
      firstName: firstName,
      lastName: lastName,
      gender: gender,
      position: position,
      mobile: mobile,
      phone: phone,
      email: email,
      employmentDate: employmentDate,
      employmentEndDate: employmentEndDate,
      employmentType: employmentType,
      pensionInsuranceNumber: pensionInsuranceNumber,
      birthDate: birthDate,
      birthName: birthName,
      placeOfBirth: placeOfBirth,
      countryOfBirth: countryOfBirth,
      // maritalStatus: maritalStatus,
      nationality: nationality,
      address: {
        street: street,
        houseNumber: houseNumber,
        postcode: postcode,
        location: location,
        careOf: careOf,
      },
    };
    console.log("profile info update", profile);
    updateProfile(profile);
    setIsEditMode(false);
  };

  const updateProfile = async (profile) => {
    try {
      const response = await editEmployeeProfile(
        localStorage.token,
        id,
        profile
      );
      if (response) {
        toast.success("Erfolgreich geupdated");
        window.location.reload(false);
      } else {
        toast.error(t("somethingWentWrong"));
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <div>
        <div>
          <div className={`section-body ${fixNavbar ? "marginTop" : ""} `}>
            <div className="container-fluid">
              <div className="card">
                <div
                  className="card-body"
                  style={{ paddingTop: "8px", paddingBottom: "8px" }}
                >
                  <div className="media">
                    <div className="relative">
                      <img
                        src={
                          employeeData.image ||
                          uploadedImageUrl ||
                          "../assets/images/userAvatar.png"
                        }
                        alt="Employee Avatar"
                        width={80}
                        height={80}
                        onClick={() => setIsUploadDialogOpen(true)}
                        style={{
                          maxWidth: "80px",
                          maxHeight: "80px",
                          width: "80px",
                          height: "80px",
                        }}
                        className="border-[1px] border-[#0B0540] rounded-full"
                      />
                      <div
                        className="absolute bottom-0 right-0 cursor-pointer z-10 bg-blue-100 p-1 rounded-2xl"
                        onClick={() => setIsUploadDialogOpen(true)}
                      >
                        <Camera />
                      </div>
                    </div>
                    <div className="media-body">
                      <div className="flex items-center ml-2">
                        <span
                          className="px-2"
                          style={{
                            color: "indigo",
                            fontSize: "24px",
                            fontWeight: 600,
                          }}
                        >
                          {employeeData.name}
                        </span>
                        <span className="text-gray-600 ml-[16px] text-xs font-semibold">
                          {employeeStatus === "ACTIVE" ? (
                            <span className="bg-green-400 px-4 py-1 rounded-full text-white">
                              Aktiv
                            </span>
                          ) : (
                            <span className="bg-red-300 px-4 py-1 rounded-full text-gray-700">
                              Inaktiv
                            </span>
                          )}
                        </span>
                      </div>
                      <div
                        className="text-gray-600"
                        style={{ fontWeight: 500 }}
                      >
                        <p
                          className="text-sm flex"
                          style={{ margin: "0px 0px 0px 14px" }}
                        >
                          {employeeData.position && (
                            <>
                              <span className="mt-0.5">
                                <Briefcase size={15} />
                              </span>
                              <span className="ml-1 capitalize">
                                {employeeData.position}
                              </span>
                            </>
                          )}
                          <span
                            className={`mt-0.5 ${
                              employeeData.position ? "ml-3" : ""
                            }`}
                          >
                            <MapPin size={15} />
                          </span>
                          <span className="ml-0.5 capitalize">
                            {employeeData.personalInfoDto?.address?.location}
                          </span>
                        </p>
                        <div
                          className="flex"
                          style={{ margin: "2px 0px 0px 14px" }}
                        >
                          <p className="text-sm">
                            {t("from")}{" "}
                            {formatDate(employeeData.employmentDate)}.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div
                      className="mt-2 mr-2 items-right"
                      style={{
                        position: "relative",
                        display: "inline-block",
                        width: "50px", // Increased width to accommodate the text and switch
                        height: "30px",
                      }}
                    >
                      <input
                        type="checkbox"
                        id="toggle"
                        checked={employeeStatusActive}
                        onChange={() => handleEmployeeStatusToggle()}
                        style={{
                          opacity: "0",
                          width: "0",
                          height: "0",
                        }}
                        title={"Change employee status"}
                        data-toggle="tooltip"
                      />
                      <label
                        htmlFor="toggle"
                        style={{
                          position: "absolute",
                          cursor: "pointer",
                          top: "0",
                          left: "0",
                          right: "0",
                          bottom: "0",
                          backgroundColor: employeeStatusActive
                            ? "#34D399"
                            : "#ccc",
                          transition: ".4s",
                          borderRadius: "30px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          color: employeeStatusActive ? "white" : "black",
                          fontWeight: "bold",
                        }}
                      >
                        <div
                          style={{
                            position: "absolute",
                            top: "50%",
                            left: employeeStatusActive ? "65%" : "5%",
                            transform: "translate(0%, -50%)",
                            width: "15px",
                            height: "15px",
                            backgroundColor: employeeStatusActive
                              ? "white"
                              : "black",
                            borderRadius: "50%",
                            transition: ".4s",
                          }}
                        />
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="d-flex justify-content-between align-items-center mb-3">
                <div
                  className="card-header"
                  style={{
                    backgroundColor: "white",
                    width: "100%",
                    padding: "10px 20px 10px 20px",
                  }}
                >
                  <ul className="nav nav-tabs page-header-tab">
                    <li className="nav-item">
                      <a
                        className="nav-link active"
                        id="public-profile-tab"
                        data-toggle="tab"
                        href="#public-profile"
                        style={{
                          fontSize: "15px",
                          fontWeight: 600,
                          padding: "5px 0px",
                        }}
                      >
                        {t("publicProfile")}
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link"
                        id="insurance-details-tab"
                        data-toggle="tab"
                        href="#insurance-details"
                        style={{
                          fontSize: "15px",
                          fontWeight: 600,
                          padding: "5px 0px",
                        }}
                      >
                        {t("insuranceApplicationForm")}
                      </a>
                    </li>
                    {age !== 0 && age >= 55 && (
                      <li className="nav-item">
                        <a
                          className="nav-link"
                          id="previous-ins-tab"
                          data-toggle="tab"
                          href="#previous-ins"
                          style={{
                            fontSize: "15px",
                            fontWeight: 600,
                            padding: "5px 0px",
                          }}
                        >
                          {t("previousInsuranceForm")}
                        </a>
                      </li>
                    )}
                    <li className="nav-item">
                      <a
                        className="nav-link"
                        id="family-ins-tab"
                        data-toggle="tab"
                        href="#family-ins"
                        style={{
                          fontSize: "15px",
                          fontWeight: 600,
                          padding: "5px 0px",
                        }}
                      >
                        {t("familyInsuranceForm")}
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link"
                        id="address-form-tab"
                        data-toggle="tab"
                        href="#address-form"
                        style={{
                          fontSize: "15px",
                          fontWeight: 600,
                          padding: "5px 0px",
                        }}
                      >
                        Vollmacht
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link"
                        id="documents-tab"
                        data-toggle="tab"
                        href="#documents"
                        style={{
                          fontSize: "15px",
                          fontWeight: 600,
                          padding: "5px 0px",
                        }}
                      >
                        {t("documents")}
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link"
                        id="email-history-tab"
                        data-toggle="tab"
                        href="#email-history"
                        style={{
                          fontSize: "15px",
                          fontWeight: 600,
                          padding: "5px 0px",
                        }}
                      >
                        Email History
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="section-body">
            <div className="container-fluid">
              {!employeeData.profileComplete && (
                <div className="card-alert alert alert-danger mb-2">
                  {t("employeeWarningMsg")}
                </div>
              )}
              <div className="tab-content">
                <div
                  className="tab-pane fade show active"
                  id="public-profile"
                  role="tabpanel"
                >
                  <div className="card px-4">
                    <div className="card-header">
                      <h3
                        className="card-title"
                        style={{ fontWeight: 600, color: "#4B0082" }}
                      >
                        Mitarbeiterprofil
                      </h3>
                      {localStorage.role !== "ROLE_EMPLOYEE" && (
                        <div className="card-options">
                          {disabled && !isEditMode && (
                            <>
                              <button
                                className="btn btn-primary ml-10"
                                style={{ textTransform: "uppercase" }}
                                onClick={handleEdit}
                              >
                                {t("edit")}
                              </button>
                            </>
                          )}
                          {isEditMode && (
                            <>
                              <button
                                className="btn btn-primary ml-10"
                                style={{ textTransform: "uppercase" }}
                                onClick={handleSave}
                              >
                                {t("save")}
                              </button>
                              <button
                                className="btn btn-info ml-10"
                                style={{ textTransform: "uppercase" }}
                                onClick={handleCancel}
                              >
                                {t("cancel")}
                              </button>
                            </>
                          )}
                        </div>
                      )}
                    </div>
                    <hr style={{ margin: 0 }} />
                    <div className="card-body">
                      <div className="row clearfix">
                        <div className="col-lg-10 col-md-10">
                          <form className="card">
                            <h3
                              className="card-title"
                              style={{
                                fontWeight: 800,
                                fontSize: "15px",
                                color: "#6F7687",
                              }}
                            >
                              Persönlichen Daten
                            </h3>
                            <div className="row">
                              <div className="col-md-3 col-sm-12 mr-4">
                                <div className="form-group">
                                  <label className="form-label">
                                    {t("Title")}
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    disabled={disabled}
                                    placeholder="title"
                                    value={title}
                                    onChange={(e) => setTitle(e.target.value)}
                                  />
                                </div>
                              </div>
                              <div className="col-md-3 col-sm-12 mr-4">
                                <div className="form-group">
                                  <label className="form-label">
                                    {t("lastName")}
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    disabled={disabled}
                                    placeholder="Last Name"
                                    value={lastName}
                                    onChange={(e) =>
                                      setLastName(e.target.value)
                                    }
                                  />
                                </div>
                              </div>
                              <div className="col-md-3 col-sm-12 mr-4">
                                <div className="form-group">
                                  <label className="form-label">
                                    {t("firstName")}
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    disabled={disabled}
                                    placeholder="Enter Here"
                                    value={firstName}
                                    onChange={(e) =>
                                      setFirstName(e.target.value)
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="row mt-2">
                              <div className="col-md-3 col-sm-12 mr-4">
                                <div className="form-group">
                                  <label className="form-label">
                                    {t("gender")}
                                  </label>
                                  <Select
                                    options={GENDER_OPTIONS}
                                    value={GENDER_OPTIONS.find(
                                      (option) => option.value === gender
                                    )}
                                    onChange={(selectedOption) =>
                                      setGender(selectedOption.value)
                                    }
                                    placeholder={t("select")}
                                    isDisabled={disabled}
                                  />
                                </div>
                              </div>
                              <div className="col-md-3 col-sm-12 mr-4">
                                <div className="form-group">
                                  <label className="form-label">
                                    {t("personnelNumber")}
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    disabled
                                    value={employeeData.employeeNumber}
                                  />
                                </div>
                              </div>
                              <div className="col-md-3 col-sm-12 mr-4">
                                <div className="form-group">
                                  <label className="form-label">
                                    {t("System-ID")}
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    disabled
                                    value={employeeData.idNumber}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="row mt-2">
                              <div className="col-md-3 col-sm-12 mr-4">
                                <div className="form-group">
                                  <label className="form-label">Email</label>
                                  <input
                                    type="email"
                                    className="form-control"
                                    disabled={disabled}
                                    placeholder={t("notSet")}
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                  />
                                </div>
                              </div>
                              <div className="col-md-3 col-sm-12 mr-4">
                                <div className="form-group">
                                  <label className="form-label">
                                    {t("mobile")}
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    disabled={disabled}
                                    placeholder={t("notSet")}
                                    value={mobile}
                                    onChange={(e) => setMobile(e.target.value)}
                                  />
                                </div>
                              </div>
                              <div className="col-md-3 col-sm-12 mr-4">
                                <div className="form-group">
                                  <label className="form-label">
                                    {t("phone")}
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    disabled={disabled}
                                    placeholder={t("notSet")}
                                    value={phone}
                                    onChange={(e) => setPhone(e.target.value)}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="row mt-2">
                              <div className="col-md-3 col-sm-12 mr-4">
                                <div className="form-group">
                                  <label className="form-label">
                                    {t("Sozialversicherungsnummer")}
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    disabled={disabled}
                                    placeholder={t("notSet")}
                                    value={pensionInsuranceNumber}
                                    onChange={(e) =>
                                      setPensionInsuranceNumber(e.target.value)
                                    }
                                  />
                                </div>
                              </div>
                              <div className="col-md-3 col-sm-12 mr-4">
                                <div className="form-group">
                                  <label
                                    className="form-label"
                                    style={{
                                      color: !birthName ? "red" : "",
                                    }}
                                  >
                                    {t("birthName")}
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    disabled={disabled}
                                    placeholder={t("notSet")}
                                    value={birthName}
                                    onChange={(e) =>
                                      setBirthName(e.target.value)
                                    }
                                  />
                                </div>
                              </div>
                              <div className="col-md-3 col-sm-12 mr-4">
                                <div className="form-group">
                                  <label
                                    className="form-label"
                                    style={{
                                      color: !birthDate ? "red" : "",
                                    }}
                                  >
                                    {t("birthDate")}
                                  </label>
                                  <input
                                    type="date"
                                    className="form-control"
                                    disabled={disabled}
                                    placeholder={t("notSet")}
                                    value={birthDate}
                                    onChange={(e) =>
                                      setBirthDate(e.target.value)
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="row mt-2">
                              <div className="col-md-3 col-sm-12 mr-4">
                                <div className="form-group">
                                  <label
                                    className="form-label"
                                    style={{
                                      color: !placeOfBirth ? "red" : "",
                                    }}
                                  >
                                    {t("placeOfBirth")}
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    disabled={disabled}
                                    placeholder={t("notSet")}
                                    value={placeOfBirth}
                                    onChange={(e) =>
                                      setPlaceOfBirth(e.target.value)
                                    }
                                  />
                                </div>
                              </div>
                              <div className="col-md-3 col-sm-12 mr-4">
                                <div className="form-group">
                                  <label
                                    className="form-label"
                                    style={{
                                      color: !countryOfBirth ? "red" : "",
                                    }}
                                  >
                                    {t("countryOfBirth")}
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    disabled={disabled}
                                    placeholder={t("notSet")}
                                    value={countryOfBirth}
                                    onChange={(e) =>
                                      setCountryOfBirth(e.target.value)
                                    }
                                  />
                                </div>
                              </div>
                              {/* <div className="col-md-3 col-sm-12 mr-4">
                                <div className="form-group">
                                  <label className="form-label">{t('maritalStatus')}</label>
                                  <Select
                                    options={MARITAL_STATUS_OPTIONS}
                                    value={MARITAL_STATUS_OPTIONS.find(
                                      (option) => option.value === maritalStatus
                                    )}
                                    onChange={(selectedOption) =>
                                      setMaritalStatus(selectedOption.value)
                                    }
                                    placeholder={t("select")}
                                    isDisabled={disabled}
                                  />
                                </div>
                              </div> */}
                              <div className="col-md-3 col-sm-12 mr-4">
                                <div className="form-group">
                                  <label
                                    className="form-label"
                                    style={{
                                      color: !nationality ? "red" : "",
                                    }}
                                  >
                                    {t("nationality")}
                                  </label>
                                  <Select
                                    options={NATIONALITY_OPTIONS}
                                    value={NATIONALITY_OPTIONS.find(
                                      (option) => option.value === nationality
                                    )}
                                    onChange={(selectedOption) =>
                                      setNationality(selectedOption.value)
                                    }
                                    placeholder={t("select")}
                                    isDisabled={disabled}
                                  />
                                </div>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                      <hr />
                      <div className="row clearfix">
                        <div className="col-lg-10 col-md-10">
                          <form className="card">
                            <h3
                              className="card-title mt-2"
                              style={{
                                fontWeight: 800,
                                fontSize: "15px",
                                color: "#6F7687",
                              }}
                            >
                              Beschäftigungdaten
                            </h3>
                            <div className="row">
                              <div className="col-md-3 col-sm-12 mr-4">
                                <div className="form-group">
                                  <label className="form-label">
                                    {t("Position")}
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    disabled={disabled}
                                    placeholder={t("notSet")}
                                    value={position}
                                    onChange={(e) =>
                                      setPosition(e.target.value)
                                    }
                                  />
                                </div>
                              </div>
                              <div className="col-md-3 col-sm-12 mr-4">
                                <div className="form-group">
                                  <label className="form-label">
                                    {t("employmentType")}
                                  </label>
                                  <Select
                                    options={EMPLOYMENT_TYPE_OPTIONS}
                                    value={EMPLOYMENT_TYPE_OPTIONS.find(
                                      (option) =>
                                        option.value === employmentType
                                    )}
                                    onChange={(selectedOption) =>
                                      setEmploymentType(selectedOption.value)
                                    }
                                    placeholder={t("select")}
                                    isDisabled={disabled}
                                  />
                                </div>
                              </div>
                              <div className="col-md-3 col-sm-12">
                                <div className="form-group">
                                  <label className="form-label">
                                    {t("employmentDate")}
                                  </label>
                                  <input
                                    type="date"
                                    className="form-control"
                                    disabled={disabled}
                                    placeholder={t("notSet")}
                                    value={employmentDate}
                                    onChange={(e) =>
                                      setEmploymentDate(e.target.value)
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="row mt-2">
                              <div className="col-md-3 col-sm-12 mr-4">
                                <div className="form-group">
                                  <label className="form-label">
                                    {t("employmentEndDate")}
                                  </label>
                                  <input
                                    type="date"
                                    className="form-control"
                                    disabled={disabled}
                                    placeholder={t("notSet")}
                                    value={employmentEndDate}
                                    onChange={(e) =>
                                      setEmploymentEndDate(e.target.value)
                                    }
                                  />
                                </div>
                              </div>
                              <div className="col-md-3 col-sm-12 mr-4">
                                <div className="form-group">
                                  <label className="form-label">
                                    {t("Firma")}
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    disabled
                                    value={employeeData.companyName}
                                  />
                                </div>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                      <hr />
                      <div className="row clearfix">
                        <div className="col-lg-10 col-md-10">
                          <form className="card">
                            <h3
                              className="card-title"
                              style={{
                                fontWeight: 800,
                                fontSize: "15px",
                                color: "#6F7687",
                              }}
                            >
                              Anschrift
                            </h3>
                            <div className="row">
                              <div className="col-md-3 col-sm-12 mr-4">
                                <div className="form-group">
                                  <label className="form-label">
                                    {t("C/O")}
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    disabled={disabled}
                                    placeholder={t("notSet")}
                                    value={careOf}
                                    onChange={(e) => setCareOf(e.target.value)}
                                  />
                                </div>
                              </div>
                              <div className="col-md-3 col-sm-12 mr-4">
                                <div className="form-group">
                                  <label
                                    className="form-label"
                                    style={{
                                      color: !street ? "red" : "",
                                    }}
                                  >
                                    {t("street")}
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    disabled={disabled}
                                    placeholder={t("notSet")}
                                    value={street}
                                    onChange={(e) => setStreet(e.target.value)}
                                  />
                                </div>
                              </div>
                              <div className="col-md-3 col-sm-12 mr-4">
                                <div className="form-group">
                                  <label
                                    className="form-label"
                                    style={{
                                      color: !houseNumber ? "red" : "",
                                    }}
                                  >
                                    {t("houseNumber")}
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    disabled={disabled}
                                    placeholder={t("notSet")}
                                    value={houseNumber}
                                    onChange={(e) =>
                                      setHouseNumber(e.target.value)
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="row mt-2">
                              <div className="col-md-3 col-sm-12 mr-4">
                                <div className="form-group">
                                  <label
                                    className="form-label"
                                    style={{
                                      color: !postcode ? "red" : "",
                                    }}
                                  >
                                    {t("postcode")}
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    disabled={disabled}
                                    placeholder={t("notSet")}
                                    value={postcode}
                                    onChange={(e) =>
                                      setPostcode(e.target.value)
                                    }
                                  />
                                </div>
                              </div>
                              <div className="col-md-3 col-sm-12 mr-4">
                                <div className="form-group">
                                  <label
                                    className="form-label"
                                    style={{
                                      color: !location ? "red" : "",
                                    }}
                                  >
                                    {t("location")}
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    disabled={disabled}
                                    placeholder={t("notSet")}
                                    value={location}
                                    onChange={(e) =>
                                      setLocation(e.target.value)
                                    }
                                  />
                                </div>
                              </div>
                              {isEditMode && (
                                <div className="col-md-3 col-sm-12 mr-4">
                                  {options && options.length > 1 && (
                                    <>
                                      <label className="form-label">
                                        Mehrere Optionen
                                      </label>
                                      <select
                                        className="custom-select"
                                        value={postcode}
                                        onChange={(e) => {
                                          const selectedOption = options.find(
                                            (option) =>
                                              option.postcode === e.target.value
                                          );
                                          setPostcode(e.target.value);
                                          setLocation(selectedOption.location);
                                        }}
                                      >
                                        {options.map((option, index) => (
                                          <option
                                            key={index}
                                            value={option.postcode}
                                          >
                                            {option.location} ({option.postcode}
                                            )
                                          </option>
                                        ))}
                                      </select>
                                    </>
                                  )}
                                </div>
                              )}
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="tab-pane fade"
                  id="insurance-details"
                  role="tabpanel"
                >
                  <div className="card">
                    <div className="card-body">
                      {employeeData.providerName === "VIACTIVKRANKENKASSE" && (
                        <div className="card-body px-14">
                          <ApplicationForm
                            employeeId={employeeData.employeeId}
                            pensionNumber={employeeData.pensionInsuranceNumber}
                          />
                        </div>
                      )}
                      {employeeData.providerName === "MOBILKRANKENKASSE" &&
                        "here in mobil"}
                    </div>
                  </div>
                </div>
                <div
                  className="tab-pane fade"
                  id="previous-ins"
                  role="tabpanel"
                >
                  <div className="card">
                    <div className="card-body">
                      {employeeData.providerName === "VIACTIVKRANKENKASSE" && (
                        <div className="card-body px-14">
                          <PreviousInsurance
                            employeeId={employeeData.employeeId}
                          />
                        </div>
                      )}
                      {employeeData.providerName === "MOBILKRANKENKASSE" &&
                        "here in mobil"}
                    </div>
                  </div>
                </div>
                <div className="tab-pane fade" id="family-ins" role="tabpanel">
                  <div className="card">
                    <div className="card-body">
                      {employeeData.providerName === "VIACTIVKRANKENKASSE" && (
                        <div className="card-body px-14">
                          <FamilyInsuranceForm
                            employeeId={employeeData.employeeId}
                            employeeLastName={employeeData.lastName}
                            employeeFirstName={employeeData.firstName}
                            employeeBirthDate={employeeData.birthDate}
                          />
                        </div>
                      )}
                      {employeeData.providerName === "MOBILKRANKENKASSE" &&
                        "here in mobil"}
                    </div>
                  </div>
                </div>
                <div
                  className="tab-pane fade"
                  id="address-form"
                  role="tabpanel"
                >
                  <div className="card">
                    <div className="card-body">
                      {employeeData.providerName === "VIACTIVKRANKENKASSE" && (
                        <div className="card-body px-14">
                          <CardDeliveryForm
                            employeeId={employeeData.employeeId}
                          />
                        </div>
                      )}
                      {employeeData.providerName === "MOBILKRANKENKASSE" &&
                        "here in mobil"}
                    </div>
                  </div>
                </div>
                <div className="tab-pane fade" id="documents" role="tabpanel">
                  <div className="card">
                    <div className="card-body">
                      <DocumentUploader employeeId={employeeId} />
                    </div>
                  </div>
                </div>
                <div
                  className="tab-pane fade"
                  id="email-history"
                  role="tabpanel"
                >
                  <EmployeeEmailHistory employeeId={employeeId} />
                </div>
              </div>
            </div>
          </div>
          <ImageUploadDialog
            isOpen={isUploadDialogOpen}
            onClose={() => setIsUploadDialogOpen(false)}
            onUpload={handleImageUpload}
            id={id}
            displayMsg={"Change Profile Picture"}
            // isCompany={false}
            // isEmployee={true}
          />
        </div>
      </div>
    </>
  );
};

export default EmployeeDetails;

import { Dialog } from "@mui/material";
import { PlusIcon } from "lucide-react";
import React, { useEffect, useState } from "react";
import MultiSelect from "../../../Utility/MultiSelect";
import Select from "react-select";
import SignaturePad from "../../../Utility/SignaturePad";
import { toast } from "react-toastify";
import {
  generatePreviousInsuranceForm,
  getPreviousInsuranceForm,
  submitPrevInsuranceFormApi,
} from "../../../../services/insuranceFormService";
import Loading from "../../../Utility/Loading";
import { useTranslation } from "react-i18next";

const PreviousInsurance = ({ employeeId }) => {
  const initialRow = {
    fromDate: "",
    toDate: "",
    name: "",
    type: "",
    privateSelect: [],
  };
  const [rows, setRows] = useState([initialRow]);
  const { t, i18n } = useTranslation("employee");
  const [comment, setComment] = useState("");
  const [includePhoneNumber, setIncludePhoneNumber] = useState("");
  const [signUrl, setSignUrl] = useState("");
  const [signaturePadOpen, setSignaturePadOpen] = useState(false);
  const [applicationSubmitted, setApplicationSubmitted] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [submittedDateTime, setSubmittedDateTime] = useState("");
  const [noEntry, setNoEntry] = useState(false);
  const initialErrorState = {
    signUrl: "",
    fromDate: "",
    toDate: "",
    name: "",
    type: "",
    privateSelect: "",
  };
  const [errors, setErrors] = useState(initialErrorState);
  const invalidFeedbackStyle = {
    display: "block",
    width: "100%",
    marginTop: "0.25rem",
    fontSize: "90%",
    color: "#dc3545",
  };

  const invalidNoEntryCardStyle = {
    display: noEntry ? "block" : "none",
    width: "100%",
    marginTop: "0.25rem",
    fontSize: "100%",
    color: "#dc3545",
  };
  useEffect(() => {
    getViactivPreviousInsuranceForm();
  }, []);

  useEffect(() => {}, [applicationSubmitted]);

  const getViactivPreviousInsuranceForm = async () => {
    try {
      const response = await getPreviousInsuranceForm(
        localStorage.token,
        employeeId
      );
      if (response) {
        setPreviousInsuranceForm(response);
        setApplicationSubmitted(true);
        setDisabled(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const setPreviousInsuranceForm = (data) => {
    setSubmittedDateTime(data.submittedTime);
    setRows(data.previousInsurances);
    setComment(data.comment);
    setIncludePhoneNumber(data.includePhoneNumber);
    setSignUrl(data.signUrl);
  };

  const handleAddRow = () => {
    setRows([...rows, { ...initialRow }]);
  };

  const handleRemoveRow = (index) => {
    const updatedRows = [...rows];
    updatedRows.splice(index, 1);
    setRows(updatedRows);
  };

  const handleRowChange = (index, field, value) => {
    const updatedRows = [...rows];
    if (field === "type" && value) {
      updatedRows[index] = {
        ...updatedRows[index],
        [field]: value,
      };
    } else if (field === "privateSelect") {
      updatedRows[index] = {
        ...updatedRows[index],
        [field]: value,
      };
    } else {
      updatedRows[index] = { ...updatedRows[index], [field]: value };
    }
    setRows(updatedRows);
  };

  const validateForm = (formData) => {
    console.log("check errors");
    const newErrors = {};
    if (!formData.signUrl || formData.signUrl.trim == "") {
      newErrors.signUrl = t("required");
    }
    if (
      formData.previousInsurances &&
      formData.previousInsurances.length == 0
    ) {
      setNoEntry(true);
    } else {
      setNoEntry(false);
    }
    if (formData.previousInsurances.length > 0) {
      rows.forEach((row, rowIndex) => {
        if (!row.fromDate || row.fromDate.trim() === "") {
          newErrors.fromDate = t("required");
        }
        if (!row.toDate || row.toDate.trim() === "") {
          newErrors.toDate = t("required");
        }
        if (!row.privateSelect || row.privateSelect.length === 0) {
          newErrors.privateSelect = t("required");
        }
        if (!row.name || row.name.trim() === "") {
          newErrors.name = t("required");
        }
        if (!row.type || row.type.trim() === "") {
          newErrors.type = t("required");
        }
      });
    }
    setErrors(newErrors);
    console.log("Errors", newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async () => {
    const formData = {
      previousInsurances: rows,
      comment: comment,
      signUrl: signUrl,
      includePhoneNumber: includePhoneNumber,
      provider: "VIACTIVKRANKENKASSE",
      formType: "PREVIOUS_INSURANCE_FORM",
    };
    console.log(formData);
    if (validateForm(formData) && !noEntry) {
      setDisabled(true);
      setIsLoading(true);
      setEditMode(false);
      submitPrevInsuranceForm(formData);
    }
  };

  const submitPrevInsuranceForm = async (formData) => {
    try {
      const response = await submitPrevInsuranceFormApi(
        localStorage.token,
        employeeId,
        formData
      );
      if (response) {
        toast.success(t("formSubmittedSuccessfully"));
        window.location.reload();
      } else {
        toast.error(t("formSubmissionFailed"));
      }
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const INSURANCE_TYPE_OPTIONS = [
    { value: "PUBLIC", label: "Gesetzlich" },
    { value: "PRIVATE", label: "Privat" },
  ];

  const PRIVATE_TYPE_OPTIONS = [
    { value: "FREEINSURANCE", label: "Versicherungsfrei" },
    { value: "EXEMPTINSURANCE", label: "Von Der Versicherungspflicht Befreit" },
    { value: "SELFEMPLOYED", label: "Selbstständig Tätig" },
  ];

  const setPrivateInsuranceTypeValues = (values, index) => {
    handleRowChange(index, "privateSelect", values);
  };

  const closeSignaturePad = () => {
    setSignaturePadOpen(false);
  };

  const setSignUrlFromPad = (url) => {
    setSignUrl(url);
  };

  const handleEdit = () => {
    setEditMode(true);
    setDisabled(false);
  };

  const handleCancel = () => {
    setEditMode(false);
    setDisabled(true);
  };

  const generateForm = async () => {
    try {
      const response = await generatePreviousInsuranceForm(
        employeeId,
        localStorage.token
      );
      const blob = new Blob([response.data], {
        type: response.headers["content-type"],
      });
      const fileURL = URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.style.display = "none";
      a.href = fileURL;
      a.download = "Vorheriges_Versicherungsformular.pdf";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      URL.revokeObjectURL(fileURL);
    } catch (error) {
      console.log(error);
    }
  };

  function formatDateOnlyGerman(dateString) {
    const date = new Date(dateString);
    const germanDate = new Intl.DateTimeFormat("de-DE", {
      day: "numeric",
      month: "long",
      year: "numeric",
    }).format(date);

    return germanDate;
  }

  return (
    <div className="card">
      <div className="card-body p-2">
        {applicationSubmitted && (
          <div className="card-alert alert alert-success mb-0">
            {t("applicationSubmittedOn")} &nbsp;
            {formatDateOnlyGerman(submittedDateTime)}.&nbsp;&nbsp;
            <button className="btn btn-info" onClick={generateForm}>
              {t("viewFromPDF")}
            </button>
          </div>
        )}
        <div style={invalidNoEntryCardStyle}>
          <div className="card-alert alert alert-danger mb-0 mt-4">
            {t("prevInsuranceFormError")}
          </div>
        </div>
        {rows.map((row, index) => (
          <div style={{ backgroundColor: "whitesmoke" }} className="p-2">
            <div className="row ml-2 mt-10">
              <div className="col-md-3 col-sm-12">
                <div className="form-group" style={{ marginTop: "5px" }}>
                  <label className="form-label" style={{ color: "#282826" }}>
                    {t("nameOfInsurance")}
                    <span
                      style={{
                        color: "red",
                        fontSize: "12px",
                        marginLeft: "1px",
                        marginRight: "4px",
                      }}
                    >
                      *
                    </span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="name"
                    value={row.name}
                    onChange={(e) =>
                      handleRowChange(index, "name", e.target.value)
                    }
                    placeholder="Name of Insurance"
                    required
                    disabled={disabled}
                  />
                  {(!row.name || row.name.trim() == "") && (
                    <div style={invalidFeedbackStyle}>{errors.name}</div>
                  )}
                </div>
              </div>
              <div className="col-md-3 col-sm-12">
                <div className="form-group" style={{ marginTop: "5px" }}>
                  <label className="form-label" style={{ color: "#282826" }}>
                    Von
                    <span
                      style={{
                        color: "red",
                        fontSize: "12px",
                        marginLeft: "1px",
                        marginRight: "4px",
                      }}
                    >
                      *
                    </span>
                  </label>
                  <input
                    type="date"
                    name="fromDate"
                    value={row.fromDate}
                    onChange={(e) =>
                      handleRowChange(index, "fromDate", e.target.value)
                    }
                    className="form-control"
                    disabled={disabled}
                  />
                  {(!row.fromDate || row.fromDate.trim() == "") && (
                    <div style={invalidFeedbackStyle}>{errors.fromDate}</div>
                  )}
                </div>
              </div>
              <div className="col-md-3 col-sm-12">
                <div className="form-group" style={{ marginTop: "5px" }}>
                  <label className="form-label" style={{ color: "#282826" }}>
                    Bis
                    <span
                      style={{
                        color: "red",
                        fontSize: "12px",
                        marginLeft: "1px",
                        marginRight: "4px",
                      }}
                    >
                      *
                    </span>
                  </label>
                  <input
                    type="date"
                    name="toDate"
                    value={row.toDate}
                    onChange={(e) =>
                      handleRowChange(index, "toDate", e.target.value)
                    }
                    className="form-control"
                    disabled={disabled}
                  />
                  {(!row.toDate || row.toDate.trim() == "") && (
                    <div style={invalidFeedbackStyle}>{errors.toDate}</div>
                  )}
                </div>
              </div>
              <div className="col-md-3 col-sm-12">
                <div className="form-group" style={{ marginTop: "5px" }}>
                  <label className="form-label" style={{ color: "#282826" }}>
                    {t("insuranceType")}
                    <span
                      style={{
                        color: "red",
                        fontSize: "12px",
                        marginLeft: "1px",
                        marginRight: "4px",
                      }}
                    >
                      *
                    </span>
                  </label>
                  <Select
                    options={INSURANCE_TYPE_OPTIONS}
                    value={INSURANCE_TYPE_OPTIONS.find(
                      (option) => option.value === row.type
                    )}
                    onChange={(e) => handleRowChange(index, "type", e.value)}
                    placeholder="Select"
                    isDisabled={disabled}
                  />
                  {(!row.type || row.type.trim() == "") && (
                    <div style={invalidFeedbackStyle}>{errors.type}</div>
                  )}
                </div>
              </div>
            </div>
            <div className="row ml-2">
              <div className="col-md-6 col-sm-12">
                <div className="form-group" style={{ marginTop: "5px" }}>
                  <label className="form-label" style={{ color: "#282826" }}>
                    Falls Sie privat krankenversichert waren, bitte ankreuzen
                    <span
                      style={{
                        color: "red",
                        fontSize: "12px",
                        marginLeft: "1px",
                        marginRight: "4px",
                      }}
                    >
                      *
                    </span>
                  </label>
                  <MultiSelect
                    selectOptions={PRIVATE_TYPE_OPTIONS}
                    setValues={setPrivateInsuranceTypeValues}
                    index={index}
                    selectedValues={row.privateSelect}
                    disabled={disabled}
                  />
                  {(!row.privateSelect || row.privateSelect.length === 0) && (
                    <div style={invalidFeedbackStyle}>
                      {errors.privateSelect}
                    </div>
                  )}
                </div>
              </div>
              <div className="col-md-4 text-left mt-8">
                <button
                  className="btn btn-info"
                  onClick={() => handleRemoveRow(index)}
                >
                  {t("deleteRow")}
                </button>
              </div>
            </div>
          </div>
        ))}
        <div className="flex gap-5 ml-3 mt-2 mb-2 justify-start">
          <button className="btn btn-primary" onClick={handleAddRow}>
            <PlusIcon size={20} />
          </button>
        </div>
        <div className="row ml-2 mt-4">
          <div className="col-md-4 col-sm-12">
            <div className="form-group" style={{ marginTop: "5px" }}>
              <label className="form-label" style={{ color: "#282826" }}>
                {t("comment")}
              </label>
              <textarea
                placeholder="Add a comment..."
                value={comment}
                className="form-control"
                name="comment"
                onChange={(e) => setComment(e.target.value)}
                rows={4}
                style={{ marginTop: "10px", width: "100%", resize: "vertical" }}
                disabled={disabled}
              />
            </div>
          </div>
          <div className="col-md-2 col-sm-12"></div>
          <div className="col-md-4 col-sm-12">
            <div className="form-group">
              <label className="form-label" style={{ color: "#282826" }}>
                {t("Unterschrift")}
                <span
                  style={{
                    color: "red",
                    fontSize: "12px",
                    marginLeft: "1px",
                    marginRight: "4px",
                  }}
                >
                  *
                </span>
              </label>
              <div>
                <img src={signUrl}></img>
                <button
                  className="btn btn-info"
                  style={{
                    height: "35px",
                    width: "100px",
                    padding: "2px",
                    marginTop: "10px",
                    fontWeight: 600,
                  }}
                  onClick={() => setSignaturePadOpen(true)}
                  disabled={disabled}
                >
                  ZEICHEN
                </button>
                {errors.signUrl && (
                  <div style={invalidFeedbackStyle}>{errors.signUrl}</div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="row ml-2 mt-4">
          <div className="col-md-4 col-sm-12" style={{ marginTop: "5px" }}>
            <div className="form-group">
              <label className="custom-control custom-checkbox">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  checked={includePhoneNumber}
                  onChange={() => setIncludePhoneNumber(!includePhoneNumber)}
                  disabled={disabled}
                />
                <span
                  className="custom-control-label"
                  style={{
                    color: "#282826",
                    fontSize: "16px",
                    fontWeight: 500,
                  }}
                >
                  Geben Sie meine Telefonnummer an
                </span>
              </label>
            </div>
          </div>
        </div>
      </div>
      <div className="card-footer text-right">
        <button
          className="btn btn-primary"
          style={{ textTransform: "uppercase" }}
          disabled={disabled}
          onClick={handleSubmit}
        >
          {t("submit")}
        </button>
        {disabled && (
          <>
            <button
              className="btn btn-primary ml-10"
              style={{ textTransform: "uppercase" }}
              onClick={handleEdit}
            >
              {t("edit")}
            </button>
          </>
        )}
        {editMode && (
          <>
            <button
              className="btn btn-info ml-10"
              style={{ textTransform: "uppercase" }}
              onClick={handleCancel}
            >
              {t("cancel")}
            </button>
          </>
        )}
      </div>
      <Dialog
        open={signaturePadOpen}
        style={{ backdropFilter: "blur(2px)" }}
        fullWidth
      >
        <div className="mx-auto" style={{ backgroundColor: "transparent" }}>
          <SignaturePad
            closeSignaturePad={closeSignaturePad}
            setSignUrlFromPad={setSignUrlFromPad}
          />
        </div>
      </Dialog>
      <Dialog
        open={isLoading}
        style={{ backdropFilter: "blur(2px)" }}
        fullWidth
      >
        <div
          className="flex flex-col justify-center"
          style={{ backgroundColor: "transparent" }}
        >
          <div className="mx-auto" style={{ backgroundColor: "transparent" }}>
            <Loading />
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default PreviousInsurance;

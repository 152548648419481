import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { connect, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import Barchart from "../../common/barchart";
import { getCompanyDashboard } from "../../../services/companyService";
import Piechart from "../../common/piechart";
import { fetchCompanyEmailCenterOverview } from "../../../services/apiService";
import { NavLink } from "react-router-dom/cjs/react-router-dom.min";

const ManagerDashboard = () => {
  const fixNavbar = useSelector((state) => state.settings.isFixNavbar);
  const { t, i18n } = useTranslation("common");
  const history = useHistory();
  const [companyStatistics,setCompanyStatistics] = useState({});
  const [employeeDistribution,setEmployeeDistribution] = useState({});
  const [onboardingStatistics,setOnboardingStatistics] = useState([]);
  const [totalEmployees,setTotalEmployees] = useState();
  const [maleEmployees,setMaleEmployees] = useState();
  const [femaleEmployees,setFemaleEmployees] = useState();
  const [otherEmployees,setOtherEmployees] = useState();
  const [emailData,setEmailData] = useState([]);
  const currentDate = new Date();
  const currentDateString = currentDate.toISOString().split('T')[0]; // Get YYYY-MM-DD
  
  function calculateDate(date, days) {
    const resultDate = new Date(date);
    resultDate.setDate(resultDate.getDate() + days);
    return resultDate.toISOString().split('T')[0];
  }

  useEffect(() => {
    fetchCompanyDashboard();
    getEmailCenterOverview();
  }, [])
  

  const locale = {
    en: "en-US",
    de: "de-DE",
  };
  
  const getEmailCenterOverview = async () => {
    try {
      const search = {
        fromDate: calculateDate(currentDate, -7),
        toDate:  currentDateString
      }
      const response = await fetchCompanyEmailCenterOverview(
        localStorage.token,search
      );
      console.log("email overview response ", response);
      if (response.length > 0 && response.length <= 6) {
        setEmailData(response);
    } else if (response.length > 6) {
        setEmailData(response.slice(0, 6));
    }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchCompanyDashboard = async () => {
    try {
      const response = await getCompanyDashboard(localStorage.token);
      if(response){
        setCompanyStatistics(response.companyStatistics);
        setEmployeeDistribution(response.employeeDistribution);
        setOnboardingStatistics(response.onboardingStatisticsList);
        setTotalEmployees(response.totalEmployees);
        setMaleEmployees(response.maleEmployees);
        setFemaleEmployees(response.femaleEmployees);
        setOtherEmployees(response.otherEmployees);
      }
      
    } catch (error) {
      console.log(error);
    }
  }
  const getEmailTypeText = (emailType) => {
    if (emailType === "FORM_EMAIL") {
      return t('applicationFormEmail');
    } else if (emailType === "PENSION_INSURANCE_EMAIL") {
      return t('pensionInsuranceEmail');
    }
  };

  const getEmailStatusText = (status) => {
    if (status === "EMAIL_SENT") {
      return (
        <span style={{ fontWeight: 500, color: "green" }}> {t('emailSent')}</span>
      );
    } else if (status === "EMAIL_PENDING") {
      return (
        <span style={{ fontWeight: 500, color: "blue" }}> {t('emailPending')}</span>
      );
    } else if (status === "EMAIL_ERROR") {
      return (
        <span style={{ fontWeight: 500, color: "red" }}> Email {" "}{t('error')}</span>
      );
    } else if (status === "EMAIL_REPLY") {
      return (
        <span style={{ fontWeight: 500, color: "orange" }}>
          {" "}
         {t('replyReceived')}
        </span>
      );
    }
  };

  const getDocStatusText = (status) => {
    if (status === "PENDING") {
      return t('documentsPending');
    } else if (status === "UPLOADED") {
      return t('documentsUploaded');
    } else if (status === "VERIFICATION_PENDING") {
      return t('docVerificationPending');
    } else if (status === "VERIFIED") {
      return t('documentsVerified');
    }
    return t('documentsNotRequired');
  };

  const formatDateGerman = (date) => {
    if (date === undefined) return "";
    const dateObject = new Date(date);
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
    };
    return new Intl.DateTimeFormat(locale[i18n.language], options).format(
      dateObject
    );
  };

  return (
    <>
      <div>
        <div className={`section-body ${fixNavbar ? "marginTop" : ""} mt-3`}>
          <div className="container-fluid">
            <div className="row clearfix">
              <div className="col-lg-8">
                <div
                  className={`section-body ${fixNavbar ? "mb-4 mt-3" : "mb-4"}`}
                >
                  <h4>
                    {t("welcome")} {localStorage.userName}!
                  </h4>
                  <small>
                    {t("dashboardMsg")}. <a href="">{t("learnMore")}</a>
                  </small>
                </div>
              </div>
            </div>
            <div className="row clearfix">
            <div className="col-md-3 col-sm-6">
                <div className="card">
                  <div className="card-body ribbon">
                    <Link
                      to="/company/details"
                      className="my_sort_cut text-muted"
                    >
                      <i className="icon-list" />
                      <span>{t('companyProfile')}</span>
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-md-3 col-sm-6">
                <div className="card">
                  <div className="card-body">
                    {localStorage.role === "ROLE_SUPER_ADMIN" && (
                      <Link
                        to="/email/center"
                        className="my_sort_cut text-muted"
                      >
                        <i className="icon-envelope" />
                        <span>Emails</span>
                      </Link>
                    )}
                    {localStorage.role === "ROLE_ADMIN" && (
                      <Link
                        to="/company/email/center"
                        className="my_sort_cut text-muted"
                      >
                        <i className="icon-envelope" />
                        <span>Emails</span>
                      </Link>
                    )}
                  </div>
                </div>
              </div>
              <div className="col-md-3 col-sm-6">
                <div className="card">
                  <div className="card-body ribbon">
                    <Link to="/hr-employee" className="my_sort_cut text-muted">
                      <i className="icon-doc" />
                      <span>{t("employees")}</span>
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-md-3 col-sm-6">
                <div className="card">
                  <div className="card-body ribbon">
                    <Link to="/company-list" className="my_sort_cut text-muted">
                      <i className="icon-like" />
                      <span>{t("support")}</span>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="row clearfix row-deck">
              <div className="col-xl-4 col-lg-12">
                <div className="card">
                  <div className="card-header" style={{ marginBottom: "40px" }}>
                    <h3 className="card-title" style={{ fontWeight: 520 }}>
                      {t("employeeDistribution")}
                    </h3>
                  </div>
                  <div
                    className="text-center"
                    style={{ padding: "5px", marginBottom: "20px" }}
                  >
                    <div className="row clearfix">
                    <div className="col-3">
                        <h6 className="mb-0">
                          {" "}
                          {totalEmployees || (
                            <i
                              className="text-muted"
                              style={{ fontSize: "12px" }}
                            >
                              {t('notSet')}
                            </i>
                          )}
                        </h6>
                        <small className="text-muted">{t("total")}</small>
                      </div>
                      <div className="col-3">
                        <h6 className="mb-0">
                          {" "}
                          {maleEmployees || (
                            <i
                              className="text-muted"
                              style={{ fontSize: "12px" }}
                            >
                              {t('notSet')}
                            </i>
                          )}
                        </h6>
                        <small className="text-muted">{t("male")}</small>
                      </div>
                      <div className="col-3">
                        <h6 className="mb-0">
                          {femaleEmployees || (
                            <i
                              className="text-muted"
                              style={{ fontSize: "12px" }}
                            >
                               {t('notSet')}
                            </i>
                          )}
                        </h6>
                        <small className="text-muted">{t("female")}</small>
                      </div>
                      <div className="col-3">
                        <h6 className="mb-0">
                          {otherEmployees || (
                            <i
                              className="text-muted"
                              style={{ fontSize: "12px" }}
                            >
                              {t('notSet')}
                            </i>
                          )}
                        </h6>
                        <small className="text-muted">{t("other")}</small>
                      </div>
                    </div>
                  </div>
                  {employeeDistribution && (
                    <Piechart
                      categories={Object.keys(employeeDistribution)}
                      series={Object.values(employeeDistribution)}
                    ></Piechart>
                  )}
                </div>
              </div>
              <div className="col-xl-8 col-lg-12">
                <div className="card">
                  <div className="card-header">
                    <h3 className="card-title">
                      {t("currentOnboardingStatus")}
                    </h3>
                  </div>
                  <div className="card-body">
                   {onboardingStatistics.length >0 &&  <Barchart data={onboardingStatistics}></Barchart> }
                  </div>
                  <div className="card-footer">
                    <div className="row">
                      <div className="col-6 col-xl-3 col-md-6">
                        <div className="clearfix">
                          <div className="float-left">
                            <strong>{companyStatistics?.emailSentPercentage}%</strong>
                          </div>
                          <div className="float-right">
                            <small className="text-muted">
                              {t("yesterday")}
                            </small>
                          </div>
                        </div>
                        <div className="progress progress-xs">
                          <div
                            className="progress-bar bg-gray"
                            role="progressbar"
                            style={{ width: `${companyStatistics?.emailSentPercentage}%` }}
                            aria-valuenow={42}
                            aria-valuemin={0}
                            aria-valuemax={100}
                          />
                        </div>
                        <span className="text-uppercase font-10">
                          Email Sent
                        </span>
                      </div>
                      <div className="col-6 col-xl-3 col-md-6">
                        <div className="clearfix">
                          <div className="float-left">
                            <strong>{companyStatistics?.emailPendingPercentage}%</strong>
                          </div>
                          <div className="float-right">
                            <small className="text-muted">
                              {t("yesterday")}
                            </small>
                          </div>
                        </div>
                        <div className="progress progress-xs">
                          <div
                            className="progress-bar bg-gray"
                            role="progressbar"
                            style={{ width: `${companyStatistics?.emailPendingPercentage}%` }}
                            aria-valuenow={42}
                            aria-valuemin={0}
                            aria-valuemax={100}
                          />
                        </div>
                        <span className="text-uppercase font-10">
                          {t("emailPending")}
                        </span>
                      </div>
                      <div className="col-6 col-xl-3 col-md-6">
                        <div className="clearfix">
                          <div className="float-left">
                            <strong>{companyStatistics?.documentsUploadedPercentage}%</strong>
                          </div>
                          <div className="float-right">
                            <small className="text-muted">
                              {t("yesterday")}
                            </small>
                          </div>
                        </div>
                        <div className="progress progress-xs">
                          <div
                            className="progress-bar bg-gray"
                            role="progressbar"
                            style={{ width: `${companyStatistics?.documentsUploadedPercentage}%` }}
                            aria-valuenow={42}
                            aria-valuemin={0}
                            aria-valuemax={100}
                          />
                        </div>
                        <span className="text-uppercase font-10">
                          {t("documentsUploaded")}
                        </span>
                      </div>
                      <div className="col-6 col-xl-3 col-md-6">
                        <div className="clearfix">
                          <div className="float-left">
                            <strong>{companyStatistics?.formSubmittedPercentage}%</strong>
                          </div>
                          <div className="float-right">
                            <small className="text-muted">
                              {t("yesterday")}
                            </small>
                          </div>
                        </div>
                        <div className="progress progress-xs">
                          <div
                            className="progress-bar bg-gray"
                            role="progressbar"
                            style={{ width: `${companyStatistics?.formSubmittedPercentage}%` }}
                            aria-valuenow={42}
                            aria-valuemin={0}
                            aria-valuemax={100}
                          />
                        </div>
                        <span className="text-uppercase font-10">
                          {t("formEinreichented")}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="section-body">
          <div className="container-fluid">
            <div className="row clearfix">
              <div className="col-md-12">
                <div className="card">
                  <div className="card-header">
                    <h3 className="card-title">{t("emailOverview")}</h3>
                  </div>
                  <div className="card-body">
                    <div className="table-responsive">
                      <table className="table table-hover table-striped text-nowrap table-vcenter mb-0">
                      <thead className="thead-light">
                          <tr>
                              <th style={{ fontWeight: 600 }}>Name</th>
                              <th style={{ fontWeight: 600 }}>{t("insuranceProvider")}</th>
                              <th style={{ fontWeight: 600 }}>{t('formStatus')}</th>
                              <th style={{ fontWeight: 600 }}>{t('emailStatus')}</th>
                              <th style={{ fontWeight: 600 }}>{t('documentStatus')}</th>
                          </tr>
                        </thead>
                        <tbody className="table-hover">
                          {emailData?.map((emailRow, index) => (
                            <tr key={index}>
                              <td className="d-flex">
                                <span
                                  className="avatar avatar-blue"
                                  data-toggle="tooltip"
                                  data-original-title={emailRow.employeeName}
                                >
                                  {emailRow.employeeName
                                    .slice(0, 2)
                                    .toUpperCase()}
                                </span>
                                <div className="ml-3 mb-1">
                                  <h6
                                    className="mb-0"
                                    style={{ fontWeight: 600 }}
                                  >
                                    <NavLink
                                      to={
                                        "/employee/details?id=" +
                                        emailRow.employeeId
                                      }
                                      target="_blank"
                                    >
                                      {emailRow.employeeName}
                                    </NavLink>
                                  </h6>
                                  <span className="text-muted">
                                    {emailRow.email}
                                  </span>
                                </div>
                              </td>
                              <td>
                                <div
                                  className="font-15"
                                  style={{ fontWeight: 600 }}
                                >
                                  <NavLink to="/" target="_blank">
                                    {emailRow.providerName}
                                  </NavLink>
                                </div>
                              </td>
                              <td>
                                {/* <span className="text-muted">Form Status :</span> */}
                                <span
                                  className={`tag ${
                                    emailRow.formStatus === "SUBMITTED"
                                      ? "tag-success"
                                      : "tag-warning"
                                  } ml-0 mr-0`}
                                  style={{ fontWeight: 700 }}
                                >
                                  {t('form')}{" "}
                                  {emailRow.formStatus
                                    ? emailRow.formStatus
                                    : t('notRequired')}
                                </span>
                              </td>
                              <td>
                                <div
                                  className="font-15"
                                  style={{ fontWeight: 600 }}
                                >
                                  {getEmailTypeText(emailRow.emailType)}
                                </div>
                                {getEmailStatusText(emailRow.emailStatus)}
                                {emailRow.mailSentTime &&
                                  emailRow.emailStatus === "EMAIL_SENT" && (
                                    <>
                                      &nbsp;on&nbsp;{" "}
                                      {formatDateGerman(emailRow.mailSentTime)}
                                    </>
                                  )}
                              </td>
                              <td>
                                <span
                                  className={`tag ${
                                    emailRow.docStatus === "VERIFIED"
                                      ? "tag-success"
                                      : "tag-warning"
                                  } ml-0 mr-0`}
                                  style={{ fontWeight: 700 }}
                                >
                                  {getDocStatusText(emailRow.docStatus)}
                                </span>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  fixNavbar: state.settings.isFixNavbar,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(ManagerDashboard);

import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import DataImport from "./DataImport";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

function DataImportPage() {
  const fixNavbar = useSelector((state) => state.settings.isFixNavbar);
  const { t } = useTranslation("common");
  const history = useHistory();
  const [uploadResponse, setUploadResponse] = useState();

  const handleUploadResponse = (response) => {
    console.log("Upload response from file ", response);
    setUploadResponse(response);
  };

  const errorMap = {
    EMPLOYEE_EXISTS_WITH_SAME_EMPLOYEE_ID:
      "Ein Mitarbeiter mit derselben Personal-Nr. existiert bereits.",
    FIRST_NAME_MISSING: "Vorname fehlt",
    LAST_NAME_MISSING: "Nachname fehlt",
    GENDER_MISSING: "Geschlecht fehlt",
    EMPLOYMENT_DATE_MISSING: "Eintrittsdatum fehlt",
    EMPLOYEE_NUMBER_MISSING: "Personal-Nr. fehlt",
    BIRTHDATE_MISSING: "Geburtsdatum fehlt",
    EXCEPTION_OCCURED:
      "Etwas ist schief gelaufen, kontaktieren Sie den Support",
  };

  // const getErrorMessage = (messages) => {
  //   let error = "<span>";
  //   messages.forEach((message, index) => {
  //     error += message;
  //     if (index !== messages.length - 1) {
  //       error += "<br/>";
  //     }
  //   });
  //   error += "</span>";
  //   return error;
  // };

  const getErrorMessage = (messages) => {
    return (
      <span>
        {messages.map((message, index) => (
          <React.Fragment key={index}>
            {errorMap[message]}
            {index !== messages.length - 1 && <br />}
          </React.Fragment>
        ))}
      </span>
    );
  };

  return (
    <>
      <div>
        <div>
          <div className={`section-body ${fixNavbar ? "marginTop" : ""} `}>
            <div className="container-fluid">
              <div className="d-flex justify-content-between align-items-center mb-3">
                <ul className="nav nav-tabs page-header-tab">
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      id="import-employees-tab"
                      data-toggle="tab"
                      href="#import-employees"
                    >
                      {t("importEmployees")}
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="section-body">
            <div className="container-fluid">
              <div className="tab-content">
                <div
                  className="tab-pane fade show active"
                  id="import-employees"
                  role="tabpanel"
                >
                  <div className="card">
                    <div className="card-body">
                      {uploadResponse && !uploadResponse?.error && (
                        <div className="card-alert alert alert-success mb-0 mt-4">
                          Alle Mitarbeiter wurden erfolgreich hochgeladen.
                        </div>
                      )}
                      <DataImport
                        task={"Employees"}
                        title={t("importEmployeeDetails")}
                        handleUploadResponse={handleUploadResponse}
                      />
                      <div className="card-alert alert alert-info mb-0 mt-4">
                        <p style={{ fontSize: "14px" }}>
                          Falls beim Hochladen der CSV-Datei Probleme auftreten,
                          befolgen Sie diese Schritte:
                        </p>
                        <ul className="mt-1">
                          <li className="mt-1" style={{ fontSize: "13px" }}>
                            {" "}
                            - Überprüfen Sie, ob alle Header in der CSV-Datei
                            mit der Beispiel-CSV übereinstimmen
                          </li>
                          <li className="mt-1" style={{ fontSize: "13px" }}>
                            {" "}
                            - Beheben Sie alle während des Uploads angezeigten
                            Fehler.{" "}
                          </li>
                          <li className="mt-1" style={{ fontSize: "13px" }}>
                            {" "}
                            - Erstellen Sie ein Support-Ticket und geben Sie
                            Ihre Kontaktdaten an. Wir werden Ihnen helfen, das
                            Problem zu lösen oder es für Sie erledigen.{" "}
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  {uploadResponse && uploadResponse?.error && (
                    <div className="card mt-2 px-4">
                      <div className="card-body">
                        <div className="card-alert alert alert-danger mb-0 mt-4">
                          {uploadResponse?.message}
                        </div>
                        <div className="table-responsive mt-4">
                          <table className="table table-vcenter table-hover text-nowrap mb-0">
                            <thead className="thead-light">
                              <tr>
                                <th style={{ fontWeight: 600 }}>Error</th>
                                <th style={{ fontWeight: 600 }}>
                                  {" "}
                                  Personal-Nr.
                                </th>
                                <th style={{ fontWeight: 600 }}>Vorname</th>
                                <th style={{ fontWeight: 600 }}>Nachname</th>
                                <th style={{ fontWeight: 600 }}>Titel</th>
                                <th style={{ fontWeight: 600 }}>Geburtsname</th>
                                <th style={{ fontWeight: 600 }}>
                                  Geburtsdatum
                                </th>
                                <th style={{ fontWeight: 600 }}>Geburtsort</th>
                                <th style={{ fontWeight: 600 }}>Geburtsland</th>
                                <th style={{ fontWeight: 600 }}>Geschlecht</th>
                                <th style={{ fontWeight: 600 }}>
                                  Straße bzw. Postfach
                                </th>
                                <th style={{ fontWeight: 600 }}>Hausnummer</th>
                                <th style={{ fontWeight: 600 }}>
                                  Postleitzahl
                                </th>
                                <th style={{ fontWeight: 600 }}>Ort</th>
                                <th style={{ fontWeight: 600 }}>TelNr</th>
                                <th style={{ fontWeight: 600 }}>
                                  Mobiltelefon
                                </th>
                                <th style={{ fontWeight: 600 }}>
                                  E-Mail des Mitarbeiter
                                </th>
                              </tr>
                            </thead>
                            <tbody className="table-hover">
                              {uploadResponse &&
                                uploadResponse?.failed &&
                                uploadResponse?.failed.length > 0 &&
                                uploadResponse?.failed.map(
                                  (employee, index) => (
                                    <tr key={index}>
                                      <td style={{ color: "red" }}>
                                        {getErrorMessage(
                                          employee.errorMessages
                                        )}
                                      </td>
                                      <td>{employee.employeeNumber}</td>
                                      <td>{employee.firstName}</td>
                                      <td>{employee.lastName}</td>
                                      <td>{employee.title}</td>
                                      <td>{employee.birthName}</td>
                                      <td>{employee.birthDate}</td>
                                      <td>{employee.placeOfBirth}</td>
                                      <td>{employee.countryOfBirth}</td>
                                      <td>{employee.gender}</td>
                                      <td>{employee.street}</td>
                                      <td>{employee.houseNumber}</td>
                                      <td>{employee.postcode}</td>
                                      <td>{employee.location}</td>
                                      <td>{employee.phone}</td>
                                      <td>{employee.mobile}</td>
                                      <td>{employee.email}</td>
                                    </tr>
                                  )
                                )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default DataImportPage;

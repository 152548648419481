import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import { ArchiveXIcon } from "lucide-react";
import { toast } from "react-toastify";
import Loading from "../../Utility/Loading";
import { Button, Dialog } from "@mui/material";
import { useTranslation } from "react-i18next";
import { downloadEmployeesSampleCsv } from "../../../services/apiService";
import {
  getCompanyDropDownList,
  getManagerDropDown,
} from "../../../services/companyService";
import Select from "react-select";

const DataImport = ({ task, title, handleUploadResponse }) => {
  const [file, setFile] = useState(null);
  const [message, setMessage] = useState("");
  const [companyId, setCompanyId] = useState("");
  const [managerId, setManagerId] = useState("");
  const [companyOptions, setCompanyOptions] = useState([]);
  const [managerDropDown, setManagerDropDown] = useState([]);
  const [managerOptions, setManagerOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const inputRef = useRef();
  const { t } = useTranslation("common");

  useEffect(() => {
    if (localStorage.role === "ROLE_SUPER_ADMIN") {
      getCompanyDropDown();
      fetchManagerDropDown();
    }
  }, []);

  const tokenApiInstance = (token) => {
    return axios.create({
      baseURL: "https://api.boardi.co",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
    });
  };

  const getCompanyDropDown = async () => {
    try {
      const response = await getCompanyDropDownList(localStorage.token);
      setCompanyOptions(response);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchManagerDropDown = async () => {
    try {
      const response = await getManagerDropDown(localStorage.token);
      setManagerDropDown(response);
    } catch (error) {
      console.log(error);
    }
  };

  const handleCompanySelect = (id) => {
    setCompanyId(id);
    if (managerDropDown[id]) {
      setManagerOptions(managerDropDown[id]);
    } else {
      setManagerOptions([]);
    }
  };

  const handleFileUpload = () => {
    if (localStorage.role === "ROLE_SUPER_ADMIN" && !companyId && !managerId) {
      toast.warn(
        "Wählen Sie das Unternehmen und den Manager aus, für den Sie Mitarbeiter hinzufügen möchten."
      );
      return;
    }

    if (file) {
      setIsLoading(true);
      const formData = new FormData();
      formData.append("file", file);
      let url = "/data/import/employee";
      if (localStorage.role === "ROLE_SUPER_ADMIN") {
        formData.append("companyId", companyId);
        formData.append("managerId", managerId);
        url = "/data/import/super/admin/employee";
      }
      tokenApiInstance(localStorage.token)
        .post(url, formData)
        .then((response) => {
          if (response.data.error) {
            toast.warn(response.data.message + " Try again!", {
              autoClose: 1500,
            });
            handleUploadResponse(response.data);
          } else {
            toast.success(t("dataImportedSuccessfully"), {
              autoClose: 1000,
            });
            handleUploadResponse(response.data);
          }
          setIsLoading(false);
          setFile(null);
        })
        .catch((error) => {
          setIsLoading(false);
          toast.error(t("somethingWentWrong"), {
            autoClose: 1000,
          });
        });
    } else {
      setMessage(t("pleaseUploadValidCsv"));
    }
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleDrop = (event) => {
    event.preventDefault();
    setFile(event.dataTransfer.files);
  };

  const handleSelectFiles = (event) => {
    event.preventDefault();
    console.log("here ");
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      console.log("selectedFile", selectedFile);
      setFile(selectedFile);
    } else {
      setFile(null);
      setMessage(t("pleaseUploadValidCsv"));
    }
  };

  const handleDeleteFile = () => {
    setFile(null);
  };

  const invalidFeedbackStyle = {
    display: "block",
    width: "100%",
    marginTop: "0.25rem",
    fontSize: "90%",
    color: "#dc3545",
  };

  if (file)
    return (
      <div className="uploads flex flex-col gap-4 items-center w-fit px-20 py-10 rounded-lg border-2 border-dashed border-blue-400">
        <ul>
          <li
            key="file"
            className="flex gap-4  rounded-md p-2 mb-3 justify-between bg-green-200"
          >
            <span className="text-base text-gray-600"> {file.name}</span>
            <span
              onClick={() => handleDeleteFile()}
              className="bg-red-400 p-1 rounded-lg"
            >
              <ArchiveXIcon className="text-white" size={20} />
            </span>
          </li>
        </ul>
        <div className="actions flex justify-between space-x-8 ">
          <button
            className="bg-red-400 text-white px-4 py-2 rounded-lg text-sm font-semibold"
            onClick={() => setFile(null)}
          >
            {t("cancel")}{" "}
          </button>
          <button
            className="bg-green-400 text-white px-4 py-2 rounded-lg text-sm font-semibold"
            onClick={handleFileUpload}
          >
            {t("upload")}{" "}
          </button>
        </div>
        <p>
          <b>{t("pleaseNote")} </b>
          {t("onlyValidFiles")}
        </p>
        {isLoading && <Loading />}
      </div>
    );

  const downloadDocument = async () => {
    try {
      setIsLoading(true);
      const response = await downloadEmployeesSampleCsv(localStorage.token);
      const blob = new Blob([response.data], {
        type: response.headers["content-type"],
      });
      const fileURL = URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.style.display = "none";
      a.href = fileURL;
      a.download = "Mitarbeiter.csv";
      document.body.appendChild(a);
      a.click();
      setIsLoading(false);
      document.body.removeChild(a);
      URL.revokeObjectURL(fileURL);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  return (
    <div className="flex flex-col justify-center">
      <div className="row">
        <div className="col-lg-8 col-md-8 col-sm-12">
          <div className="card">
            <div className="card-body text-left">
              <div className="details">
                <h3
                  className="mb-0"
                  style={{ margin: "0px", fontSize: "18px" }}
                >
                  {title}
                </h3>
                <p className="text-muted mt-2 mb-0">
                  {" "}
                  {t("pleaseNote")} : {t("downloadSampleCsv")}
                </p>
                {/* <p className='text-muted mt-1 mb-2'> {t('uploadInstruction')}</p>
                          <ul style={{fontSize:"14px"}}>
                          <li>{'-'}&nbsp;&nbsp;{t('uploadInstruction5')}</li>
                          <li>{'-'}&nbsp;&nbsp;{t('uploadInstruction1')}</li>
                          <li>{'-'}&nbsp;&nbsp;{t('uploadInstruction2')}</li>
                          <li>{'-'}&nbsp;&nbsp;{t('uploadInstruction3')}</li>
                          <li>{'-'}&nbsp;&nbsp;{t('uploadInstruction4')}</li>
                        </ul> */}
              </div>
              <div className="row clearfix" style={{ marginTop: "10px" }}>
                {localStorage.role === "ROLE_SUPER_ADMIN" && (
                  <>
                    <div className="col-md-4 col-sm-6">
                      <div className="form-group" style={{ marginTop: "5px" }}>
                        <label
                          className="form-label"
                          style={{ color: "#58585B" }}
                        >
                          {t("company")}
                          <span
                            style={{
                              color: "red",
                              fontSize: "12px",
                              marginLeft: "1px",
                              marginRight: "4px",
                            }}
                          >
                            *
                          </span>
                        </label>
                        <Select
                          options={companyOptions}
                          value={companyOptions.find(
                            (option) => option.value === companyId
                          )}
                          onChange={(selectedOption) =>
                            handleCompanySelect(selectedOption.value)
                          }
                          placeholder={t("select")}
                        />
                        {!companyId && (
                          <div style={invalidFeedbackStyle}>
                            {t("required")}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="col-md-4 col-sm-6">
                      <div className="form-group" style={{ marginTop: "5px" }}>
                        <label
                          className="form-label"
                          style={{ color: "#58585B" }}
                        >
                          Manager
                          <span
                            style={{
                              color: "red",
                              fontSize: "12px",
                              marginLeft: "1px",
                              marginRight: "4px",
                            }}
                          >
                            *
                          </span>
                        </label>
                        <Select
                          options={managerOptions}
                          value={managerOptions.find(
                            (option) => option.value === managerId
                          )}
                          onChange={(selectedOption) =>
                            setManagerId(selectedOption.value)
                          }
                          placeholder={t("select")}
                        />
                        {!managerId && (
                          <div style={invalidFeedbackStyle}>
                            {t("required")}
                          </div>
                        )}
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-md-4 col-sm-12">
          <div className="card">
            <div className="card-body text-right">
              <div className="details">
                <button
                  className="btn btn-primary uppercase"
                  onClick={downloadDocument}
                  style={{ fontSize: "12px", fontWeight: 600 }}
                >
                  {t("download")}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <h2 className='text-base text-blue-600 mb-4 mt-2 '> {title}</h2> */}
      <div
        className="dropzone flex flex-col gap-1 text-xl items-center p-20 rounded-lg border-2 border-dashed border-blue-400"
        onDragOver={handleDragOver}
        onDrop={handleDrop}
        style={{
          backgroundColor: "white",
        }}
      >
        <h4
          className="form-label font-bold"
          style={{ fontSize: "18px", color: "gray" }}
        >
          {t("dragAndDrop")}
        </h4>
        <h4
          className="form-label font-bold"
          style={{ fontSize: "18px", color: "gray" }}
        >
          {t("or")}
        </h4>
        <input
          type="file"
          onChange={handleSelectFiles}
          hidden
          accept=".csv, .xlsx"
          ref={inputRef}
        />
        <button
          className="btn btn-primary uppercase"
          disabled={
            localStorage.role === "ROLE_SUPER_ADMIN"
              ? !companyId || !managerId
                ? true
                : false
              : false
          }
          onClick={() => inputRef.current.click()}
          style={{ fontSize: "12px", fontWeight: 600 }}
        >
          {t("selectFiles")}
        </button>
      </div>

      <Dialog
        open={isLoading}
        style={{ backdropFilter: "blur(2px)" }}
        fullWidth
      >
        <div
          className="flex flex-col justify-center"
          style={{ backgroundColor: "transparent" }}
        >
          <div className="mx-auto" style={{ backgroundColor: "transparent" }}>
            <Loading />
          </div>
        </div>
      </Dialog>
    </div>
  );
};
export default DataImport;

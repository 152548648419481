import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import CountUp from "react-countup";
import { toast } from "react-toastify";
import { addManager, getCompanyDetailsById } from "../../../services/companyService";
import countryData from "../../../constants/countryData";
import Select from "react-select";

const CompanyDetails = () => {
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const id = queryParams.get("id");
  const [companyId, setCompanyId] = useState(id);
  const fixNavbar = useSelector((state) => state.settings.isFixNavbar);
  const [companyData, setCompanyData] = useState({});
  const [totalEmployees, setTotalEmployees] = useState(null);
  const [inactiveEmployees, setInactiveEmployees] = useState(null);
  const [activeEmployees, setActiveEmployees] = useState(null);
  const [maleEmployees, setMaleEmployees] = useState(null);
  const [feMaleEmployees, setFeMaleEmployees] = useState(null);
  const [otherEmployees, setOtherEmployees] = useState(null);
  const { t } = useTranslation("common");
  const [formData, setFormData] = useState({
    managerEmail: "",
    managerFirstName: "",
    managerLastName: "",
    phone: "",
    countryCode: "",
  });
  const [errors, setErrors] = useState({
    managerEmail: false,
    managerFirstName: false,
    managerLastName: false,
  });

  useEffect(() => {
    setCompanyId(id);
    getCompanyDetails();
  }, []);

  useEffect(() => {}, [companyData]);

  const countryCodeOptions = countryData.map((country) => ({
    value: country.dial_code,
    label: `${country.dial_code} - ${country.name}`,
  }));

  const getCompanyDetails = async () => {
    try {
      let response;
      if (id) {
        response = await getCompanyDetailsById(localStorage.token, id);
      }
      console.log("company details ", response);
      setCompanyData(response);
      setTotalEmployees(response.totalEmployees);
      setInactiveEmployees(response.inactiveEmployees);
      setActiveEmployees(response.activeEmployees);
      setMaleEmployees(response.maleEmployees);
      setFeMaleEmployees(response.feMaleEmployees);
      setOtherEmployees(response.otherEmployees);
    } catch (error) {
      console.log(error);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setErrors({ ...errors, [name]: false });
  };

  const handleDropDownInputChange = (e) => {
    setFormData({ ...formData, ["countryCode"]: e.value });
  };

  const validateForm = (data) => {
    const newErrors = {};
    if (!data.managerEmail || data.managerEmail.trim() === "") {
      newErrors.managerEmail = true;
    }
    if (!data.managerFirstName || data.managerFirstName.trim() === "") {
      newErrors.managerFirstName = true;
    }
    if (!data.managerLastName || data.managerLastName.trim() === "") {
      newErrors.managerLastName = true;
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = () => {
    const isFormValid = validateForm(formData);
    if (isFormValid) {
      saveManager();
    }
  };

  const saveManager = async () => {
    try {
      const response = await addManager(
        id,
        formData,
        localStorage.token
      );
      if (response) {
        toast.success(t("managerCreatedSuccessfully"), {
          autoClose: 300,
        });
        handleClose();
        document.getElementById("closeAddModal").click();
        getCompanyDetails();
      }
    } catch (error) {
      toast.error(t("somethingWentWrong"), {
        autoClose: 1000,
      });
      console.error("Error saving manager:", error);
    }
  };

  const handleClose = () => {
    setFormData({
      managerEmail: "",
      managerFirstName: "",
      managerLastName: "",
      phone: "",
      countryCode: "",
    });
    setErrors({
      managerEmail: false,
      managerFirstName: false,
      managerLastName: false,
    });
  };

  return (
    <>
      <div className={`section-body ${fixNavbar ? "marginTop" : ""} `}>
        <div className="container-fluid">
          <div className="col-lg-12 col-md-12">
            <div className="card">
              <div className="card-body">
                <div className="media">
                  <img
                    className="mr-3"
                    src={"../assets/images/company.jpg"}
                    alt="company logo"
                    style={{ width: "200px", height: "60px" }}
                  />
                  <div className="media-body">
                    <h5 className="m-0">{companyData.name}</h5>
                    <p className="text-muted mb-0">{companyData.email}</p>
                    <p className="text-muted mb-0">
                      {companyData.address?.street},
                      {companyData.address?.location},
                      {companyData.address?.postcode}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="section-body  py-4">
        <div className="container-fluid">
          <div className="row clearfix">
            <div className="col-12">
              <ul className="nav nav-tabs mb-3" id="pills-tab" role="tablist">
                <li className="nav-item">
                  <a
                    className="nav-link active"
                    id="pills-calendar-tab"
                    data-toggle="pill"
                    href="#pills-calendar"
                    role="tab"
                    aria-controls="pills-calendar"
                    aria-selected="false"
                  >
                    {t('overview')}
                  </a>
                </li>
              </ul>
            </div>
            <div className="col-md-12">
              <div className="tab-content" id="pills-tabContent">
                <div
                  className="tab-pane fade show active"
                  id="pills-calendar"
                  role="tabpanel"
                  aria-labelledby="pills-calendar-tab"
                >
                  <div className="row">
                    <div className="col-lg-2 col-md-4">
                      <div className="card">
                        <div className="card-body w_sparkline">
                          <div className="details">
                            <span>{t("totalEmployees")}</span>
                            <h3 className="mb-0">
                              <span className="counter">
                                {" "}
                                {totalEmployees && (
                                  <CountUp end={totalEmployees} />
                                )}
                              </span>
                            </h3>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-2 col-md-4">
                      <div className="card">
                        <div className="card-body w_sparkline">
                          <div className="details">
                            <span>{t("inactiveEmps")}</span>
                            <h3 className="mb-0">
                              {inactiveEmployees && (
                                <CountUp end={inactiveEmployees} />
                              )}
                            </h3>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-2 col-md-4">
                      <div className="card">
                        <div className="card-body w_sparkline">
                          <div className="details">
                            <span>{t("activeEmps")}</span>
                            <h3 className="mb-0">
                              {activeEmployees && (
                                <CountUp end={activeEmployees} />
                              )}
                            </h3>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-2 col-md-4">
                      <div className="card">
                        <div className="card-body w_sparkline">
                          <div className="details">
                            <span>{t("maleEmps")}</span>
                            <h3 className="mb-0 counter">
                              {" "}
                              {maleEmployees && <CountUp end={maleEmployees} />}
                            </h3>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-2 col-md-4">
                      <div className="card">
                        <div className="card-body w_sparkline">
                          <div className="details">
                            <span>{t("femaleEmps")}</span>
                            <h3 className="mb-0 counter">
                              {" "}
                              {feMaleEmployees && (
                                <CountUp end={feMaleEmployees} />
                              )}
                            </h3>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-2 col-md-4">
                      <div className="card">
                        <div className="card-body w_sparkline">
                          <div className="details">
                            <span>{t("otherEmps")}</span>
                            <h3 className="mb-0 counter">
                              {" "}
                              {otherEmployees && (
                                <CountUp end={otherEmployees} />
                              )}
                            </h3>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="card">
              <div className="card-header">
                <h3 className="card-title">Managers</h3>
                <div className="card-options">
                  <button
                    className="btn btn-primary"
                    data-toggle="modal"
                    data-target="#addModal"
                  >
                   {t('addManager')}
                  </button>
                </div>
              </div>
              <div className="card-body">
                <div className="table-responsive">
                  <table className="table table-hover table-striped text-nowrap table-vcenter mb-0">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>{t("manager")}</th>
                        <th>Email</th>
                        <th>{t("phone")}</th>
                        <th>{t("inviteSent")}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {companyData.managers?.map((manager, index) => (
                        <tr key={index}>
                          <td>{manager.id}</td>
                          <td>{manager.name}</td>
                          <td>{manager.managerEmail}</td>
                          <td>
                            {manager.countryCode}
                            {"-"}
                            {manager.phone}
                          </td>
                          <td style={{ whiteSpace: "nowrap" }}>
                            {manager.invitationSent ? (
                              <>
                                <span
                                  className="tag tag-success"
                                  style={{
                                    fontWeight: 600,
                                    textTransform: "uppercase",
                                  }}
                                >
                                  {t("inviteSent")}
                                </span>
                              </>
                            ) : (
                              <>
                                <span
                                  className="tag tag-warning"
                                  style={{
                                    fontWeight: 600,
                                    textTransform: "uppercase",
                                  }}
                                >
                                  {t("pending")}
                                </span>
                              </>
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="modal fade"
          id="addModal"
          tabIndex={-1}
          role="dialog"
          aria-labelledby="addModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="addModalLabel">
                {t('addNewManager')}
                </h5>
                <button
                  type="button"
                  id="closeAddModal"
                  className="close"
                  onClick={handleClose}
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="row clearfix">
                  <div className="col-md-12">
                    <div className="form-group">
                      <label className="form-label">{t('managerFirstName')}
                      <span
                        style={{
                          color: "red",
                          fontSize: "12px",
                          marginLeft: "1px",
                          marginRight: "4px",
                        }}
                      >
                        *
                      </span>
                      </label>
                      <input
                        type="text"
                        className={`form-control ${
                          errors.managerFirstName ? "is-invalid" : " "
                        }  `}
                        name="managerFirstName"
                        value={formData.managerFirstName}
                        onChange={handleInputChange}
                        placeholder={t("enterHere")}
                        required
                      />
                      {errors.managerFirstName && (
                        <div className="invalid-feedback">{t('required')}</div>
                      )}
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group">
                      <label className="form-label">{t('managerLastName')}
                      <span
                        style={{
                          color: "red",
                          fontSize: "12px",
                          marginLeft: "1px",
                          marginRight: "4px",
                        }}
                      >
                        *
                      </span>
                      </label>
                      <input
                        type="text"
                        className={`form-control ${
                          errors.managerLastName ? "is-invalid" : " "
                        }  `}
                        name="managerLastName"
                        value={formData.managerLastName}
                        onChange={handleInputChange}
                        placeholder={t("enterHere")}
                        required
                      />
                      {errors.managerLastName && (
                          <div className="invalid-feedback">{t('required')}</div>
                      )}
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group">
                      <label className="form-label">Manager Email
                      <span
                        style={{
                          color: "red",
                          fontSize: "12px",
                          marginLeft: "1px",
                          marginRight: "4px",
                        }}
                      >
                        *
                      </span>
                      </label>
                      
                      {/* <input type="text" className="form-control" placeholder="managers Email" /> */}
                      <input
                        type="managerEmail"
                        className={`form-control  ${
                          errors.managerEmail ? "is-invalid" : " "
                        }  `}
                        name="managerEmail"
                        value={formData.managerEmail}
                        onChange={handleInputChange}
                        placeholder={t("enterHere")}
                        required
                      />
                      {errors.managerEmail && (
                        <div className="invalid-feedback">{t('required')}</div>
                      )}
                    </div>
                  </div>
                  <div className="col-md-12">
                          <div className="form-group">
                            <label className="form-label">
                              {t("countryCode")}
                            </label>
                            <Select
                              options={countryCodeOptions}
                              name="countryCode"
                              value={
                                formData.countryCode
                                  ? countryCodeOptions.find(
                                      (option) =>
                                        option.value === formData.countryCode
                                    )
                                  : null
                              }
                              onChange={handleDropDownInputChange}
                              placeholder={t("select")}
                            />
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="form-group">
                            <label className="form-label">{t("phone")}</label>
                            <input
                              type="text"
                              className="form-control"
                              name="phone"
                              value={formData.phone}
                              onChange={handleInputChange}
                              placeholder={t("enterHere")}
                            />
                          </div>
                        </div>
                </div>
              </div>

              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={handleClose}
                  data-dismiss="modal"
                >
                  {t("close")}
                </button>
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={handleSubmit}
                >
                  {t("save")}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CompanyDetails;

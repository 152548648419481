import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { Dialog, IconButton } from "@mui/material";
import Loading from "../../Utility/Loading";
import { createInsuranceProvider, deleteInsuranceProviderById, editInsuranceProvider, getAllInsuranceProviders } from "../../../services/insuranceProviderService";
import { Trash2 } from "lucide-react";
import Select from "react-select";

const AccountSettings = () => {
  const fixNavbar = useSelector((state) => state.settings.isFixNavbar);
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation("common");
  const [insuranceProviderList, setInsuranceProviderList] = useState([]);
  const [email,setEmail] = useState();
  const [errors, setErrors] = useState({
    name: "",
    commission: "",
    email: "",
    documents:""
  });
  const [valid, setValid] = useState({
    name: false,
    commission: false,
    email: false,
    documents:false
  });
  const [formData, setFormData] = useState({
    name: "",
    commission: "",
    email: "",
    documents:[]
  });
  const [provider, setProvider] = useState({
    name: "",
    commission: "",
    email: "",
    documents:[]
  });
  const initialRow = {
    documentType: "",
    mandatory: false,
  };
  const [rows, setRows] = useState([initialRow]);

  useEffect(() => {
    getInsuranceProviderList();
  }, [])
  

  const handleAddRow = () => {
    setRows([...rows, { ...initialRow }]);
  };

  const handleRemoveRow = (index) => {
    const updatedRows = [...rows];
    updatedRows.splice(index, 1);
    setRows(updatedRows);
  };

  const handleRowChange = (index, field, value) => {
    const updatedRows = [...rows];
    updatedRows[index] = { ...updatedRows[index], [field]: value };
    setRows(updatedRows);
  };

  const provoderOptions = [
    { value: "VIACTIVKRANKENKASSE", label: "Viactiv Krankenkasse"},
    { value: "MOBILKRANKENKASSE", label: "Mobil Krankenkasse" }
  ];

  const providerMap = {
     "VIACTIVKRANKENKASSE":"Viactiv Krankenkasse",
     "MOBILKRANKENKASSE":"Mobil Krankenkasse"
  }



  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setErrors({ ...errors, [name]: "" });
  };

  const handleDropDownInputChange = (e) => {
    setFormData({ ...formData, ["name"]: e.value });
    setErrors({ ...errors, ["name"]: "" });
  };

  const handleClose = () => {
    setFormData({
      name: "",
      commission: "",
      email: "",
      documents:[]
    });
    setErrors({
      name: "",
      commission: "",
      documents:""
    });
    setValid({
      name: false,
      commission: false,
      email: false,
      documents:false
    });
    setRows([initialRow]);
  };

  const handleEditClose = () => {
    setProvider(  {
      name: "",
      commission: "",
      email: "",
      documents:[]
    });
    setErrors({
      name: "",
      commission: "",
      documents:""
    });
    setValid({
      name: false,
      commission: false,
      email: false,
      documents:false
    });
  };


  const validateForm = (formData) => {
    const newErrors = {};
    if (!formData.name || formData.name.trim() === "") {
      newErrors.name = t('nameOfInsuranceProviderIsRequired');
    }
    if (!formData.email || formData.email.trim() === "") {
      newErrors.email =  t('emailIsRequired');
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = () => {
    const isFormValid = validateForm(formData);
    if (isFormValid) {
      setIsLoading(true);
      const valid = {};
      valid.name = true;
      // valid.commission = true;
      setValid(valid);
      formData.documents = rows;
      addInsuranceProvider(formData);
    }
  };

  const getInsuranceProviderList = async ()=>{
    try {
      const response = await getAllInsuranceProviders(localStorage.token);
      setInsuranceProviderList(response);
    } catch (error) {
      console.log(error);
    }
    }

  const addInsuranceProvider = async (data) => {
    try {
      console.log('data',data);
      const response = await createInsuranceProvider(localStorage.token, data);
      if (!response) {
        setIsLoading(false);
        setValid({
          name: false,
          commission: false,
          email:false
        });
        toast.info(t('duplicateInsuranceProviderName'), {
          autoClose: 2000,
        });
      } else {
        setIsLoading(false);
        toast.success(t('providerAddedSuccessfully'), {
          autoClose: 300,
        });
        handleClose();
        document.getElementById("closeAddModal").click();
        getInsuranceProviderList();
      }
    } catch (error) {
      setIsLoading(false);
      toast.error("something went wrong", {
        autoClose: 1000,
      });
      console.error("Error saving work provider:", error);
    }
  };

  const deleteInsuranceProvider = async (id) => {
    try {
      const response = await deleteInsuranceProviderById(localStorage.token, id);
      if (response) {
        toast.success(t('deletedSuccessfully'));
        getInsuranceProviderList();
      } else {
        toast.error(t('somethingWentWrong'));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getDocumentTypes = (provider) => {
    if (!provider || !provider.documents || provider.documents.length === 0) {
      return ''; 
    }
    const documentTypes = provider.documents.map(doc => doc.documentType);
    const uniqueDocumentTypes = [...new Set(documentTypes)].join(', ');
    return uniqueDocumentTypes;
  }

  const handleEditClick = (data) => {
    console.log('edit provider',data);
    setProvider({
    id:data.id,
    name: data.name,
    email: data.email,
    documents: data.documents
  })
  setEmail(data.email);
  console.log('clicked');
  }

  const handleEditSubmit = (id) => {
    const newErrors = {}; 
    if (!email || email.trim() === "") {
      newErrors.email =  t('emailIsRequired');
    }
    setErrors(newErrors);
    if(Object.keys(newErrors).length === 0){
      const valid = {};
      valid.email = true;
      setValid(valid);
      editInsuranceProviderEmail(id,email);
    }
  }

  const editInsuranceProviderEmail = async (id,email) => {
    try {
      console.log('id',id);
      console.log('email',email);
      const response = await editInsuranceProvider(localStorage.token, id, email);
      if (!response) {
        setIsLoading(false);
        setValid({
          name: false,
          commission: false,
          email:false
        });
        toast.error("something went wrong", {
          autoClose: 1000,
        });
      } else {
        setIsLoading(false);
        toast.success(t('providerEditedSuccessfully'), {
          autoClose: 300,
        });
        handleEditClose();
        document.getElementById("closeEditModal").click();
        getInsuranceProviderList();
      }
    } catch (error) {
      setIsLoading(false);
      toast.error("something went wrong", {
        autoClose: 1000,
      });
      console.error("Error editing  provider:", error);
    }
  };



  return (
    <>

      <div className={`section-body ${fixNavbar ? "marginTop" : ""}`}>
        <div className="container-fluid">
          <div className="d-flex justify-content-between align-items-center">
            <ul className="nav nav-tabs page-header-tab">
              <li className="nav-item">
                <a
                  className="nav-link active"
                  id="work-modal-tab"
                  data-toggle="tab"
                  href="#work-modal-settings"
                >
                 {t('insuranceProviders')}
                </a>
              </li>
            </ul>
            {/* <div className="header-action">
              <button
                type="button"
                className="btn btn-primary mt-2 btn-block"
                data-toggle="modal"
                data-target="#addModal"
              >
                <i className="fe fe-plus mr-2" />
                {t('addInsuranceProvider')}
              </button>
            </div> */}
          </div>
        </div>
      </div>
      <div className="section-body mt-3">
        <div className="container-fluid">
          <div className="tab-content mt-3">
            <div
              className="tab-pane fade show active"
              id="work-modal-settings"
              role="tabpanel"
            >
              <div className="row clearfix">
                <div className="col-12">
                  <div className="card">
                    <div className="card-header">
                      <h3 className="card-title" style={{ fontWeight: 500 }}>
                      {t('savedInsuranceProviders')}
                      </h3>
                    </div>
                    <div className="card-body">
                      <div className="col-12">
                        <div className="table-responsive">
                          <table className="table table-striped">
                            <thead className="thead-light">
                              <tr>
                                <th style={{ fontWeight: 600 }}>Name</th>
                                <th style={{ fontWeight: 600 }}>
                                 Email
                                </th>
                                <th style={{ fontWeight: 600,width:'600px' }}> {t('documents')}</th>
                                {/* <th style={{ fontWeight: 600 }}>{t('action')}</th> */}
                              </tr>
                            </thead>
                            <tbody>
                              {insuranceProviderList.map((provider, index) => (
                                <tr key={index}>
                                  <td style={{ fontSize: '15px',whiteSpace:"nowrap" }}>{providerMap[provider.name]}</td>
                                  <td style={{ fontSize: '15px' }}>
                                    {provider.email}
                                  </td>
                                  <td style={{ fontSize: '15px' }}>{getDocumentTypes(provider)}</td>
                                  {/* <td>
                                   <button
                                      type="button"
                                      className="btn btn-icon js-sweetalert"
                                      title="Edit"
                                      onClick={()=>handleEditClick(provider)}
                                      data-toggle="modal"
                                      data-target="#editModal"
                                    >
                                      <i className="fa fa-pencil" />
                                    </button>
                                    <button
                                      type="button"
                                      className="btn btn-icon js-sweetalert"
                                      title="Delete"
                                      data-type="confirm"
                                      onClick={() => deleteInsuranceProvider(provider.id)}
                                      >
                                      <i className="fa fa-trash-o text-red-400" />
                                    </button>
                                  </td> */}
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="modal fade"
            id="addModal"
            tabIndex={-1}
            role="dialog"
            aria-labelledby="addModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-lg" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="addModalLabel">
                 {t('onboardNewInsuranceProvider')}
                  </h5>
                  <button
                    type="button"
                    id="closeAddModal"
                    className="close"
                    onClick={handleClose}
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                <div className="modal-body">
                  <div className="row clearfix">
                    <div className="col-md-6 col-sm-12">
                      <div className="form-group">
                        <label className="form-label">
                        {t('insuranceProviderName')}
                          <span
                            style={{
                              color: "red",
                              fontSize: "12px",
                              marginLeft: "1px",
                              marginRight: "4px",
                            }}
                          >
                            *
                          </span>
                        </label>
                        <Select
                      options={provoderOptions}
                      value={provoderOptions.find(
                        (option) => option.value === formData.name
                      )}
                      onChange={handleDropDownInputChange}
                      placeholder="Select"
                    />
                        {errors.name && (
                          <div className="invalid-feedback">{errors.name}</div>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6 col-sm-12">
                      <div className="form-group">
                        <label className="form-label">
                          Email
                          <span
                            style={{
                              color: "red",
                              fontSize: "12px",
                              marginLeft: "1px",
                              marginRight: "4px",
                            }}
                          >
                            *
                          </span>
                        </label>
                        <input
                          type="text"
                          className={`form-control ${
                            valid.email ? "is-valid" : " "
                          }  ${errors.email ? "is-invalid" : " "}  `}
                          name="email"
                          value={formData.email}
                          onChange={handleInputChange}
                          placeholder="Enter Email"
                          required
                        />
                        {errors.email && (
                          <div className="invalid-feedback">
                            {errors.email}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="col-12">
                        <label className="form-label">
                        {t('listOfDocuments')}
                        </label>
                          {rows.map((row, index) => (
                            <>
                              <hr style={{ margin: "0px 0px" }}></hr>
                              <div className="row mb-2">
                                <div className="col-lg-6 col-md-12 mt-2">
                                  <input
                                    type="text"
                                    placeholder={t("addDocumentTypes")}
                                    className="w-full p-2 border rounded"
                                    value={row.documentType}
                                    onChange={(e) =>
                                      handleRowChange(
                                        index,
                                        "documentType",
                                        e.target.value
                                      )
                                    }
                                  />
                                </div>
                                <div className="col-lg-4 col-md-12 mt-3">
                                  <label className="custom-control custom-checkbox">
                                    <input
                                      type="checkbox"
                                      className="custom-control-input"
                                      checked={row.mandatory}
                                      onChange={(e) =>
                                        handleRowChange(
                                          index,
                                          "mandatory",
                                          e.target.checked
                                        )
                                      }
                                    />
                                    <span
                                      className="custom-control-label"
                                      style={{ fontWeight: 500 }}
                                    >
                                      {t("mandatory")}
                                    </span>
                                  </label>
                                </div>
                                <div className="col-lg-2 col-md-12 mt-2">
                                  <IconButton
                                    onClick={() => handleRemoveRow(index)}
                                  >
                                    <Trash2 className="h-6 w-6 text-red-400" />
                                  </IconButton>
                                </div>
                              </div>
                              <hr style={{ margin: "0px 0px" }}></hr>
                            </>
                          ))}
                          <div className="flex gap-5 mt-4  mb-4 justify-start">
                            <button
                              className="btn btn-info"
                              onClick={handleAddRow}
                            >
                             {t('addRow')}
                            </button>
                          </div>
                     </div>
                  </div>
                </div>

                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={handleClose}
                    data-dismiss="modal"
                  >
                    {t('close')}
                  </button>
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={handleSubmit}
                  >
                    {t('save')}
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div
            className="modal fade"
            id="editModal"
            tabIndex={-1}
            role="dialog"
            aria-labelledby="editModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-lg" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="editModalLabel">
                 {t('editInsuranceProvider')}
                  </h5>
                  <button
                    type="button"
                    id="closeEditModal"
                    className="close"
                    onClick={handleClose}
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                <div className="modal-body">
                  <div className="row clearfix">
                    <div className="col-md-6 col-sm-12">
                      <div className="form-group">
                        <label className="form-label">
                        {t('insuranceProviderName')}
                          <span
                            style={{
                              color: "red",
                              fontSize: "12px",
                              marginLeft: "1px",
                              marginRight: "4px",
                            }}
                          >
                            *
                          </span>
                        </label>
                        <Select
                      options={provoderOptions}
                      value={provoderOptions.find(
                        (option) => option.value === provider.name
                      )}
                      isDisabled={true}
                      placeholder="Select"
                    />
                        {errors.name && (
                          <div className="invalid-feedback">{errors.name}</div>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6 col-sm-12">
                      <div className="form-group">
                        <label className="form-label">
                          Email
                          <span
                            style={{
                              color: "red",
                              fontSize: "12px",
                              marginLeft: "1px",
                              marginRight: "4px",
                            }}
                          >
                            *
                          </span>
                        </label>
                        <input
                          type="text"
                          className={`form-control ${
                            valid.email ? "is-valid" : " "
                          }  ${errors.email ? "is-invalid" : " "}  `}
                          name="email"
                          value={email}
                          onChange={(e)=>setEmail(e.target.value)}
                          placeholder="Enter Email"
                          required
                        />
                        {errors.email && (
                          <div className="invalid-feedback">
                            {errors.email}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="col-12">
                        <label className="form-label">
                        {t('listOfDocuments')}
                        </label>
                          {provider.documents && provider.documents.length>0 && provider.documents.map((row, index) => (
                            <>
                              <hr style={{ margin: "0px 0px" }}></hr>
                              <div className="row mb-2">
                                <div className="col-lg-6 col-md-12 mt-2">
                                  <input
                                    type="text"
                                    placeholder={t("addDocumentTypes")}
                                    className="w-full p-2 border rounded"
                                    value={row.documentType}
                                    disabled
                                  />
                                </div>
                                <div className="col-lg-4 col-md-12 mt-3">
                                  <label className="custom-control custom-checkbox">
                                    <input
                                      type="checkbox"
                                      className="custom-control-input"
                                      checked={row.mandatory}
                                      disabled
                                    />
                                    <span
                                      className="custom-control-label"
                                      style={{ fontWeight: 500 }}
                                    >
                                      {t("mandatory")}
                                    </span>
                                  </label>
                                </div>
                              </div>
                              <hr style={{ margin: "0px 0px" }}></hr>
                            </>
                          ))}
                     </div>
                  </div>
                </div>

                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={handleEditClose}
                    data-dismiss="modal"
                  >
                    {t('close')}
                  </button>
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={()=>handleEditSubmit(provider.id)}
                  >
                    {t('save')}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Dialog open={isLoading} style={{backdropFilter: "blur(2px)"}} fullWidth>
          <div
            className="flex flex-col justify-center"
            style={{ backgroundColor: "transparent" }}
          >
            <div className="mx-auto" style={{ backgroundColor: "transparent" }}>
              <Loading />
            </div>
          </div>
        </Dialog>
      </div>
    </>
  );
};

export default AccountSettings;

import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Select from "react-select";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import countryData from "../../../constants/countryData";
import { NavLink } from "react-router-dom/cjs/react-router-dom";
import {
  createCompany,
  deleteCompanyById,
  getCompanyList,
} from "../../../services/companyService";
import { Dialog, IconButton } from "@mui/material";
import { ChevronLeft, ChevronRight, Trash2 } from "lucide-react";
import Loading from "../../Utility/Loading";

function Companies(props) {
  const { fixNavbar } = props;
  const itemsPerPage = 10;
  const [currentPage, setCurrentPage] = useState(1);
  const [companyData, setCompanyData] = useState([]);
  const [companyId, setCompanyId] = useState("");
  const { t } = useTranslation("common");

  const initialErrorState = {
    name: "",
    email: "",
    managerFirstName: "",
    managerLastName: "",
    managerEmail: "",
    referenceNum: "",
    street: "",
    houseNumber: "",
    postcode: "",
    location: "",
    companyCommissionPer: "",
    agentCommissionPer: "",
    providerId: "",
  };
  const initialValidState = {
    name: false,
    email: false,
    managerFirstName: false,
    managerLastName: false,
    managerEmail: false,
    referenceNum: false,
    street: false,
    houseNumber: false,
    postcode: false,
    location: false,
    companyCommissionPer: false,
    agentCommissionPer: false,
    providerId: false,
  };
  const initalFormData = {
    name: "",
    email: "",
    referenceNum: "",
    registrationId: "",
    street: "",
    houseNumber: "",
    postcode: "",
    location: "",
    companyCommissionPer: "",
    agentCommissionPer: "",
    providerId: "",
  };
  const initialRow = {
    managerFirstName: "",
    managerLastName: "",
    managerEmail: "",
    countryCode: "",
    phone: "",
  };
  const [rows, setRows] = useState([initialRow]);

  const [errors, setErrors] = useState(initialErrorState);
  const [valid, setValid] = useState(initialValidState);
  const [formData, setFormData] = useState(initalFormData);
  const [noManagerAdded, setNoManagerAdded] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [companyOptions, setCompanyOptions] = useState([]);
  const [searchName, setSearchName] = useState("");
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [cityOptions, setCityOptions] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const handleAddRow = () => {
    setRows([...rows, { ...initialRow }]);
    if (rows.length === 0) {
      setNoManagerAdded(false);
    }
  };

  const handleRemoveRow = (index) => {
    const updatedRows = [...rows];
    updatedRows.splice(index, 1);
    if (updatedRows.length === 0) {
      setNoManagerAdded(true);
    }
    setRows(updatedRows);
  };

  const handleRowChange = (index, field, value) => {
    const updatedRows = [...rows];
    if (field === "countryCode" && value) {
      updatedRows[index] = {
        ...updatedRows[index],
        [field]: value.value,
      };
    } else {
      updatedRows[index] = { ...updatedRows[index], [field]: value };
    }
    setRows(updatedRows);
  };

  useEffect(() => {
    fetchCompanyData();
  }, []);

  useEffect(() => {}, [companyData, companyOptions, cityOptions]);

  const countryCodeOptions = countryData.map((country) => ({
    value: country.dial_code,
    label: `${country.dial_code} - ${country.name}`,
  }));

  const fetchCompanyData = async () => {
    try {
      const response = await getCompanyList(localStorage.token);
      setCompanyData(response);
      setFilteredData(response);
      handleOptions(response);
    } catch (error) {
      console.log(error);
    }
  };

  const handleOptions = (response) => {
    const uniqueCitySet = new Set();
    const cityOptions = response
      .filter((emp) => emp.address.location)
      .filter((emp) => {
        const city = emp.address.location;
        if (!uniqueCitySet.has(city)) {
          uniqueCitySet.add(city);
          return true;
        }
        return false;
      })
      .map((emp) => ({
        label: emp.address.location,
        value: emp.address.location,
      }));
    setCityOptions(cityOptions);

    const uniqueCompanySet = new Set();
    const companyOptions = response
      .filter((emp) => emp.name)
      .filter((emp) => {
        const company = emp.name;
        if (!uniqueCompanySet.has(company)) {
          uniqueCompanySet.add(company);
          return true;
        }
        return false;
      })
      .map((emp) => ({
        label: emp.name,
        value: emp.name,
      }));

    setCompanyOptions(companyOptions);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setErrors({ ...errors, [name]: "" });
  };

  const validateForm = (formData) => {
    const newErrors = {};
    if (!formData.name || formData.name.trim() === "") {
      newErrors.name = t("companyNameIsRequired");
    }
    if (!formData.email || formData.email.trim() === "") {
      newErrors.email = t("companyEmailIsRequired");
    }

    if (!formData.street || formData.street.trim() === "") {
      newErrors.street = t("streetRequired");
    }
    if (!formData.houseNumber || formData.houseNumber.trim() === "") {
      newErrors.houseNumber = t("required");
    }
    if (!formData.location || formData.location.trim() === "") {
      newErrors.location = t("cityRequired");
    }
    if (!formData.postcode || formData.postcode.trim() === "") {
      newErrors.postcode = t("postCodeRequired");
    }
    if (!formData.referenceNum || formData.referenceNum.trim() === "") {
      newErrors.referenceNum = t("referenceNumIsRequired");
    }
    if (rows.length > 0) {
      rows.forEach((row, rowIndex) => {
        if (!row.managerFirstName || row.managerFirstName.trim() === "") {
          newErrors.managerFirstName = t("required");
        }
        if (!row.managerLastName || row.managerLastName.trim() === "") {
          newErrors.managerLastName = t("required");
        }
        if (!row.managerEmail || row.managerEmail.trim() === "") {
          newErrors.managerEmail = t("required");
        }
      });
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = () => {
    if (noManagerAdded) {
      toast.error(t("noManagerMsg"));
      return;
    }
    const isFormValid = validateForm(formData);
    if (isFormValid) {
      const valid = {};
      valid.name = true;
      valid.email = true;
      valid.street = true;
      valid.houseNumber = true;
      valid.location = true;
      valid.postcode = true;
      valid.referenceNum = true;
      setValid(valid);

      const request = {
        name: formData.name,
        email: formData.email,
        managers: rows,
        address: {
          street: formData.street,
          postcode: formData.postcode,
          location: formData.location,
          houseNumber: formData.houseNumber,
        },
        countryCode: formData.countryCode,
        phone: formData.phone,
        referenceNum: formData.referenceNum,
        registrationId: formData.registrationId,
        companyCommissionPer: formData.companyCommissionPer,
        agentCommissionPer: formData.agentCommissionPer,
      };
      addCompany(request);
    }
  };

  const addCompany = async (companyData) => {
    try {
      console.log("save company", companyData);
      const response = await createCompany(localStorage.token, companyData);
      if (!response) {
        setValid(initialValidState);
        toast.info(t("companyNameExists"), {
          autoClose: 2000,
        });
      } else {
        toast.success(
          t("companyCreatedSuccessfully") + " - " + t("companyManagerMsg"),
          {
            autoClose: 500,
          }
        );
        handleClose();
        formData.storeId = "";
        document.getElementById("closeAddModal").click();
        fetchCompanyData();
      }
    } catch (error) {
      toast.error(t("somethingWentWrong"), {
        autoClose: 1000,
      });
      console.error("Error saving company:", error);
    }
  };

  const handleClose = () => {
    setFormData(initalFormData);
    setErrors(initialErrorState);
    setValid(initialValidState);
    setRows([initialRow]);
  };

  const resetFilters = () => {
    setSelectedLocation(null);
    setSearchName("");
    setSelectedCompany(null);
    setFilteredData(companyData);
  };

  const applyFilters = () => {
    const filteredData = companyData
      .filter(
        (company) =>
          !searchName || company.managerEmail.toLowerCase().includes(searchName)
      )
      .filter(
        (company) =>
          !selectedLocation ||
          selectedLocation.value === company.address.location
      )
      .filter(
        (company) => !selectedCompany || selectedCompany.value === company.name
      );
    setFilteredData(filteredData);
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);

  const totalPages = Math.ceil(filteredData.length / itemsPerPage);

  const getDisplayPages = () => {
    const pages = [];
    const pageWindow = 3; // Number of pages to display

    if (totalPages <= pageWindow) {
      for (let i = 1; i <= totalPages; i++) {
        pages.push(i);
      }
    } else {
      if (currentPage <= 1) {
        for (let i = 1; i <= pageWindow; i++) {
          pages.push(i);
        }
      } else if (currentPage >= totalPages) {
        for (let i = totalPages - pageWindow + 1; i <= totalPages; i++) {
          pages.push(i);
        }
      } else {
        const start = currentPage - 1;
        const end = currentPage + 1;

        for (let i = start; i <= end; i++) {
          pages.push(i);
        }
      }
    }

    return pages;
  };

  const handleClick = (newPage) => {
    if (newPage >= 1 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };
  const handleDeleteClick = (id, name) => {
    setCompanyId(id);
  };

  const handleDeleteConfirm = () => {
    setIsLoading(true);
    deleteCompany();
    setIsLoading(false);
  };
  const deleteCompany = async () => {
    try {
      const response = await deleteCompanyById(localStorage.token, companyId);
      if (response) {
        document.getElementById("closeConfirmModal").click();
        fetchCompanyData();
        toast.success(t("companyDeletedSuccessfully"));
      } else {
        toast.error(t("somethingWentWrong"));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const invalidFeedbackStyle = {
    display: "block",
    width: "100%",
    marginTop: "0.25rem",
    fontSize: "90%",
    color: "#dc3545",
  };

  return (
    <div>
      <div className={`section-body ${fixNavbar ? "marginTop" : ""}`}>
        <div className="container-fluid">
          <div className="d-flex justify-content-between align-items-center">
            <ul className="nav nav-tabs page-header-tab">
              <li className="nav-item">
                <a
                  className="nav-link active"
                  id="Departments-tab"
                  data-toggle="tab"
                  href="#Departments-list"
                >
                  Liste
                </a>
              </li>
              {/* <li className="nav-item"><a className="nav-link" id="Departments-tab" data-toggle="tab" href="#Departments-grid">Grid View</a></li>
               */}
            </ul>
            <div className="header-action">
              <button
                type="button"
                className="btn btn-primary mt-2 btn-block"
                data-toggle="modal"
                data-target="#addModal"
              >
                <i className="fe fe-plus mr-2" />
                {t("addNewCompany")}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="section-body mt-3">
        <div className="container-fluid">
          {/* <div className="row">
            <div className="col-lg-2 col-md-4">
              <div className="card">
                <div className="card-body w_sparkline">
                  <div className="details">
                    <span>{t("totalEmployees")}</span>
                    <h3 className="mb-0">
                      <span className="counter">
                        {" "}
                        {employeeCountData.totalEmployees && (
                          <CountUp end={employeeCountData.totalEmployees} />
                        )}
                      </span>
                    </h3>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-2 col-md-4">
              <div className="card">
                <div className="card-body w_sparkline">
                  <div className="details">
                    <span> {t("mailSent")}</span>
                    <h3 className="mb-0">
                      {employeeCountData.mailSent && (
                        <CountUp end={employeeCountData.mailSent} />
                      )}
                    </h3>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-2 col-md-4">
              <div className="card">
                <div className="card-body w_sparkline">
                  <div className="details">
                    <span style={{whiteSpace:"nowrap"}}>{t("documentsUploaded")}</span>
                    <h3 className="mb-0">
                      {employeeCountData.documentUploaded && (
                        <CountUp end={employeeCountData.documentUploaded} />
                      )}
                    </h3>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-2 col-md-4">
              <div className="card">
                <div className="card-body w_sparkline">
                  <div className="details">
                    <span>{t("maleEmps")}</span>
                    <h3 className="mb-0 counter">
                      {" "}
                      {employeeCountData.maleEmployees && (
                        <CountUp end={employeeCountData.maleEmployees} />
                      )}
                    </h3>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-2 col-md-4">
              <div className="card">
                <div className="card-body w_sparkline">
                  <div className="details">
                    <span>{t("femaleEmps")}</span>
                    <h3 className="mb-0 counter">
                      {" "}
                      {employeeCountData.feMaleEmployees && (
                        <CountUp end={employeeCountData.feMaleEmployees} />
                      )}
                    </h3>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-2 col-md-4">
              <div className="card">
                <div className="card-body w_sparkline">
                  <div className="details">
                    <span>{t("diverseEmployees")}</span>
                    <h3 className="mb-0 counter">
                      {" "}
                      {employeeCountData.otherEmployees && (
                        <CountUp end={employeeCountData.otherEmployees} />
                      )}
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
          <div className="tab-content mt-3">
            <div
              className="tab-pane fade show active"
              id="Departments-list"
              role="tabpanel"
            >
              <div className="card">
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-2 col-sm-6">
                      <label>{t("company")}</label>
                      <div className="multiselect_div">
                        <Select
                          className="react-select-container"
                          classNamePrefix="react-select"
                          options={companyOptions}
                          value={selectedCompany}
                          onChange={(selectedOption) =>
                            setSelectedCompany(selectedOption)
                          }
                          placeholder={t("select")}
                        />
                      </div>
                    </div>
                    <div className="col-md-2 col-sm-6">
                      <label>{t("location")}</label>
                      <div className="form-group">
                        <Select
                          className="react-select-container"
                          classNamePrefix="react-select"
                          options={cityOptions}
                          value={selectedLocation}
                          onChange={(selectedOption) =>
                            setSelectedLocation(selectedOption)
                          }
                          placeholder={t("select")}
                        />
                      </div>
                    </div>
                    <div className="col-md-3 col-sm-6">
                      <label>{t("searchByEmail")}</label>
                      <div className="input-group">
                        <input
                          type="text"
                          value={searchName}
                          onChange={(e) => setSearchName(e.target.value)}
                          className="form-control"
                          placeholder={t("enterHere")}
                        />
                      </div>
                    </div>
                    <div className="col-md-2 col-sm-6">
                      <label>&nbsp;</label>
                      <button
                        onClick={() => {
                          applyFilters();
                        }}
                        className="btn btn-sm btn-primary btn-block p-2"
                      >
                        {t("filter")}
                      </button>
                    </div>
                    <div className="col-md-2 col-sm-6">
                      <label>&nbsp;</label>
                      <button
                        onClick={() => {
                          resetFilters();
                        }}
                        className="btn btn-sm btn-primary btn-block p-2"
                      >
                        {t("reset")}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="card-header">
                  <h3 className="card-title" style={{ fontWeight: 500 }}>
                    {t("companyList")}
                  </h3>
                </div>
                <div className="card-body">
                  <div className="table-responsive">
                    <table className="table  table-vcenter table-hover mb-0">
                      <thead className="thead-light">
                        <tr>
                          <th style={{ fontWeight: 600 }} className="w40">#</th>
                          <th style={{ whiteSpace: "nowrap", fontWeight: 600 }}>
                            {t("companyName")}
                          </th>
                          <th style={{ whiteSpace: "nowrap", fontWeight: 600 }}>
                            {t("referenceNum")}
                          </th>
                          <th style={{ whiteSpace: "nowrap", fontWeight: 600 }}>
                            {t("registrationId")}
                          </th>
                          <th style={{ whiteSpace: "nowrap", fontWeight: 600 }}>
                            Manager
                          </th>
                          <th style={{ whiteSpace: "nowrap", fontWeight: 600 }}>
                            {t("accountCreated")}
                          </th>
                          <th style={{ whiteSpace: "nowrap", fontWeight: 600 }}>
                            {t("phoneNumber")}
                          </th>
                          <th style={{ whiteSpace: "nowrap", fontWeight: 600 }}>
                            Status
                          </th>
                          <th style={{ whiteSpace: "nowrap", fontWeight: 600 }}>
                            {t("employeeCount")}
                          </th>
                          <th style={{ whiteSpace: "nowrap", fontWeight: 600 }}>
                            {t("location")}
                          </th>
                          <th style={{ whiteSpace: "nowrap", fontWeight: 600 }}>
                            {t("action")}
                          </th>
                        </tr>
                      </thead>
                      <tbody className="table-hover">
                        {currentItems.map((company, index) => (
                          <tr key={company.id}>
                            <td className="w40">{company.index}</td>
                            <td>
                              <div>
                                <NavLink
                                  to={"/company/info?id=" + company.companyId}
                                >
                                  <h6
                                    className="mb-0"
                                    style={{ whiteSpace: "nowrap" }}
                                  >
                                    {company.name}
                                  </h6>
                                </NavLink>

                                <span className="text-muted">
                                  {company.email}
                                </span>
                              </div>
                            </td>
                            <td style={{ whiteSpace: "nowrap" }}>
                              {company.referenceNum}
                            </td>
                            <td style={{ whiteSpace: "nowrap" }}>
                              {company.registrationId}
                            </td>
                            <td
                              className="d-flex"
                              style={{ whiteSpace: "nowrap" }}
                            >
                              <span
                                className="avatar avatar-blue"
                                data-toggle="tooltip"
                                data-original-title={company.managerName}
                              >
                                {company.managerName.substring(0, 2)}
                              </span>
                              <div className="ml-3">
                                <h6 className="mb-0">
                                  <NavLink
                                    to={
                                      "/employee/details?id=" +
                                      company.companyId
                                    }
                                  >
                                    {company.managerName}
                                  </NavLink>
                                </h6>
                                <span className="text-muted">
                                  {company.managerEmail}
                                </span>
                              </div>
                            </td>
                            <td style={{ whiteSpace: "nowrap" }}>
                              {company.invitationSent ? (
                                <>
                                  <span
                                    className="tag tag-success"
                                    style={{
                                      fontWeight: 600,
                                      textTransform: "uppercase",
                                    }}
                                  >
                                    {t("inviteSent")}
                                  </span>
                                </>
                              ) : (
                                <>
                                  <span
                                    className="tag tag-warning"
                                    style={{
                                      fontWeight: 600,
                                      textTransform: "uppercase",
                                    }}
                                  >
                                    {t("pending")}
                                  </span>
                                </>
                              )}
                            </td>
                            <td style={{ whiteSpace: "nowrap" }}>
                              {company.managerPhone}
                            </td>
                            <td style={{ whiteSpace: "nowrap" }}>
                              {company.onboardingStatus === "STARTED" && (
                                <span
                                  className="tag tag-success"
                                  style={{ fontWeight: 600 }}
                                >
                                  {company.onboardingStatus}
                                </span>
                              )}
                              {company.onboardingStatus === "INITIATED" && (
                                <span
                                  className="tag tag-orange"
                                  style={{ fontWeight: 600 }}
                                >
                                  {company.onboardingStatus}
                                </span>
                              )}
                            </td>

                            <td style={{ whiteSpace: "nowrap" }}>
                              <span
                                style={{
                                  justifyContent: "center",
                                  backgroundColor: "#90CDF4",
                                  color: "#374151",
                                  padding: "0.5rem 1rem",
                                  borderRadius: "999px",
                                  fontWeight: "bold",
                                  marginLeft: "10px",
                                }}
                              >
                                {company.employeeCount}
                              </span>
                            </td>
                            <td style={{ whiteSpace: "nowrap" }}>
                              {company.address?.location}
                            </td>
                            <td
                              style={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                               <NavLink
                                    className="btn btn-icon btn-sm"
                                    title="View"
                                    to={"/company/info?id=" + company.companyId}
                                    target="_blank"
                                  >
                                    <i className="fa fa-eye" />
                                  </NavLink>
                                  <NavLink
                                    className="btn btn-icon btn-sm"
                                    title="View"
                                    to={"/company/info?id=" + company.companyId}
                                    target="_blank"
                                  >
                                    <i className="fa fa-user-plus" />
                                  </NavLink>
                              {/* <button
                                type="button"
                                className="btn btn-icon btn-sm"
                                title="View"
                              >
                                <i className="fa fa-eye" />
                              </button>
                              <button
                                type="button"
                                //onClick={() => handleEditStoreModal(store)}
                                className="btn btn-icon"
                                title="Edit"
                                data-toggle="modal"
                                data-target="#editStoreModal"
                              >
                                <i className="fa fa-edit" />
                              </button> */}
                              <button
                                type="button"
                                onClick={() =>
                                  handleDeleteClick(
                                    company.companyId,
                                    company.name
                                  )
                                }
                                className="btn btn-icon"
                                data-toggle="modal"
                                data-target="#deleteConfirmationModal"
                              >
                                <i className="fa fa-trash-o text-red-400" />
                              </button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="addModal"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="addModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-xl" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="addModalLabel">
                {t("addNewCompany")}
              </h5>
              <button
                type="button"
                id="closeAddModal"
                className="close"
                onClick={handleClose}
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body p-4">
              <div className="row clearfix">
                <div
                  className="col-12 mb-4"
                  style={{ fontWeight: 650, color: "#b7b7b7" }}
                >
                  <span>{t("companyDetails")}</span>
                </div>
                <div className="col-md-4 col-sm-6">
                  <div className="form-group">
                    <label className="form-label">
                      {t("companyName")}
                      <span
                        style={{
                          color: "red",
                          fontSize: "12px",
                          marginLeft: "1px",
                          marginRight: "4px",
                        }}
                      >
                        *
                      </span>
                    </label>
                    <input
                      type="text"
                      className={`form-control ${
                        valid.name ? "is-valid" : " "
                      }  ${errors.name ? "is-invalid" : " "}  `}
                      name="name"
                      value={formData.name}
                      onChange={handleInputChange}
                      placeholder={t("enterHere")}
                      required
                    />
                    {errors.name && (
                      <div className="invalid-feedback">{errors.name}</div>
                    )}
                  </div>
                </div>
                <div className="col-md-4 col-sm-6">
                  <div className="form-group">
                    <label className="form-label">
                      {t("companyEmail")}
                      <span
                        style={{
                          color: "red",
                          fontSize: "12px",
                          marginLeft: "1px",
                          marginRight: "4px",
                        }}
                      >
                        *
                      </span>
                    </label>
                    <input
                      type="text"
                      className={`form-control ${
                        valid.email ? "is-valid" : " "
                      }  ${errors.email ? "is-invalid" : " "}  `}
                      name="email"
                      value={formData.email}
                      onChange={handleInputChange}
                      placeholder={t("enterHere")}
                      required
                    />
                    {errors.email && (
                      <div className="invalid-feedback">{errors.email}</div>
                    )}
                  </div>
                </div>
                <div className="col-md-4 col-sm-6">
                  <div className="form-group">
                    <label className="form-label">
                      {t("referenceNum")}
                      <span
                        style={{
                          color: "red",
                          fontSize: "12px",
                          marginLeft: "1px",
                          marginRight: "4px",
                        }}
                      >
                        *
                      </span>
                    </label>
                    <input
                      type="text"
                      className={`form-control ${
                        valid.referenceNum ? "is-valid" : " "
                      }  ${errors.referenceNum ? "is-invalid" : " "}  `}
                      name="referenceNum"
                      value={formData.referenceNum}
                      onChange={handleInputChange}
                      placeholder={t("enterHere")}
                      required
                    />
                    {errors.referenceNum && (
                      <div className="invalid-feedback">
                        {errors.referenceNum}
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-md-4 col-sm-6">
                  <div className="form-group">
                    <label className="form-label">
                      {t("registrationId")}
                    </label>
                    <input
                      type="text"
                      className='form-control'
                      name="registrationId"
                      value={formData.registrationId}
                      onChange={handleInputChange}
                      placeholder={t("enterHere")}
                      required
                    />
                  </div>
                </div>
                <div className="col-md-4 col-sm-6">
                  <div className="form-group">
                    <label className="form-label">
                      {t("street")}
                      <span
                        style={{
                          color: "red",
                          fontSize: "12px",
                          marginLeft: "1px",
                          marginRight: "4px",
                        }}
                      >
                        *
                      </span>
                    </label>
                    <input
                      type="text"
                      className={`form-control ${
                        valid.street ? "is-valid" : " "
                      }  ${errors.street ? "is-invalid" : " "}  `}
                      name="street"
                      value={formData.street}
                      onChange={handleInputChange}
                      placeholder={t("enterStreet")}
                      required
                    />
                    {errors.street && (
                      <div className="invalid-feedback">{errors.street}</div>
                    )}
                  </div>
                </div>
                <div className="col-md-4 col-sm-6">
                  <div className="form-group">
                    <label className="form-label">
                      {t("houseNumber")}
                      <span
                        style={{
                          color: "red",
                          fontSize: "12px",
                          marginLeft: "1px",
                          marginRight: "4px",
                        }}
                      >
                        *
                      </span>
                    </label>
                    <input
                      type="text"
                      className={`form-control ${
                        valid.houseNumber ? "is-valid" : " "
                      }  ${errors.houseNumber ? "is-invalid" : " "}  `}
                      name="houseNumber"
                      value={formData.houseNumber}
                      onChange={handleInputChange}
                      placeholder="Enter House number"
                      required
                    />
                    {errors.houseNumber && (
                      <div className="invalid-feedback">
                        {errors.houseNumber}
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-md-4 col-sm-6">
                  <div className="form-group">
                    <label className="form-label">
                      {t("location")}
                      <span
                        style={{
                          color: "red",
                          fontSize: "12px",
                          marginLeft: "1px",
                          marginRight: "4px",
                        }}
                      >
                        *
                      </span>
                    </label>
                    <input
                      type="text"
                      className={`form-control ${
                        valid.location ? "is-valid" : " "
                      }  ${errors.location ? "is-invalid" : " "}  `}
                      name="location"
                      value={formData.location}
                      onChange={handleInputChange}
                      placeholder={t("enterHere")}
                      required
                    />
                    {errors.location && (
                      <div className="invalid-feedback">{errors.location}</div>
                    )}
                  </div>
                </div>
                <div className="col-md-4 col-sm-6">
                  <div className="form-group">
                    <label className="form-label">
                      {t("postCode")}
                      <span
                        style={{
                          color: "red",
                          fontSize: "12px",
                          marginLeft: "1px",
                          marginRight: "4px",
                        }}
                      >
                        *
                      </span>
                    </label>
                    <input
                      type="text"
                      className={`form-control ${
                        valid.postcode ? "is-valid" : " "
                      }  ${errors.postcode ? "is-invalid" : " "}  `}
                      name="postcode"
                      value={formData.postcode}
                      onChange={handleInputChange}
                      placeholder={t("enterPostCode")}
                      required
                    />
                    {errors.postcode && (
                      <div className="invalid-feedback">{errors.postcode}</div>
                    )}
                  </div>
                </div>

                <div
                  className="col-12 mb-4 mt-2"
                  style={{ fontWeight: 650, color: "#b7b7b7" }}
                >
                  <span> {t("managerDetails")}</span>
                </div>
                {noManagerAdded && (
                  <div className="card-alert alert alert-danger mb-0">
                    {t("noManagerMsg")}
                  </div>
                )}
                <div className="col-12">
                  {rows.map((row, index) => (
                    <>
                      <hr style={{ margin: "0px 0px" }}></hr>
                      <div className="row mb-2 mt-2">
                        <div className="col-md-4 col-sm-6">
                          <div className="form-group">
                            <label className="form-label">
                              {t("managerFirstName")}
                              <span
                                style={{
                                  color: "red",
                                  fontSize: "12px",
                                  marginLeft: "1px",
                                  marginRight: "4px",
                                }}
                              >
                                *
                              </span>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              name="managerFirstName"
                              value={row.managerFirstName}
                              onChange={(e) =>
                                handleRowChange(
                                  index,
                                  "managerFirstName",
                                  e.target.value
                                )
                              }
                              placeholder="Enter Fisrt Name"
                              required
                            />
                            {!row.managerFirstName && (
                              <div style={invalidFeedbackStyle}>
                                {errors.managerFirstName}
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="col-md-4 col-sm-6">
                          <div className="form-group">
                            <label className="form-label">
                              {t("managerLastName")}
                              <span
                                style={{
                                  color: "red",
                                  fontSize: "12px",
                                  marginLeft: "1px",
                                  marginRight: "4px",
                                }}
                              >
                                *
                              </span>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              name="managerLastName"
                              value={row.managerLastName}
                              onChange={(e) =>
                                handleRowChange(
                                  index,
                                  "managerLastName",
                                  e.target.value
                                )
                              }
                              placeholder="Enter Last Name"
                              required
                            />
                            {!row.managerLastName && (
                              <div style={invalidFeedbackStyle}>
                                {errors.managerLastName}
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="col-md-4 col-sm-6">
                          <div className="form-group">
                            <label className="form-label">
                              {t("managerEmail")}
                              <span
                                style={{
                                  color: "red",
                                  fontSize: "12px",
                                  marginLeft: "1px",
                                  marginRight: "4px",
                                }}
                              >
                                *
                              </span>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              name="managerEmail"
                              value={row.managerEmail}
                              onChange={(e) =>
                                handleRowChange(
                                  index,
                                  "managerEmail",
                                  e.target.value
                                )
                              }
                              placeholder="Enter Manager Email"
                              required
                            />
                            {!row.managerEmail && (
                              <div style={invalidFeedbackStyle}>
                                {errors.managerEmail}
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="col-md-4 col-sm-6">
                          <div className="form-group">
                            <label className="form-label">
                              {t("countryCode")}
                            </label>
                            <Select
                              options={countryCodeOptions}
                              value={
                                row.countryCode
                                  ? countryCodeOptions.find(
                                      (option) =>
                                        option.value === row.countryCode
                                    )
                                  : null
                              }
                              onChange={(selectedOption) =>
                                handleRowChange(
                                  index,
                                  "countryCode",
                                  selectedOption
                                )
                              }
                              placeholder={t("select")}
                            />
                          </div>
                        </div>
                        <div className="col-md-4 col-sm-6">
                          <div className="form-group">
                            <label className="form-label">{t("phone")}</label>
                            <input
                              type="text"
                              className="form-control"
                              name="phone"
                              value={row.phone}
                              onChange={(e) =>
                                handleRowChange(index, "phone", e.target.value)
                              }
                              placeholder={t("enterValidPhoneNumber")}
                            />
                          </div>
                        </div>
                        <div className="col-lg-2 col-md-12 mt-4">
                          <IconButton onClick={() => handleRemoveRow(index)}>
                            <Trash2 className="h-6 w-6 text-red-400" />
                          </IconButton>
                        </div>
                      </div>
                      <hr style={{ margin: "0px 0px" }}></hr>
                    </>
                  ))}
                  <div className="flex gap-5 mt-4  mb-4 justify-start">
                    <button className="btn btn-info" onClick={handleAddRow}>
                      {t("addRow")}
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                onClick={handleClose}
                data-dismiss="modal"
              >
                {t("close")}
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={handleSubmit}
              >
                {t("save")}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="deleteConfirmationModal"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="deleteConfirmationModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="deleteConfirmationModalLabel">
                {t("deleteConfirmation")}
              </h5>
              <button
                type="button"
                id="closeConfirmModal"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">{t("confirmDeleteMessage")}</div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
              >
                {t("cancel")}
              </button>
              <button
                type="button"
                className="btn btn-danger"
                onClick={handleDeleteConfirm}
              >
                {t("delete")}
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* 
      <div
        className="modal fade"
        id="editStoreModal"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="editStoreModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="editStoreModalLabel">
                {t("editStore")}
              </h5>
              <button
                type="button"
                id="closeEditModal"
                onClick={handleEditClose}
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="row clearfix">
                <div className="col-md-4 col-sm-6">
                  <div className="form-group">
                    <label className="form-label">{t("storeName")}</label>
                    <input
                      type="text"
                      name="name"
                      className="form-control"
                      value={editCompanyData.name}
                      onChange={(e) => handleEditInputChange(e, "name")}
                      placeholder={t("storeName")}
                      required
                    />
                    {errors.name && (
                      <div
                        style={{
                          color: "#dc3545",
                          fontSize: "12.8px",
                          marginTop: "2px",
                        }}
                      >
                        {errors.name}
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-md-4 col-sm-6">
                  <div className="form-group">
                    <label className="form-label">{t("openFrom")}</label>
                    <input
                      type="text"
                      className="form-control"
                      name="openFrom"
                      value={editCompanyData.openFrom}
                      onChange={(e) => handleEditInputChange(e, "openFrom")}
                      placeholder="HH:MM"
                      required
                    />
                    {errors.openFrom && (
                      <div
                        style={{
                          color: "#dc3545",
                          fontSize: "12.8px",
                          marginTop: "2px",
                        }}
                      >
                        {errors.openFrom}
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-md-4 col-sm-6">
                  <div className="form-group">
                    <label className="form-label">{t("openTo")}</label>
                    <input
                      type="text"
                      className="form-control"
                      name="openTo"
                      value={editCompanyData.openTo}
                      onChange={(e) => handleEditInputChange(e, "openTo")}
                      placeholder="HH:MM"
                      required
                    />
                    {errors.openTo && (
                      <div
                        style={{
                          color: "#dc3545",
                          fontSize: "12.8px",
                          marginTop: "2px",
                        }}
                      >
                        {errors.openTo}
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-md-4 col-sm-6">
                  <div className="form-group">
                    <label className="form-label">{t("countryCode")}</label>
                    <Select
                      options={countryCodeOptions}
                      value={
                        editCompanyData.countryCode
                          ? countryCodeOptions.find(
                              (option) =>
                                option.value === editCompanyData.countryCode
                            )
                          : null
                      }
                      onChange={(e) =>
                        handleEditDropdownChange(e, "countryCode")
                      }
                      placeholder={t("select")}
                    />
                  </div>
                </div>
                <div className="col-md-4 col-sm-6">
                  <div className="form-group">
                    <label className="form-label">{t("phone")}</label>
                    <input
                      type="text"
                      className="form-control"
                      name="phone"
                      value={editCompanyData.phone}
                      onChange={(e) => handleEditInputChange(e, "phone")}
                      placeholder={t("enterValidPhoneNumber")}
                      required
                    />
                    {errors.phone && (
                      <div
                        style={{
                          color: "#dc3545",
                          fontSize: "12.8px",
                          marginTop: "2px",
                        }}
                      >
                        {errors.phone}
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-md-4 col-sm-6">
                  <div className="form-group">
                    <label className="form-label">{t("street1")}</label>
                    <input
                      type="text"
                      className="form-control"
                      name="street1"
                      value={editCompanyData.street1}
                      onChange={(e) => handleEditInputChange(e, "street1")}
                      placeholder={t("enterStreet")}
                      required
                    />
                    {errors.street1 && (
                      <div
                        style={{
                          color: "#dc3545",
                          fontSize: "12.8px",
                          marginTop: "2px",
                        }}
                      >
                        {errors.street1}
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-md-4 col-sm-6">
                  <div className="form-group">
                    <label className="form-label">{t("street2")}</label>
                    <input
                      type="text"
                      className="form-control"
                      name="street2"
                      value={editCompanyData.street2}
                      onChange={(e) => handleEditInputChange(e, "street2")}
                      placeholder={t("enterStreet")}
                    />
                  </div>
                </div>
                <div className="col-md-4 col-sm-6">
                  <div className="form-group">
                    <label className="form-label">{t("city")}</label>
                    <input
                      type="text"
                      className="form-control"
                      name="city"
                      value={editCompanyData.city}
                      onChange={(e) => handleEditInputChange(e, "city")}
                      placeholder={t("enterCity")}
                      required
                    />
                    {errors.city && (
                      <div
                        style={{
                          color: "#dc3545",
                          fontSize: "12.8px",
                          marginTop: "2px",
                        }}
                      >
                        {errors.city}
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-md-4 col-sm-6">
                  <div className="form-group">
                    <label className="form-label">{t("country")}</label>
                    <Select
                      options={countryOptions}
                      value={
                        editCompanyData.country
                          ? countryOptions.find(
                              (option) => option.value === editCompanyData.country
                            )
                          : null
                      }
                      onChange={(e) => handleEditDropdownChange(e, "country")}
                      placeholder={t("select")}
                    />
                  </div>
                </div>
                <div className="col-md-4 col-sm-6">
                  <div className="form-group">
                    <label className="form-label">{t("postCode")}</label>
                    <input
                      type="text"
                      className="form-control"
                      name="postCode"
                      value={editCompanyData.postCode}
                      onChange={(e) => handleEditInputChange(e, "postCode")}
                      placeholder={t("enterPostCode")}
                      required
                    />
                    {errors.postCode && (
                      <div
                        style={{
                          color: "#dc3545",
                          fontSize: "12.8px",
                          marginTop: "2px",
                        }}
                      >
                        {errors.postCode}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                onClick={handleEditClose}
                data-dismiss="modal"
              >
                {t("close")}
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={handleEditStore}
              >
                {t("save")}
              </button>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="assignStoreManagerModal"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="assignStoreManagerModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="assignStoreManagerModalLabel">
                {t("assignStoreManager")}
              </h5>
              <button
                onClick={closeAssignManager}
                type="button"
                id="closeAssignStoreManagerModal"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              {(selectMessage || selectMessage.trim() !== "") && (
                <div
                  style={{
                    color: "#dc3545",
                    fontSize: "12.8px",
                    marginTop: "2px",
                    marginBottom: "10px",
                  }}
                >
                  {t("oneStoreSelected")}
                </div>
              )}

              <div className="form-group">
                <label className="form-label">
                  {t("selectManagerForStore")}
                </label>
                <Select
                  options={prospectStorManagerOptions}
                  value={selectedEmpIds.map((id) =>
                    prospectStorManagerOptions.find(
                      (option) => option.value === id
                    )
                  )}
                  onChange={(e) => setSelectedEmpIds([e.value])}
                  placeholder="Select"
                />
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                onClick={closeAssignManager}
                className="btn btn-secondary"
                data-dismiss="modal"
              >
                {t("close")}
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={handleAssignStoreManager}
              >
                {t("save")}
              </button>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="assignEmpModal"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="assignEmpModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="assignEmpModalLabel">
                {t("assignEmp")}
              </h5>
              <button
                onClick={closeAssignEmployees}
                type="button"
                id="closeAssignEmpModal"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              {(selectMessage || selectMessage.trim() !== "") && (
                <div
                  style={{
                    color: "#dc3545",
                    fontSize: "12.8px",
                    marginTop: "2px",
                    marginBottom: "10px",
                  }}
                >
                  {t("oneStoreSelected")}
                </div>
              )}

              <div className="form-group">
                <label className="form-label">{t("selectEmployeesMsg")}</label>
                <Select
                  options={noStoreEmployeesOptions}
                  value={selectedEmpIds.map((id) =>
                    noStoreEmployeesOptions.find(
                      (option) => option.value === id
                    )
                  )}
                  isMulti // Enable multiple selection
                  onChange={(selectedOptions) => {
                    const selectedIds = selectedOptions.map(
                      (option) => option.value
                    );
                    setSelectedEmpIds(selectedIds);
                  }}
                  placeholder={t("select")}
                />
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                onClick={closeAssignEmployees}
                className="btn btn-secondary"
                data-dismiss="modal"
              >
                {t("close")}
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={handleAssignEmployees}
              >
                {t("save")}
              </button>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="removeEmpModal"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="removeEmpModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="removeEmpModalLabel">
                {t("removeEmp")}
              </h5>
              <button
                onClick={closeRemoveEmployees}
                type="button"
                id="closeRemoveEmpModal"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              {(selectMessage || selectMessage.trim() !== "") && (
                <div
                  style={{
                    color: "#dc3545",
                    fontSize: "12.8px",
                    marginTop: "2px",
                    marginBottom: "10px",
                  }}
                >
                  {t("oneStoreSelected")}
                </div>
              )}

              <div className="form-group">
                <label className="form-label">
                  {t("selectEmployeesFromStore")}
                </label>
                <Select
                  options={storeEmployeesOptions}
                  value={selectedEmpIds.map((id) =>
                    storeEmployeesOptions.find((option) => option.value === id)
                  )}
                  isMulti // Enable multiple selection
                  onChange={(selectedOptions) => {
                    const selectedIds = selectedOptions.map(
                      (option) => option.value
                    );
                    setSelectedEmpIds(selectedIds);
                  }}
                  placeholder={t("select")}
                />
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                onClick={closeRemoveEmployees}
                className="btn btn-secondary"
                data-dismiss="modal"
              >
                {t("close")}
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={handleRemoveEmployees}
              >
                {t("save")}
              </button>
            </div>
          </div>
        </div>
      </div> */}

      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <nav>
                          <ul className="pagination">
                            {currentPage > 1 && (
                              <li
                                className="page-item"
                                style={{ padding: "5px 2px" }}
                              >
                                <button
                                  onClick={() => handleClick(currentPage - 1)}
                                  className="page-link"
                                  style={{
                                    fontSize: "14px",
                                    padding: "2px 10px",
                                    fontWeight: 600,
                                  }}
                                >
                                  <ChevronLeft size={17}/>
                                </button>
                              </li>
                            )}

                            {getDisplayPages().map((page) => (
                              <li
                                key={page}
                                className={`page-item ${
                                  currentPage === page ? "active" : ""
                                }`}
                                style={{ padding: "5px 2px" }}
                              >
                                <button
                                  onClick={() => handleClick(page)}
                                  className="page-link"
                                  style={{
                                    fontSize: "14px",
                                    padding: "2px 10px",
                                    fontWeight: 600,
                                    backgroundColor:
                                      currentPage === page ? "gray" : " ",
                                  }}
                                >
                                  {page}
                                </button>
                              </li>
                            ))}

                            {currentPage < totalPages && (
                              <li
                                className="page-item"
                                style={{ padding: "5px 2px" }}
                              >
                                <button
                                  onClick={() => handleClick(currentPage + 1)}
                                  className="page-link"
                                  style={{
                                    fontSize: "14px",
                                    padding: "2px 10px",
                                    fontWeight: 600,
                                  }}
                                >
                                  <ChevronRight size={17}/>
                                </button>
                              </li>
                            )}
                          </ul>
                        </nav>
                      </div>
      <Dialog
        open={isLoading}
        style={{ backdropFilter: "blur(2px)" }}
        fullWidth
      >
        <div
          className="flex flex-col justify-center"
          style={{ backgroundColor: "transparent" }}
        >
          <div className="mx-auto" style={{ backgroundColor: "transparent" }}>
            <Loading />
          </div>
        </div>
      </Dialog>
    </div>
  );
}

const mapStateToProps = (state) => ({
  fixNavbar: state.settings.isFixNavbar,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Companies);

import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Select from "react-select";
import {
  activateEmployeeById,
  addEmployeeApi,
  disableEmployeeById,
  generateCredsForEmployee,
  getCompanyEmployeeList,
  getEmployeeList,
} from "../../../services/employeeService";
import moment from "moment";
import { toast } from "react-toastify";
import { NavLink } from "react-router-dom/cjs/react-router-dom";
import Loading from "../../Utility/Loading";
import { useTranslation } from "react-i18next";
import { Dialog } from "@mui/material";
import {
  getCompanyDropDownList,
  getManagerDropDown,
} from "../../../services/companyService";
import { getInsuranceProviderOptions } from "../../../services/insuranceProviderService";
import { ChevronLeft, ChevronRight } from "lucide-react";

function Employee() {
  const fixNavbar = useSelector((state) => state.settings.isFixNavbar);
  const { t, i18n } = useTranslation("employee");
  const currentLocale = i18n.language;
  const itemsPerPage = 10;
  const [currentPage, setCurrentPage] = useState(1);
  const [employeeData, setEmployeeData] = useState([]);
  const [selectedEmployees, setSelectedEmployees] = useState([]);

  const [title, setTitle] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [gender, setGender] = useState("");
  const [email, setEmail] = useState("");
  const [position, setPosition] = useState("");
  const [employeeNumber, setEmployeeNumber] = useState("");
  const [employmentDate, setEmploymentDate] = useState("");
  const [birthDate, setBirthDate] = useState("");
  const [mobile, setMobile] = useState("");
  const [phone, setPhone] = useState("");
  const [generateCreds, setGenerateCreds] = useState(false);
  const [employmentType, setEmploymentType] = useState("FULL_TIME");
  const [isLoading, setIsLoading] = useState(false);
  const [searchName, setSearchName] = useState("");
  const [selectedStore, setSelectedStore] = useState(null);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [companyOptions, setCompanyOptions] = useState([]);
  const [managerDropDown, setManagerDropDown] = useState([]);
  const [managerOptions, setManagerOptions] = useState([]);
  const [companyId, setCompanyId] = useState("");
  const [providerId, setProviderId] = useState("");
  const [insuranceOptions, setInsuranceOptions] = useState([]);
  const [cityOptions, setCityOptions] = useState([]);
  const [managerId, setManagerId] = useState("");
  const [dateFilter, setDateFilter] = useState("ALL");
  const [companyFilter, setCompanyFilter] = useState();

  const initialErrorState = {
    title: "",
    firstName: "",
    lastName: "",
    gender: "",
    email: "",
    position: "",
    employeeNumber: "",
    employmentDate: "",
    //employmentType: "",
    mobile: "",
    phone: "",
    providerId: "",
    birthDate: "",
  };
  const initialValidState = {
    title: false,
    firstName: false,
    lastName: false,
    gender: false,
    email: false,
    position: false,
    employeeNumber: false,
    employmentDate: false,
    //employmentType: false,
    mobile: false,
    phone: false,
    providerId: false,
    birthDate: false,
  };
  const [errors, setErrors] = useState(initialErrorState);
  const [valid, setValid] = useState(initialValidState);
  const currentDate = new Date();
  const currentDateString = currentDate.toISOString().split("T")[0]; // Get YYYY-MM-DD

  function calculateDate(date, days) {
    const resultDate = new Date(date);
    resultDate.setDate(resultDate.getDate() + days);
    return resultDate.toISOString().split("T")[0];
  }

  const genderOptions = [
    { value: "MALE", label: t("male") },
    { value: "FEMALE", label: t("female") },
    { value: "OTHER", label: t("other") },
  ];

  // const employmentTypeOptions = [
  //   { value: "FULL_TIME", label: t("fullTime") },
  //   { value: "PART_TIME", label: t("partTime") },
  //   { value: "MINI_JOB", label: t("miniJob") },
  // ];

  const providerMap = {
    VIACTIVKRANKENKASSE: "Viactiv Krankenkasse",
    MOBILKRANKENKASSE: "Mobil Krankenkasse",
  };

  useEffect(() => {
    fetchEmployeeData();
    fetchManagerDropDown();
    getCompanyDropDown();
    fetchInsuranceProviderOptions();
  }, []);

  const fetchEmployeeData = async () => {
    try {
      setIsLoading(true);
      let response = null;
      if (localStorage.role === "ROLE_ADMIN") {
        response = await getCompanyEmployeeList(localStorage.token);
      } else {
        response = await getEmployeeList(localStorage.token);
      }
      setEmployeeData(response);
      console.log("employee response", response);
      const uniqueCitySet = new Set();
      const cityOptions = response
        .filter((emp) => emp.location)
        .filter((emp) => {
          const city = emp.location;
          if (!uniqueCitySet.has(city)) {
            uniqueCitySet.add(city);
            return true;
          }
          return false;
        })
        .map((emp) => ({
          label: emp.location,
          value: emp.location,
        }));
      setCityOptions(cityOptions);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  const fetchInsuranceProviderOptions = async () => {
    try {
      const response = await getInsuranceProviderOptions(localStorage.token);
      setInsuranceOptions(response);
    } catch (error) {
      console.log(error);
    }
  };

  const getCompanyDropDown = async () => {
    try {
      const response = await getCompanyDropDownList(localStorage.token);
      setCompanyOptions(response);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchManagerDropDown = async () => {
    try {
      const response = await getManagerDropDown(localStorage.token);
      setManagerDropDown(response);
    } catch (error) {
      console.log(error);
    }
  };

  const handleCompanySelect = (id) => {
    setCompanyId(id);
    if (managerDropDown[id]) {
      setManagerOptions(managerDropDown[id]);
    } else {
      setManagerOptions([]);
    }
  };

  const validateForm = () => {
    const newErrors = {};
    const valid = {};

    if (!firstName || firstName.trim() === "") {
      newErrors.firstName = t("firstNameIsRequired");
    } else {
      valid.firstName = true;
    }
    if (!lastName || lastName.trim() === "") {
      newErrors.lastName = t("lastNameIsRequired");
    } else {
      valid.lastName = true;
    }
    if (!employeeNumber || employeeNumber.trim() === "") {
      newErrors.employeeNumber = t("employeeNumberIsRequired");
    } else {
      valid.employeeNumber = true;
    }
    if (!gender || gender.trim() === "") {
      newErrors.gender = t("genderIsRequired");
    } else {
      valid.gender = true;
    }
    // if (!employmentType || employmentType.trim() === "") {
    //   newErrors.employmentType = t("employmentTypeIsRequired");
    // } else {
    //   valid.employmentType = true;
    // }
    // if (!position || position.trim() === "") {
    //   newErrors.position = t("positionIsRequired");
    // } else {
    //   valid.position = true;
    // }
    if (!employmentDate) {
      newErrors.employmentDate = t("employmentDateIsRequired");
    } else {
      valid.employmentDate = true;
    }
    if (!birthDate) {
      newErrors.birthDate = t("birthDateIsRequired");
    } else {
      valid.birthDate = true;
    }
    // if (!title || title.trim() === "") {
    //   newErrors.title = t("titleIsRequired");
    // } else {
    //   valid.title = true;
    // }
    if (!providerId) {
      newErrors.providerId = t("insuranceProviderIsRequired");
    } else {
      valid.providerId = true;
    }
    if (localStorage.role === "ROLE_SUPER_ADMIN") {
      if (!companyId) {
        newErrors.companyId = t("required");
      }
      if (!managerId) {
        newErrors.managerId = t("required");
      }
    }

    setErrors(newErrors);
    setValid(valid);
    return Object.keys(newErrors).length === 0;
  };

  const handleSave = () => {
    let idForCompany = companyId;
    if (localStorage.role === "ROLE_ADMIN") {
      idForCompany = parseInt(localStorage.companyId, 10);
    }
    if (validateForm()) {
      setIsLoading(true);
      const formData = {
        title,
        firstName,
        lastName,
        birthDate,
        gender,
        email,
        position,
        employeeNumber,
        employmentDate,
        //employmentType,
        mobile,
        phone,
        generateCreds,
        companyId: idForCompany,
        providerId,
        managerId,
      };
      console.log("employee", formData);
      saveEmployee(formData);
    }
  };

  const saveEmployee = async (employeeData) => {
    const response = await addEmployeeApi(localStorage.token, employeeData);
    if (!response.error) {
      toast.success(t("employeeCreatedSuccessfully"));
      clearForm();
      document.getElementById("closeAddEmpModalLabel").click();
      setIsLoading(false);
    } else {
      let message = getErrorMessageFromResponse(response);
      toast.error(message);
      setIsLoading(false);
    }
  };

  const getErrorMessageFromResponse = (response) => {
    switch (response.errorMessage) {
      case "MANAGER_NOT_SELECTED":
        return t("managerNotSelected");
      case "MANAGER_NOT_FOUND":
        return t("managerNotFound");
      case "EMPLOYEE_EXISTS_WITH_SAME_EMPLOYEE_ID":
        return t("employeeExists");
      default:
        return t("somethingWentWrong");
    }
  };
  const handleCloseEmployee = () => {
    clearForm();
  };

  const clearForm = () => {
    setTitle("");
    setFirstName("");
    setLastName("");
    setGender("");
    setEmail("");
    setPosition("");
    setEmployeeNumber("");
    setEmploymentDate("");
    setBirthDate("");
    //setEmploymentType("");
    setMobile("");
    setPhone("");
    setProviderId("");
    setCompanyId("");
    setManagerId("");
    setGenerateCreds(false);
    setErrors(initialErrorState);
    setValid(initialValidState);
    window.location.reload(false);
  };

  const employmentTypes = {
    FULL_TIME: t("fullTime"),
    PART_TIME: t("partTime"),
    MINI_JOB: t("miniJob"),
  };

  const genderMap = {
    MALE: t("male"),
    FEMALE: t("female"),
    OTHER: t("other"),
  };

  const formatDate = (dateString, locale) => {
    const dateObject = moment(dateString);
    dateObject.locale(locale);
    return dateObject.format("D MMM, YYYY");
  };

  const filterByDate = (emp, dateFilter) => {
    if (dateFilter === "ALL") return true;
    if (dateFilter == "TODAY") {
      let fromDate = calculateDate(currentDate, 0);
      let toDate = currentDateString;
      return emp.joiningDate >= fromDate && emp.joiningDate <= toDate;
    } else if (dateFilter == "LAST_3_DAYS") {
      let fromDate = calculateDate(currentDate, -3);
      let toDate = currentDateString;
      return emp.joiningDate >= fromDate && emp.joiningDate <= toDate;
    } else if (dateFilter == "LAST_7_DAYS") {
      let fromDate = calculateDate(currentDate, -7);
      let toDate = currentDateString;
      return emp.joiningDate >= fromDate && emp.joiningDate <= toDate;
    } else if (dateFilter == "LAST_15_DAYS") {
      let fromDate = calculateDate(currentDate, -15);
      let toDate = currentDateString;
      return emp.joiningDate >= fromDate && emp.joiningDate <= toDate;
    } else if (dateFilter == "LAST_30_DAYS") {
      let fromDate = calculateDate(currentDate, -30);
      let toDate = currentDateString;
      return emp.joiningDate >= fromDate && emp.joiningDate <= toDate;
    } else if (dateFilter == "LAST_60_DAYS") {
      let fromDate = calculateDate(currentDate, -60);
      let toDate = currentDateString;
      return emp.joiningDate >= fromDate && emp.joiningDate <= toDate;
    }
  };

  const filteredData = employeeData
    .filter(
      (emp) =>
        !searchName || emp.name.toLowerCase().includes(searchName.toLowerCase())
    )
    //   .filter((emp) => !searchEmail || dept.email.toLowerCase().includes(searchEmail.toLowerCase()))
    .filter((emp) => !companyFilter || companyFilter.value === emp.companyId)
    .filter(
      (emp) => !selectedLocation || selectedLocation.value === emp.location
    )
    .filter((emp) => !dateFilter || filterByDate(emp, dateFilter));

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);

  const totalPages = Math.ceil(filteredData.length / itemsPerPage);

  const getDisplayPages = () => {
    const pages = [];
    const pageWindow = 3; // Number of pages to display

    if (totalPages <= pageWindow) {
      for (let i = 1; i <= totalPages; i++) {
        pages.push(i);
      }
    } else {
      if (currentPage <= 1) {
        for (let i = 1; i <= pageWindow; i++) {
          pages.push(i);
        }
      } else if (currentPage >= totalPages) {
        for (let i = totalPages - pageWindow + 1; i <= totalPages; i++) {
          pages.push(i);
        }
      } else {
        const start = currentPage - 1;
        const end = currentPage + 1;

        for (let i = start; i <= end; i++) {
          pages.push(i);
        }
      }
    }

    return pages;
  };

  const handleClick = (newPage) => {
    if (newPage >= 1 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };

  const handleActivateClick = async (employeeId) => {
    try {
      console.log("clicked activate");
      setIsLoading(true);
      const response = await activateEmployeeById(
        localStorage.token,
        employeeId
      );
      if (response) {
        toast.success(t("activateSuccess"));
        setIsLoading(false);
        fetchEmployeeData();
      } else {
        toast.error(t("somethingWentWrong"));
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  const handleDisableClick = async (employeeId) => {
    try {
      setIsLoading(true);
      const response = await disableEmployeeById(
        localStorage.token,
        employeeId
      );
      if (response) {
        toast.success(t("disableSuccess"));
        setIsLoading(false);
        fetchEmployeeData();
      } else {
        toast.error(t("somethingWentWrong"));
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  const resetFilters = () => {
    setCompanyFilter(null);
    setDateFilter("ALL");
    setSelectedLocation(null);
    setSearchName("");
  };

  const invalidFeedbackStyle = {
    display: "block",
    width: "100%",
    marginTop: "0.25rem",
    fontSize: "90%",
    color: "#dc3545",
  };

  return (
    <>
      <div>
        <div>
          <div className={`section-body ${fixNavbar ? "marginTop" : ""} `}>
            <div className="container-fluid">
              <div className="d-flex justify-end align-items-center mb-3 mt-2">
                <div className="header-action">
                  <button
                    type="button"
                    className="btn btn-primary"
                    data-toggle="modal"
                    data-target="#addModal"
                  >
                    <i className="fe fe-plus mr-2" />
                    {t("addNewEmployee")}
                  </button>
                </div>
              </div>
              {/* <div className="row">
                <div className="col-lg-2 col-md-4">
                  <div className="card">
                    <div className="card-body w_sparkline">
                      <div className="details">
                        <span>{t("totalEmployees")}</span>
                        <h3 className="mb-0">
                          <span className="counter">
                            {" "}
                            {employeeCountData.totalEmployees && (
                              <CountUp end={employeeCountData.totalEmployees} />
                            )}
                          </span>
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-2 col-md-4">
                  <div className="card">
                    <div className="card-body w_sparkline">
                      <div className="details">
                        <span>{t("inactiveEmps")}</span>
                        <h3 className="mb-0">
                          {employeeCountData.inactiveEmployees && (
                            <CountUp
                              end={employeeCountData.inactiveEmployees}
                            />
                          )}
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-2 col-md-4">
                  <div className="card">
                    <div className="card-body w_sparkline">
                      <div className="details">
                        <span>{t("activeEmps")}</span>
                        <h3 className="mb-0">
                          {employeeCountData.activeEmployees && (
                            <CountUp end={employeeCountData.activeEmployees} />
                          )}
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-2 col-md-4">
                  <div className="card">
                    <div className="card-body w_sparkline">
                      <div className="details">
                        <span>{t("maleEmps")}</span>
                        <h3 className="mb-0 counter">
                          {" "}
                          {employeeCountData.maleEmployees && (
                            <CountUp end={employeeCountData.maleEmployees} />
                          )}
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-2 col-md-4">
                  <div className="card">
                    <div className="card-body w_sparkline">
                      <div className="details">
                        <span>{t("femaleEmps")}</span>
                        <h3 className="mb-0 counter">
                          {" "}
                          {employeeCountData.feMaleEmployees && (
                            <CountUp end={employeeCountData.feMaleEmployees} />
                          )}
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-2 col-md-4">
                  <div className="card">
                    <div className="card-body w_sparkline">
                      <div className="details">
                        <span>{t("otherEmps")}</span>
                        <h3 className="mb-0 counter">
                          {" "}
                          {employeeCountData.otherEmployees && (
                            <CountUp end={employeeCountData.otherEmployees} />
                          )}
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
          </div>

          <div className="section-body">
            <div className="container-fluid">
              <div className="card">
                <div className="card-body">
                  <div className="row">
                    {/* <div className="col-md-3 col-sm-6">
                      <label>{t('insuranceProvider')}</label>
                      <div className="multiselect_div">
                            <Select
                              className="react-select-container"
                              classNamePrefix="react-select"
                              options={insuranceOptions}
                              value={selectedStore}
                              onChange={(selectedOption) =>
                                setSelectedStore(selectedOption)
                              }
                              placeholder={t("select")}
                            />
                          </div>
                    </div> */}
                    {localStorage.role === "ROLE_SUPER_ADMIN" && (
                      <div className="col-md-2 col-sm-6">
                        <label>{t("company")}</label>
                        <div className="multiselect_div">
                          <Select
                            className="react-select-container"
                            classNamePrefix="react-select"
                            options={companyOptions}
                            value={companyFilter}
                            onChange={(selectedOption) =>
                              setCompanyFilter(selectedOption)
                            }
                            placeholder={t("select")}
                          />
                        </div>
                      </div>
                    )}
                    <div className="col-md-2 col-sm-6">
                      <label>{t("location")}</label>
                      <div className="form-group">
                        <Select
                          className="react-select-container"
                          classNamePrefix="react-select"
                          options={cityOptions}
                          value={selectedLocation}
                          onChange={(selectedOption) =>
                            setSelectedLocation(selectedOption)
                          }
                          placeholder={t("select")}
                        />
                      </div>
                    </div>
                    <div className="col-md-2 col-sm-6">
                      <label style={{ whiteSpace: "nowrap" }}>
                        {t("employeeCreated")}
                      </label>
                      <div className="multiselect_div">
                        <select
                          className="custom-select"
                          value={dateFilter}
                          onChange={(e) => setDateFilter(e.target.value)}
                        >
                          <option value="ALL">{t("all")}</option>
                          <option value="TODAY">{t("today")}</option>
                          <option value="LAST_3_DAYS">{t("last3d")}</option>
                          <option value="LAST_7_DAYS">{t("last7d")}</option>
                          <option value="LAST_15_DAYS">{t("last15d")}</option>
                          <option value="LAST_30_DAYS">{t("last30d")}</option>
                          <option value="LAST_60_DAYS">{t("last60d")}</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-md-2 col-sm-6">
                      <label>{t("searchByName")}</label>
                      <div className="input-group">
                        <input
                          type="text"
                          value={searchName}
                          onChange={(e) => setSearchName(e.target.value)}
                          className="form-control"
                          placeholder={t("enterName")}
                        />
                      </div>
                    </div>
                    <div className="col-md-2 col-sm-6">
                      <label>&nbsp;</label>
                      <button
                        onClick={() => {
                          resetFilters();
                        }}
                        className="btn btn-sm btn-primary btn-block p-2"
                      >
                        {t("reset")}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="tab-content">
                <div
                  className="tab-pane fade show active"
                  id="Employee-list"
                  role="tabpanel"
                >
                  <div className="card">
                    <div className="card-header">
                      <h3 className="card-title" style={{ fontWeight: 500 }}>
                        {t("employeeList")}
                      </h3>
                      <div
                        className="card-options"
                        style={{ color: "#6C6D6D" }}
                      >
                        Gesamt :{" "}
                        <strong>
                          {" "}
                          {employeeData.length} ({totalPages} seiten)
                        </strong>
                      </div>
                    </div>
                    <div className="card-body">
                      <div className="table-responsive">
                        <table className="table table-vcenter table-hover text-nowrap mb-0">
                          <thead className="thead-light">
                            <tr>
                              <th style={{ fontWeight: 600 }} className="w40">
                                #
                              </th>
                              <th style={{ fontWeight: 600 }}>Name</th>
                              {localStorage.role === "ROLE_SUPER_ADMIN" && (
                                <th style={{ fontWeight: 600 }}>
                                  {" "}
                                  {t("company")}
                                </th>
                              )}
                              <th style={{ fontWeight: 600 }}>
                                {t("employeeNumber")}
                              </th>
                              <th style={{ fontWeight: 600 }}>Status</th>
                              <th style={{ fontWeight: 600 }}>
                                {t("employmentType")}
                              </th>
                              <th style={{ fontWeight: 600 }}>
                                {" "}
                                {t("gender")}
                              </th>
                              <th style={{ fontWeight: 600 }}>Position</th>
                              <th style={{ fontWeight: 600 }}>
                                {t("insuranceProvider")}
                              </th>
                              <th style={{ fontWeight: 600 }}>
                                {t("location")}
                              </th>
                              <th style={{ fontWeight: 600 }}>
                                {t("employmentDate")}
                              </th>
                              <th style={{ fontWeight: 600 }}>
                                {t("joiningDate")}
                              </th>
                              <th style={{ fontWeight: 600 }}>
                                {t("actions")}
                              </th>
                            </tr>
                          </thead>
                          <tbody className="table-hover">
                            {currentItems.map((employee, index) => (
                              <tr key={index}>
                                {/* <td className="w40">{employee.index}</td> */}
                                <td className="w40">{index}</td>
                                <td className="d-flex">
                                  <span
                                    className="avatar avatar-blue"
                                    data-toggle="tooltip"
                                    data-original-title={employee.name}
                                  >
                                    {employee.name.substring(0, 2)}
                                  </span>
                                  <div className="ml-3">
                                    <h6 className="mb-0">
                                      <NavLink
                                        to={
                                          "/employee/details?id=" +
                                          employee.employeeId
                                        }
                                      >
                                        {employee.name}
                                      </NavLink>
                                    </h6>
                                    <span className="text-muted">
                                      {employee.email}
                                    </span>
                                  </div>
                                </td>
                                {localStorage.role === "ROLE_SUPER_ADMIN" && (
                                  <td>
                                    <div>
                                      <h6 className="mb-0">
                                        <NavLink
                                          to={
                                            "/company/info?id=" +
                                            employee.companyId
                                          }
                                        >
                                          {employee.companyName}
                                        </NavLink>
                                      </h6>

                                      <span className="text-muted">
                                        {employee.managerName}
                                      </span>
                                    </div>
                                  </td>
                                )}
                                <td
                                  style={{
                                    fontWeight: "500",
                                  }}
                                >
                                  {employee.employeeNumber}
                                </td>
                                <td>
                                  {employee.status === "ACTIVE" && (
                                    <span
                                      className="tag tag-success"
                                      style={{ fontWeight: 600 }}
                                    >
                                      {t("active")}
                                    </span>
                                  )}
                                  {employee.status === "INACTIVE" && (
                                    <span
                                      className="tag tag-danger"
                                      style={{ fontWeight: 600 }}
                                    >
                                      {t("inactive")}
                                    </span>
                                  )}
                                </td>
                                <td>
                                  <span>
                                    {employee.employmentType
                                      ? employmentTypes[employee.employmentType]
                                      : t("notSet")}
                                  </span>
                                </td>
                                <td
                                  style={{
                                    fontWeight: "500",
                                  }}
                                >
                                  {genderMap[employee.gender]}
                                </td>
                                <td
                                  style={{
                                    fontWeight: "500",
                                  }}
                                >
                                  {employee.position}
                                </td>
                                <td
                                  style={{
                                    fontWeight: "500",
                                  }}
                                >
                                  {providerMap[employee.providerName]}
                                </td>
                                <td>{employee.location}</td>
                                <td>{formatDate(employee.employmentDate)}</td>
                                <td>{formatDate(employee.joiningDate)}</td>
                                <td>
                                  {employee.status === "ACTIVE" && (
                                    <>
                                      <button
                                        className="btn btn-icon btn-sm"
                                        title={t("disable")}
                                        onClick={() =>
                                          handleDisableClick(
                                            employee.employeeId
                                          )
                                        }
                                      >
                                        <i
                                          className="fa fa-ban"
                                          style={{ color: "red" }}
                                        />
                                      </button>
                                    </>
                                  )}
                                  {employee.status === "INACTIVE" && (
                                    <>
                                      <button
                                        className="btn btn-icon btn-sm"
                                        title={t("activate")}
                                        onClick={() =>
                                          handleActivateClick(
                                            employee.employeeId
                                          )
                                        }
                                      >
                                        <i
                                          className="fa fa-check-square-o "
                                          style={{ color: "green" }}
                                        />
                                      </button>
                                    </>
                                  )}
                                  <NavLink
                                    className="btn btn-icon btn-sm"
                                    title="View"
                                    to={
                                      "/employee/details?id=" +
                                      employee.employeeId
                                    }
                                    target="_blank"
                                  >
                                    <i className="fa fa-eye" />
                                  </NavLink>
                                  <NavLink
                                    className="btn btn-icon btn-sm"
                                    title="Edit"
                                    to={
                                      "/employee/details?id=" +
                                      employee.employeeId
                                    }
                                    target="_blank"
                                  >
                                    <i
                                      className="fa fa-edit"
                                      style={{ color: "blue" }}
                                    />
                                  </NavLink>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <nav>
                          <ul className="pagination">
                            {currentPage > 1 && (
                              <li
                                className="page-item"
                                style={{ padding: "5px 2px" }}
                              >
                                <button
                                  onClick={() => handleClick(currentPage - 1)}
                                  className="page-link"
                                  style={{
                                    fontSize: "14px",
                                    padding: "2px 10px",
                                    fontWeight: 600,
                                  }}
                                >
                                  <ChevronLeft size={17} />
                                </button>
                              </li>
                            )}

                            {getDisplayPages().map((page) => (
                              <li
                                key={page}
                                className={`page-item ${
                                  currentPage === page ? "active" : ""
                                }`}
                                style={{ padding: "5px 2px" }}
                              >
                                <button
                                  onClick={() => handleClick(page)}
                                  className="page-link"
                                  style={{
                                    fontSize: "14px",
                                    padding: "2px 10px",
                                    fontWeight: 600,
                                    backgroundColor:
                                      currentPage === page ? "gray" : " ",
                                  }}
                                >
                                  {page}
                                </button>
                              </li>
                            ))}

                            {currentPage < totalPages && (
                              <li
                                className="page-item"
                                style={{ padding: "5px 2px" }}
                              >
                                <button
                                  onClick={() => handleClick(currentPage + 1)}
                                  className="page-link"
                                  style={{
                                    fontSize: "14px",
                                    padding: "2px 10px",
                                    fontWeight: 600,
                                  }}
                                >
                                  <ChevronRight size={17} />
                                </button>
                              </li>
                            )}
                          </ul>
                        </nav>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="addModal"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="addModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg" role="document">
          <div
            className="modal-content"
            style={{
              maxHeight: "800px",
              overflowY: "auto",
              overflowX: "hidden",
            }}
          >
            <div
              className="modal-header"
              style={{
                position: "sticky",
                top: 0,
                background: "white",
                zIndex: 10,
              }}
            >
              <h5 className="modal-title" id="addModalLabel">
                {t("addNewEmployee")}
              </h5>
              <button
                id="closeAddEmpModalLabel"
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={handleCloseEmployee}
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body" style={{ padding: "20px" }}>
              <div className="row clearfix" style={{ marginTop: "10px" }}>
                {localStorage.role === "ROLE_SUPER_ADMIN" && (
                  <>
                    <div className="col-md-4 col-sm-6">
                      <div className="form-group" style={{ marginTop: "5px" }}>
                        <label
                          className="form-label"
                          style={{ color: "#58585B" }}
                        >
                          {t("company")}
                          <span
                            style={{
                              color: "red",
                              fontSize: "12px",
                              marginLeft: "1px",
                              marginRight: "4px",
                            }}
                          >
                            *
                          </span>
                        </label>
                        <Select
                          options={companyOptions}
                          value={companyOptions.find(
                            (option) => option.value === companyId
                          )}
                          onChange={(selectedOption) =>
                            handleCompanySelect(selectedOption.value)
                          }
                          placeholder={t("select")}
                        />
                        {errors.companyId && (
                          <div style={invalidFeedbackStyle}>
                            {errors.companyId}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="col-md-4 col-sm-6">
                      <div className="form-group" style={{ marginTop: "5px" }}>
                        <label
                          className="form-label"
                          style={{ color: "#58585B" }}
                        >
                          Manager
                          <span
                            style={{
                              color: "red",
                              fontSize: "12px",
                              marginLeft: "1px",
                              marginRight: "4px",
                            }}
                          >
                            *
                          </span>
                        </label>
                        <Select
                          options={managerOptions}
                          value={managerOptions.find(
                            (option) => option.value === managerId
                          )}
                          onChange={(selectedOption) =>
                            setManagerId(selectedOption.value)
                          }
                          placeholder={t("select")}
                        />
                        {errors.managerId && (
                          <div style={invalidFeedbackStyle}>
                            {errors.managerId}
                          </div>
                        )}
                      </div>
                    </div>
                  </>
                )}
                <div className="col-md-4 col-sm-6 mt-1">
                  <div className="form-group">
                    <label className="form-label" style={{ color: "#58585B" }}>
                      {t("insuranceProvider")}
                      <span
                        style={{
                          color: "red",
                          fontSize: "12px",
                          marginLeft: "1px",
                          marginRight: "4px",
                        }}
                      >
                        *
                      </span>
                    </label>
                    <Select
                      options={insuranceOptions}
                      value={insuranceOptions.find(
                        (option) => option.value === providerId
                      )}
                      onChange={(selectedOption) =>
                        setProviderId(selectedOption.value)
                      }
                      placeholder={t("select")}
                    />
                  </div>
                  {errors.managerId && (
                    <div style={invalidFeedbackStyle}>{errors.managerId}</div>
                  )}
                </div>
                <div className="col-md-3 col-sm-6">
                  <div className="form-group" style={{ marginTop: "5px" }}>
                    <label className="form-label" style={{ color: "#58585B" }}>
                      {t("employeeNumber")}
                      <span
                        style={{
                          color: "red",
                          fontSize: "12px",
                          marginLeft: "1px",
                          marginRight: "4px",
                        }}
                      >
                        *
                      </span>
                    </label>
                    <input
                      type="text"
                      className={`form-control ${
                        valid.employeeNumber ? "is-valid" : " "
                      }  ${errors.employeeNumber ? "is-invalid" : " "}  `}
                      name="employeeNumber"
                      value={employeeNumber}
                      onChange={(e) => setEmployeeNumber(e.target.value)}
                      placeholder={t("enterHere")}
                    />
                    {errors.employeeNumber && (
                      <div className="invalid-feedback">
                        {errors.employeeNumber}
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-md-3 col-sm-6">
                  <div className="form-group" style={{ marginTop: "5px" }}>
                    <label className="form-label" style={{ color: "#58585B" }}>
                      Title
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="title"
                      value={title}
                      onChange={(e) => setTitle(e.target.value)}
                      placeholder="Enter Title"
                      required
                    />
                  </div>
                </div>
                <div className="col-md-3 col-sm-6">
                  <div className="form-group" style={{ marginTop: "5px" }}>
                    <label className="form-label" style={{ color: "#58585B" }}>
                      {t("firstName")}
                      <span
                        style={{
                          color: "red",
                          fontSize: "12px",
                          marginLeft: "1px",
                          marginRight: "4px",
                        }}
                      >
                        *
                      </span>
                    </label>
                    <input
                      type="text"
                      className={`form-control ${
                        valid.firstName ? "is-valid" : " "
                      }  ${errors.firstName ? "is-invalid" : " "}  `}
                      name="firstName"
                      value={firstName}
                      onChange={(e) => setFirstName(e.target.value)}
                      placeholder="First Name"
                      required
                    />
                    {errors.firstName && (
                      <div className="invalid-feedback">{errors.firstName}</div>
                    )}
                  </div>
                </div>
                <div className="col-md-3 col-sm-6">
                  <div className="form-group" style={{ marginTop: "5px" }}>
                    <label className="form-label" style={{ color: "#58585B" }}>
                      {t("lastName")}
                      <span
                        style={{
                          color: "red",
                          fontSize: "12px",
                          marginLeft: "1px",
                          marginRight: "4px",
                        }}
                      >
                        *
                      </span>
                    </label>
                    <input
                      type="text"
                      className={`form-control ${
                        valid.lastName ? "is-valid" : " "
                      }  ${errors.lastName ? "is-invalid" : " "}  `}
                      name="lastName"
                      value={lastName}
                      onChange={(e) => setLastName(e.target.value)}
                      placeholder="Last Name"
                      required
                    />
                    {errors.lastName && (
                      <div className="invalid-feedback">{errors.lastName}</div>
                    )}
                  </div>
                </div>
                <div className="col-md-4 col-sm-6">
                  <div className="form-group">
                    <label
                      className="form-label"
                      style={{ color: "#58585B", marginTop: "5px" }}
                    >
                      {t("gender")}
                      <span
                        style={{
                          color: "red",
                          fontSize: "12px",
                          marginLeft: "1px",
                          marginRight: "4px",
                        }}
                      >
                        *
                      </span>
                    </label>
                    <Select
                      options={genderOptions}
                      value={genderOptions.find(
                        (option) => option.value === gender
                      )}
                      onChange={(selectedOption) =>
                        setGender(selectedOption.value)
                      }
                      placeholder="Select"
                    />
                    {errors.gender && (
                      <div style={invalidFeedbackStyle}>{errors.gender}</div>
                    )}
                  </div>
                </div>
                <div className="col-md-4 col-sm-6">
                  <div className="form-group" style={{ marginTop: "5px" }}>
                    <label className="form-label" style={{ color: "#58585B" }}>
                      {t("birthDate")}
                      <span
                        style={{
                          color: "red",
                          fontSize: "12px",
                          marginLeft: "1px",
                          marginRight: "4px",
                        }}
                      >
                        *
                      </span>
                    </label>
                    <input
                      type="date"
                      className={`form-control ${
                        valid.birthDate ? "is-valid" : " "
                      }  ${errors.birthDate ? "is-invalid" : " "}  `}
                      name="birthDate"
                      value={birthDate}
                      onChange={(e) => setBirthDate(e.target.value)}
                      required
                    />
                    {errors.birthDate && (
                      <div className="invalid-feedback">{errors.birthDate}</div>
                    )}
                  </div>
                </div>
                <div className="col-md-4 col-sm-6">
                  <div className="form-group" style={{ marginTop: "5px" }}>
                    <label className="form-label" style={{ color: "#58585B" }}>
                      {t("employmentDate")}
                      <span
                        style={{
                          color: "red",
                          fontSize: "12px",
                          marginLeft: "1px",
                          marginRight: "4px",
                        }}
                      >
                        *
                      </span>
                    </label>
                    <input
                      type="date"
                      className={`form-control ${
                        valid.employmentDate ? "is-valid" : " "
                      }  ${errors.employmentDate ? "is-invalid" : " "}  `}
                      name="employmentDate"
                      value={employmentDate}
                      onChange={(e) => setEmploymentDate(e.target.value)}
                      required
                    />
                    {errors.employmentDate && (
                      <div className="invalid-feedback">
                        {errors.employmentDate}
                      </div>
                    )}
                  </div>
                </div>
                {/* <div className="col-md-4 col-sm-6">
                  <div className="form-group">
                    <label className="form-label" style={{ color: "#58585B" }}>
                      {t("employmentType")}
                      <span
                        style={{
                          color: "red",
                          fontSize: "12px",
                          marginLeft: "1px",
                          marginRight: "4px",
                        }}
                      >
                        *
                      </span>
                    </label>
                    <Select
                      options={employmentTypeOptions}
                      value={employmentTypeOptions.find(
                        (option) => option.value === employmentType
                      )}
                      onChange={(selectedOption) =>
                        setEmploymentType(selectedOption.value)
                      }
                      placeholder="Select"
                    />
                    {errors.employmentType && (
                      <div style={invalidFeedbackStyle}>
                        {errors.employmentType}
                      </div>
                    )}
                  </div>
                </div> */}
                <div className="col-md-4 col-sm-6">
                  <div className="form-group">
                    <label className="form-label" style={{ color: "#58585B" }}>
                      {t("mobile")}
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="mobile"
                      value={mobile}
                      onChange={(e) => setMobile(e.target.value)}
                      placeholder={t("enterHere")}
                    />
                  </div>
                </div>
                <div className="col-md-4 col-sm-6">
                  <div className="form-group">
                    <label className="form-label" style={{ color: "#58585B" }}>
                      {t("phone")}
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="phone"
                      value={phone}
                      onChange={(e) => setPhone(e.target.value)}
                      placeholder={t("enterHere")}
                    />
                  </div>
                </div>
                <div className="col-md-4 col-sm-6">
                  <div className="form-group">
                    <label className="form-label" style={{ color: "#58585B" }}>
                      Email
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      placeholder="Email"
                    />
                  </div>
                </div>
                {/*<div className="col-md-4 col-sm-6">
                  <div className="form-group" style={{ marginTop: "5px" }}>
                    <label className="form-label" style={{ color: "#58585B" }}>
                      {t("position")}
                      <span
                        style={{
                          color: "red",
                          fontSize: "12px",
                          marginLeft: "1px",
                          marginRight: "4px",
                        }}
                      >
                        *
                      </span>
                    </label>
                    <input
                      type="text"
                      className={`form-control ${
                        valid.position ? "is-valid" : " "
                      }  ${errors.position ? "is-invalid" : " "}  `}
                      name="position"
                      value={position}
                      onChange={(e) => setPosition(e.target.value)}
                      placeholder="Position"
                      required
                    />
                    {errors.position && (
                      <div className="invalid-feedback">{errors.position}</div>
                    )}
                  </div>
                </div>*/}
                {/* <div className="col-md-4 col-sm-6">
                  <div className="form-group">
                    <label className="form-label">
                    Company Commission
                      <span
                        style={{
                          color: "red",
                          fontSize: "12px",
                          marginLeft: "1px",
                          marginRight: "4px",
                        }}
                      >
                        *
                      </span>
                    </label>
                    <input
                      type="text"
                      className={`form-control ${
                        valid.companyCommissionPer ? "is-valid" : " "
                      }  ${errors.companyCommissionPer ? "is-invalid" : " "}  `}
                      name="companyCommissionPer"
                      value={formData.companyCommissionPer}
                      onChange={handleInputChange}
                      placeholder="Enter Company Commission"
                      required
                    />
                    {errors.companyCommissionPer && (
                      <div className="invalid-feedback">{errors.companyCommissionPer}</div>
                    )}
                  </div>
                </div>
                <div className="col-md-4 col-sm-6">
                  <div className="form-group">
                    <label className="form-label">
                    Agent Commission
                      <span
                        style={{
                          color: "red",
                          fontSize: "12px",
                          marginLeft: "1px",
                          marginRight: "4px",
                        }}
                      >
                        *
                      </span>
                    </label>
                    <input
                      type="text"
                      className={`form-control ${
                        valid.agentCommissionPer ? "is-valid" : " "
                      }  ${errors.agentCommissionPer ? "is-invalid" : " "}  `}
                      name="agentCommissionPer"
                      value={formData.agentCommissionPer}
                      onChange={handleInputChange}
                      placeholder="Enter Agent Commission"
                      required
                    />
                    {errors.agentCommissionPer && (
                      <div className="invalid-feedback">{errors.agentCommissionPer}</div>
                    )}
                  </div>
                </div> */}

                {/* <div className="col-12" style={{ marginBottom: "30px" }}>
                  <div className="form-group" style={{ marginTop: "10px" }}>
                    <label className="custom-control custom-checkbox">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        checked={generateCreds}
                        onChange={() => setGenerateCreds(!generateCreds)}
                      />
                      <span
                        className="custom-control-label"
                        style={{
                          color: "#58585B",
                          fontSize: "16px",
                          fontWeight: 500,
                        }}
                      >
                        {t("createEmployeeAccount")}
                      </span>
                    </label>
                    <small id="fileHelp" className="form-text text-muted">
                      {t("sendInviteAndGenerateCredentials")}
                    </small>
                  </div>
                </div> */}
              </div>
            </div>
            <div
              className="modal-footer mt-40"
              style={{
                position: "sticky",
                bottom: 0,
                background: "white",
                // zIndex: 10,
              }}
            >
              <button
                type="button"
                onClick={() => handleCloseEmployee()}
                className="btn btn-secondary"
                data-dismiss="modal"
              >
                {t("close")}
              </button>
              <button
                type="button"
                onClick={() => handleSave()}
                className="btn btn-primary"
              >
                {t("save")}
              </button>
            </div>
          </div>
        </div>
      </div>
      <Dialog
        open={isLoading}
        style={{ backdropFilter: "blur(2px)" }}
        fullWidth
      >
        <div
          className="flex flex-col justify-center"
          style={{ backgroundColor: "transparent" }}
        >
          <div className="mx-auto" style={{ backgroundColor: "transparent" }}>
            <Loading />
          </div>
        </div>
      </Dialog>
    </>
  );
}

export default Employee;

import React, { useEffect, useState } from "react";
import UploadDialog from "./UploadDialog";
import FileIcon from "./FileIcon";
import { Dialog } from "@mui/material";
import { FileUploadOutlined, FileUploadRounded } from "@mui/icons-material";
import {
  deleteDocumentById,
  downloadDocumentById,
  getInsuranceDocumentsList,
  verifyDocumentById,
} from "../../services/documentService";
import { Check, EyeIcon, File } from "lucide-react";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import {
  generateApplicationForm,
  generateCardDeliveryForm,
  generateFamilyInsuranceForm,
  generatePreviousInsuranceForm,
  getFormsSubmittedInfo,
  sendCompanyManagerEmail,
} from "../../services/insuranceFormService";
import Loading from "./Loading";
import MultiSelect from "./MultiSelect";

const DocumentUploader = ({ employeeId }) => {
  const [uploadDialogOpen, setUploadDialogOpen] = useState(false);
  const [uploadingDocumentIndex, setUploadingDocumentIndex] = useState(null);
  const [isRequired, setIsRequired] = useState(true);
  const [documents, setDocuments] = useState([]);
  const [additionalDocuments, setAdditionalDocuments] = useState([]);
  const [docTypeId, setDocTypeId] = useState(null);
  const [formsData, setFormsData] = useState({});
  const [managerAttachments, setManagerAttachments] = useState([]);
  const [sendEmailDisabled, setSendEmailDisabled] = useState(false);
  const [docOptions, setDocOptions] = useState([]);
  const [formOptions, setFormOptions] = useState([]);
  const [selectedDocIds, setSelectedDocIds] = useState([]);
  const [selectedForms, setSelectedForms] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [includeEmployeePhoto, setIncludeEmployeePhoto] = useState(false);

  const { t } = useTranslation("employee");

  useEffect(() => {
    if (employeeId !== undefined) {
      fetchInsuranceDocumentsList(employeeId);
      fetchFormsSubmittedInfo();
    }
  }, [employeeId]);

  useEffect(() => {}, [documents, additionalDocuments]);

  const fetchInsuranceDocumentsList = async (employeeId) => {
    try {
      if (!employeeId) return;
      const response = await getInsuranceDocumentsList(
        localStorage.token,
        employeeId
      );
      setDocuments(response.required);
      setAdditionalDocuments(response.additional);
      setManagerAttachments(response.managerDocs);
      const allDocs = [
        ...response.required,
        ...response.additional,
        ...response.managerDocs,
      ];
      const options = allDocs
        .filter((doc) => doc.fileName)
        .map((doc) => ({ value: doc.docId, label: doc.fileName }));
      setDocOptions(options);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchFormsSubmittedInfo = async () => {
    try {
      if (!employeeId) return;
      const response = await getFormsSubmittedInfo(
        localStorage.token,
        employeeId
      );
      setFormsData(response);
      if (Object.entries(response).length !== 0) {
        let options = [];
        Object.entries(response).forEach(([key, value]) => {
          options.push({ label: getFormName(key), value: key });
        });
        setFormOptions(options);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleUpload = () => {
    if (uploadingDocumentIndex !== null) {
      const updatedDocuments = [...documents];
      updatedDocuments[uploadingDocumentIndex].status = "UPLOADED";
      setDocuments(updatedDocuments);
    }
    setUploadDialogOpen(false);
    setUploadingDocumentIndex(null);
    fetchInsuranceDocumentsList(employeeId);
  };

  const openUploadDialog = (index, docTypeId) => {
    setIsRequired(true);
    setUploadingDocumentIndex(index);
    setDocTypeId(docTypeId);
    setUploadDialogOpen(true);
  };

  const pendingCount = documents.filter(
    (doc) => doc.status === "PENDING" && doc.mandatory === true
  ).length;

  const convertToGermanDateTime = (inputDateTimeString) => {
    const inputDate = new Date(inputDateTimeString + " UTC");
    const germanFormattedDateTime = inputDate.toLocaleString("de-DE", {
      timeZone: "Europe/Berlin", // German timezone
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: false, // Use 12-hour format
    });

    return germanFormattedDateTime;
  };

  const verifyDocument = async (docId) => {
    try {
      const response = await verifyDocumentById(
        employeeId,
        docId,
        localStorage.token
      );
      if (response) {
        toast.success(t("verifiedSuccessfully"));
        fetchInsuranceDocumentsList(employeeId);
      } else {
        toast.error(t("verificationFailed"));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const deleteDocument = async (docId) => {
    try {
      const response = await deleteDocumentById(
        employeeId,
        docId,
        localStorage.token
      );
      if (response) {
        toast.success(t("deletedSuccessfully"));
        fetchInsuranceDocumentsList(employeeId);
      } else {
        toast.error(t("deletionFailed"));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const viewApplicationForm = async () => {
    try {
      setIsLoading(true);
      const response = await generateApplicationForm(
        employeeId,
        localStorage.token
      );
      const blob = new Blob([response.data], {
        type: response.headers["content-type"],
      });
      console.log("response.headers", response.headers);
      const contentDisposition = response.headers["content-disposition"];
      console.log("contentDisposition", contentDisposition);
      const filenameRegex = /filename=([^;]+)/;
      const matches = filenameRegex.exec(contentDisposition);
      console.log("matches", matches);
      const filename =
        matches && matches[1]
          ? matches[1].trim()
          : "Versicherungsantragsformular.pdf";
      console.log("filename", filename);
      const fileURL = URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.style.display = "none";
      a.href = fileURL;
      a.download = filename;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      URL.revokeObjectURL(fileURL);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  const viewFamilyInsForm = async () => {
    try {
      setIsLoading(true);
      const response = await generateFamilyInsuranceForm(
        employeeId,
        localStorage.token
      );
      const blob = new Blob([response.data], {
        type: response.headers["content-type"],
      });
      const fileURL = URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.style.display = "none";
      a.href = fileURL;
      a.download = "Formular_zur_Familienversicherung.pdf";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      URL.revokeObjectURL(fileURL);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  const viewCardDeliveryForm = async () => {
    try {
      setIsLoading(true);
      const response = await generateCardDeliveryForm(
        employeeId,
        localStorage.token
      );
      const blob = new Blob([response.data], {
        type: response.headers["content-type"],
      });
      const fileURL = URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.style.display = "none";
      a.href = fileURL;
      a.download = "Kartenliefer_Vollmacht_formular.pdf";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      URL.revokeObjectURL(fileURL);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  const viewPreviousInsuranceForm = async () => {
    try {
      setIsLoading(true);
      const response = await generatePreviousInsuranceForm(
        employeeId,
        localStorage.token
      );
      const blob = new Blob([response.data], {
        type: response.headers["content-type"],
      });
      const fileURL = URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.style.display = "none";
      a.href = fileURL;
      a.download = "Vorheriges_Versicherungsformular.pdf";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      URL.revokeObjectURL(fileURL);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  const handleViewForm = (type) => {
    if (type === "APPLICATION_FORM") {
      viewApplicationForm();
    } else if (type === "CARD_DELIVERY_FORM") {
      viewCardDeliveryForm();
    } else if (type === "FAMILY_INSURANCE_FORM") {
      viewFamilyInsForm();
    } else if (type === "PREVIOUS_INSURANCE_FORM") {
      viewPreviousInsuranceForm();
    }
  };

  const getFormName = (type) => {
    switch (type) {
      case "APPLICATION_FORM":
        return "Versicherungsantragsformular.pdf";
      case "CARD_DELIVERY_FORM":
        return "Kartenliefer_Vollmacht_formular.pdf";
      case "FAMILY_INSURANCE_FORM":
        return "Formular_zur_Familienversicherung.pdf";
      case "PREVIOUS_INSURANCE_FORM":
        return "Vorheriges_Versicherungsformular.pdf";
      default:
        return null;
    }
  };

  const handleDownload = async (docId, fileName) => {
    console.log("download docId ", docId);
    try {
      setIsLoading(true);
      const response = await downloadDocumentById(
        employeeId,
        docId,
        localStorage.token
      );
      const blob = new Blob([response.data], {
        type: response.headers["content-type"],
      });
      const fileURL = URL.createObjectURL(blob);
      window.open(fileURL);
      const link = document.createElement("a");
      link.href = fileURL;
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      setIsLoading(false);
      link.click();
      link.parentNode.removeChild(link);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  const sendEmailToManager = async () => {
    console.log("selectedDocIds  - ", selectedDocIds);
    console.log("selectedForms  - ", selectedForms);
    console.log("includeEmployeePhoto  - ", includeEmployeePhoto);
    try {
      setIsLoading(true);
      const response = await sendCompanyManagerEmail(
        employeeId,
        localStorage.token,
        {
          selectedDocIds,
          selectedForms,
          includeEmployeePhoto,
        }
      );
      if (response) {
        toast.success(t("mailWillBeSent"));
      } else {
        toast.error(t("mailRequestFailed"));
      }
      setSendEmailDisabled(false);
      setSelectedDocIds([]);
      setSelectedForms([]);
      setIncludeEmployeePhoto(false);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setSendEmailDisabled(false);
      setSelectedDocIds([]);
      setSelectedForms([]);
      setIncludeEmployeePhoto(false);
      setIsLoading(false);
    }
  };

  const buttonStyle = {
    backgroundColor: "#17a2b8", // Info color
    color: "white", // Text color
    border: "none", // Remove border
    padding: "0.33rem 1rem", // Padding
    fontSize: "1rem", // Font size
    borderRadius: "0.25rem", // Rounded corners
    cursor: "pointer", // Pointer cursor
  };

  const hoverStyle = {
    backgroundColor: "#138496", // Darker shade on hover
  };

  return (
    <div className="card">
      <div className="card-body">
        <div
          className="card-title"
          style={{ fontWeight: 600, color: "#4B0082" }}
        >
          {t("forms_generated")}
        </div>
        <div className="px-2 pt-1 pb-1">
          {formsData && Object.entries(formsData).length === 0 && (
            <span className="text-red-400" style={{ fontWeight: 450 }}>
              {t("forms_not_submitted_yet")}
            </span>
          )}
          {formsData &&
            Object.entries(formsData).length !== 0 &&
            Object.entries(formsData).map(([key, value]) => (
              <>
                <div className="row mt-1">
                  <div className="col-md-4 col-sm-12">
                    <div className="flex flex-row">
                      <File className="mr-2" />
                      <span
                        style={{ cursor: "pointer" }}
                        onClick={() => handleViewForm(key)}
                      >
                        {getFormName(key)}
                      </span>
                    </div>
                  </div>
                  <div
                    className="col-md-4 col-sm-12"
                    style={{ color: "green" }}
                  >
                    {t("generatedOn")} {convertToGermanDateTime(value)}
                  </div>
                  <div className="col-md-2 col-sm-12">
                    <span
                      onClick={() => handleViewForm(key)}
                      style={{
                        color: "#18A2FF",
                        padding: "2px",
                        textDecoration: "underline",
                        cursor: "pointer",
                      }}
                    >
                      {t("view")}
                    </span>
                  </div>
                </div>
              </>
            ))}
        </div>
      </div>
      <div className="card-body">
        <div
          className="card-title"
          style={{ fontWeight: 600, color: "#4B0082" }}
        >
          {t("listOfDocumentsForInsuranceProvider")}
        </div>

        {pendingCount > 0 && (
          <div className="card-alert alert alert-danger mb-0">
            {pendingCount}&nbsp; {t("documentsPending")}
          </div>
        )}
        {/* {pendingCount == 0 && (
          <div className="card-alert alert alert-success mb-0">
            {t("allRequiredDocumentsUploaded")}
          </div>
        )} */}
        <div className="card">
          <div className="card-body">
            {documents.map((document, index) => (
              <div
                className="row clearfix mt-1"
                style={{ borderBottom: "1px solid #E8E9E9" }}
              >
                <div className="col-md-1 col-sm-12 text-center">
                  <FileIcon
                    status={document.status}
                    onClick={() => openUploadDialog(index, document.docTypeId)}
                  />
                </div>
                <div className="col-md-5 col-sm-12">
                  {document.fileName ? (
                    <>
                      <span
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          handleDownload(document.docId, document.fileName)
                        }
                      >
                        {document.fileName}
                      </span>
                      <br />
                      <small className="text-muted">
                        {document.documentType}
                      </small>
                      <small className="text-muted ml-2">
                        {" - "} {t("uploadedOn")}{" "}
                        {convertToGermanDateTime(document.uploadedDate)}
                      </small>
                    </>
                  ) : (
                    <span>{document.documentType}</span>
                  )}
                </div>
                <div className="col-md-3 col-sm-12">
                  <span>
                    {document.mandatory ? (
                      <>{t("mandatory")}</>
                    ) : (
                      <>{t("optional")}</>
                    )}
                  </span>
                  <br />
                  <small className="text-muted">
                    {document.sendAsAttachment && t("sendAsAttachment")}
                  </small>
                </div>
                <div className="col-md-1 col-sm-12 text-right mr-4">
                  {document.docId && !document.verified && (
                    <button
                      className="btn"
                      style={buttonStyle}
                      onMouseOver={(e) =>
                        (e.currentTarget.style.backgroundColor =
                          hoverStyle.backgroundColor)
                      }
                      onMouseOut={(e) =>
                        (e.currentTarget.style.backgroundColor =
                          buttonStyle.backgroundColor)
                      }
                      onClick={() => verifyDocument(document.docId)}
                    >
                      {t("verify")}
                    </button>
                  )}
                  {document.docId && document.verified && (
                    <span
                      className="tag tag-success"
                      style={{ fontWeight: 600, textTransform: "uppercase" }}
                    >
                      {t("verified")}
                    </span>
                  )}
                </div>
                <div className="col-md-1 col-sm-12 text-right">
                  {document.docId ? (
                    <button
                      className="btn btn-info ml-4"
                      onClick={() => deleteDocument(document.docId)}
                    >
                      {t("delete")}
                    </button>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="card-body">
        <div
          className="card-title"
          style={{ fontWeight: 600, color: "#4B0082" }}
        >
          {t("additionalDocuments")}
        </div>

        <div className="card-alert alert alert-success mb-0">
          {t("uploadInstructions")}
        </div>
        <div className="px-2 pt-4 pb-1">
          {additionalDocuments.map((document, index) => (
            <div
              className="row clearfix mt-1"
              style={{ borderBottom: "1px solid #E8E9E9" }}
            >
              <div className="col-md-1 col-sm-12 text-center">
                <Check style={{ color: "green" }} />
              </div>
              <div className="col-md-5 col-sm-12">
                <span
                  style={{ cursor: "pointer" }}
                  onClick={() =>
                    handleDownload(document.docId, document.fileName)
                  }
                >
                  {document.fileName}
                </span>
                <br />
                <small className="text-muted">
                  {t("uploadedOn")}{" "}
                  {convertToGermanDateTime(document.uploadedDate)}
                </small>
              </div>
              <div className="col-md-3 col-sm-12">
                <span>
                  {document.sendAsAttachment ? (
                    <>{t("sendAsAttachment")}</>
                  ) : (
                    <>{t("dontSendAsAttachment")}</>
                  )}
                </span>
              </div>
              <div className="col-md-1 col-sm-12 text-right mr-4">
                {document.docId && !document.verified && (
                  <button
                    className="btn"
                    style={buttonStyle}
                    onMouseOver={(e) =>
                      (e.currentTarget.style.backgroundColor =
                        hoverStyle.backgroundColor)
                    }
                    onMouseOut={(e) =>
                      (e.currentTarget.style.backgroundColor =
                        buttonStyle.backgroundColor)
                    }
                    onClick={() => verifyDocument(document.docId)}
                  >
                    {t("verify")}
                  </button>
                )}
                {document.docId && document.verified && (
                  <span
                    className="tag tag-success"
                    style={{ fontWeight: 600, textTransform: "uppercase" }}
                  >
                    {t("verified")}
                  </span>
                )}
              </div>
              <div className="col-md-1 col-sm-12 text-right">
                {document.docId ? (
                  <button
                    className="btn btn-info ml-4"
                    onClick={() => deleteDocument(document.docId)}
                  >
                    {t("delete")}
                  </button>
                ) : (
                  ""
                )}
              </div>
            </div>
          ))}
        </div>
        <button
          className="btn btn-primary mt-1"
          onClick={() => {
            setIsRequired(false);
            setUploadDialogOpen(true);
            setDocTypeId(-1);
          }}
        >
          <FileUploadOutlined className="mr-2" /> {t("upload")}
        </button>
      </div>
      {localStorage.role !== "ROLE_ADMIN" && (
        <div className="card-body">
          <div
            className="card-title"
            style={{ fontWeight: 600, color: "#4B0082" }}
          >
            {t("mail_documents_to_manager")}
          </div>
          <div className="row">
            <div className="col-md-6 col-sm-12" style={{ marginTop: "5px" }}>
              <div className="form-group" style={{ marginTop: "5px" }}>
                <label className="form-label" style={{ color: "#282826" }}>
                  {t("select_documents")}
                  <span
                    style={{
                      color: "red",
                      fontSize: "12px",
                      marginLeft: "1px",
                      marginRight: "4px",
                    }}
                  >
                    *
                  </span>
                </label>
                <MultiSelect
                  selectOptions={docOptions}
                  setValues={setSelectedDocIds}
                  selectedValues={selectedDocIds}
                />
              </div>
            </div>
            <div className="col-md-6 col-sm-12" style={{ marginTop: "5px" }}>
              <div className="form-group" style={{ marginTop: "5px" }}>
                <label className="form-label" style={{ color: "#282826" }}>
                  {t("select_forms")}
                  <span
                    style={{
                      color: "red",
                      fontSize: "12px",
                      marginLeft: "1px",
                      marginRight: "4px",
                    }}
                  >
                    *
                  </span>
                </label>
                <MultiSelect
                  selectOptions={formOptions}
                  setValues={setSelectedForms}
                  selectedValues={selectedForms}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 col-sm-12" style={{ marginTop: "5px" }}>
              <div className="form-group">
                <label className="custom-control custom-checkbox">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    checked={includeEmployeePhoto}
                    onChange={() =>
                      setIncludeEmployeePhoto(!includeEmployeePhoto)
                    }
                  />
                  <span
                    className="custom-control-label"
                    style={{
                      color: "#282826",
                      fontSize: "16px",
                      fontWeight: 500,
                    }}
                  >
                    {t("include_employee_photo")}
                  </span>
                </label>
              </div>
            </div>
            <div className="col-md-6 col-sm-12" style={{ marginTop: "5px" }}>
              <button
                className="btn mt-2"
                style={buttonStyle}
                onMouseOver={(e) =>
                  (e.currentTarget.style.backgroundColor =
                    hoverStyle.backgroundColor)
                }
                onMouseOut={(e) =>
                  (e.currentTarget.style.backgroundColor =
                    buttonStyle.backgroundColor)
                }
                onClick={() => {
                  setIsRequired(false);
                  setUploadDialogOpen(true);
                  setDocTypeId(-3);
                }}
              >
                <FileUploadOutlined className="mr-2" /> {t("upload_attachment")}
              </button>
            </div>
          </div>
          <div className="px-2 pt-1 pb-1">
            <button
              className="btn btn-primary mt-2"
              onClick={() => {
                setSendEmailDisabled(true);
                sendEmailToManager();
              }}
              disabled={sendEmailDisabled}
            >
              {t("send_email")}
            </button>
          </div>
        </div>
      )}
      <Dialog
        open={uploadDialogOpen}
        style={{ backdropFilter: "blur(2px)" }}
        fullWidth
      >
        <UploadDialog
          onClose={() => setUploadDialogOpen(false)}
          onUpload={handleUpload}
          docTypeId={docTypeId}
          isRequired={isRequired}
          employeeId={employeeId}
        />
      </Dialog>
      <Dialog
        open={isLoading}
        style={{ backdropFilter: "blur(2px)" }}
        fullWidth
      >
        <div
          className="flex flex-col justify-center"
          style={{ backgroundColor: "transparent" }}
        >
          <div className="mx-auto" style={{ backgroundColor: "transparent" }}>
            <Loading />
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default DocumentUploader;

import { Dialog } from "@mui/material";
import React, { useEffect, useState } from "react";
import SignaturePad from "../../../Utility/SignaturePad";
import { toast } from "react-toastify";
import {
  generateCardDeliveryForm,
  getCardDeliveryForm,
  submitCardDeliveryFormApi,
} from "../../../../services/insuranceFormService";
import Loading from "../../../Utility/Loading";
import { useTranslation } from "react-i18next";
import { getCompanyCardDeliveryDetails } from "../../../../services/apiService";

const CardDeliveryForm = ({ employeeId }) => {
  const { t } = useTranslation("employee");
  const [signaturePadOpen, setSignaturePadOpen] = useState(false);
  const [applicationSubmitted, setApplicationSubmitted] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [submittedDateTime, setSubmittedDateTime] = useState("");
  const [employeeFirstName, setEmployeeFirstName] = useState("");
  const [employeeLastName, setEmployeeLastName] = useState("");
  const [employeeBirthDate, setEmployeeBirthDate] = useState("");
  const [streetHouseNumber, setStreetHouseNumber] = useState("");
  const [postCodeLocation, setPostCodeLocation] = useState("");
  const [careOf, setCareOf] = useState("");
  const [signUrl, setSignUrl] = useState("");
  const [signDate, setSignDate] = useState("");
  const [insuranceStartDate, setInsuranceStartDate] = useState(null);
  const [signLocation, setSignLocation] = useState("");
  const [receiverLastName, setReceiverLastName] = useState("");
  const [receiverFirstName, setReceiverFirstName] = useState("");
  const [employerName, setEmployerName] = useState("");
  const [receiverStreetHouseNumber, setReceiverStreetHouseNumber] =
    useState("");
  const [receiverPostCodeLocation, setReceiverPostCodeLocation] = useState("");
  const initialErrorState = {
    signDate: "",
    signLocation: "",
    signUrl: "",
    receiverFirstName: "",
    receiverLastName: "",
    employerName: "",
    receiverStreetHouseNumber: "",
    receiverPostCodeLocation: "",
    employeeFirstName: "",
    employeeLastName: "",
    employeeBirthDate: "",
    streetHouseNumber: "",
    postCodeLocation: "",
  };
  const [errors, setErrors] = useState(initialErrorState);
  const [hasErrors, setHasErrors] = useState(false);
  const invalidFeedbackStyle = {
    display: "block",
    width: "100%",
    marginTop: "0.25rem",
    fontSize: "90%",
    color: "#dc3545",
  };
  const invalidFeedbackCardStyle = {
    display: hasErrors ? "block" : "none",
    width: "100%",
    marginTop: "0.25rem",
    fontSize: "100%",
    color: "#dc3545",
  };
  useEffect(() => {
    getViactivCardDeliveryForm();
    fetchCompanyCardDeliveryDetails();
  }, []);

  useEffect(() => {}, [applicationSubmitted]);

  useEffect(() => {
    console.log("Card develiery");
    const currentDate = new Date();
    if (!insuranceStartDate) {
      console.log("Card insuranceStartDate ", insuranceStartDate);
      setSignDate(currentDate.toISOString().split("T")[0]);
      return;
    }
    console.log("currentDate ", currentDate);
    const insuranceDateObj = new Date(insuranceStartDate);
    console.log("insuranceDateObj ", insuranceDateObj);
    if (currentDate >= insuranceDateObj) {
      const oneDayBefore = new Date(insuranceDateObj);
      oneDayBefore.setDate(insuranceDateObj.getDate() - 1);
      const year = oneDayBefore.getFullYear();
      const month = String(oneDayBefore.getMonth() + 1).padStart(2, "0"); // Months are zero-based
      const day = String(oneDayBefore.getDate()).padStart(2, "0");
      const formattedDate = `${year}-${month}-${day}`;
      setSignDate(formattedDate);
    }
  }, [insuranceStartDate]);

  useEffect(() => {
    if (!insuranceStartDate) {
      console.log("Card develiery sign date use effect");
      return;
    }
    const signDateObj = new Date(signDate);
    console.log("signDateObj ", signDateObj);
    const insuranceDateObj = new Date(insuranceStartDate);
    console.log("insuranceDateObj ", insuranceDateObj);
    if (signDateObj >= insuranceDateObj) {
      console.log("sign date is greater than  insuranceDateObj");
      toast.error(
        "Das Signaturdatum sollte vor dem Versicherungsdatum liegen."
      );
      const oneDayBefore = new Date(insuranceDateObj);
      oneDayBefore.setDate(insuranceDateObj.getDate() - 1);
      const year = oneDayBefore.getFullYear();
      const month = String(oneDayBefore.getMonth() + 1).padStart(2, "0"); // Months are zero-based
      const day = String(oneDayBefore.getDate()).padStart(2, "0");
      const formattedDate = `${year}-${month}-${day}`;
      setSignDate(formattedDate);
    }
  }, [signDate]);

  const fetchCompanyCardDeliveryDetails = async () => {
    try {
      const response = await getCompanyCardDeliveryDetails(
        localStorage.token,
        employeeId
      );
      console.log("companuy", response);
      setReceiverFirstName(response?.manager?.managerFirstName);
      setReceiverLastName(response?.manager?.managerLastName);
      setSignLocation(response?.address?.location);
      setReceiverPostCodeLocation(
        getConcatString(
          response?.address?.postcode,
          response?.address?.location
        )
      );
      setReceiverStreetHouseNumber(
        getConcatString(
          response?.address?.street,
          response?.address?.houseNumber
        )
      );
      setEmployerName(response.name);
      setEmployeeFirstName(response?.employee?.firstName);
      setEmployeeLastName(response?.employee?.lastName);
      setEmployeeBirthDate(response?.employee?.birthDate);
      setCareOf(response?.employee?.address?.careOf);
      setStreetHouseNumber(
        getConcatString(
          response?.employee?.address?.street,
          response?.employee?.address?.houseNumber
        )
      );
      setPostCodeLocation(
        getConcatString(
          response?.employee?.address?.postcode,
          response?.employee?.address?.location
        )
      );
      setSignUrl(response?.signUrl);
      setInsuranceStartDate(response?.insuranceStartDate);
    } catch (error) {
      console.log(error);
    }
  };

  const getConcatString = (one, two) => {
    if (one === undefined && two === undefined) return "";
    if (one === undefined && two !== undefined) return one;
    if (one !== undefined && two === undefined) return two;
    return one + " , " + two;
  };

  const getViactivCardDeliveryForm = async () => {
    try {
      const response = await getCardDeliveryForm(
        localStorage.token,
        employeeId
      );
      if (response) {
        setCardDeliveryForm(response);
        setApplicationSubmitted(true);
        setDisabled(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const setCardDeliveryForm = (data) => {
    setSubmittedDateTime(data.submittedTime);
    setSignUrl(data.signUrl);
    setSignDate(data.signDate);
    setSignLocation(data.signLocation);
    setSignLocation(data.signLocation);
    setReceiverFirstName(data.receiverFirstName);
    setReceiverLastName(data.receiverLastName);
    setEmployerName(data.employerName);
    setReceiverStreetHouseNumber(data.receiverStreetHouseNumber);
    setReceiverPostCodeLocation(data.receiverPostCodeLocation);
    setCareOf(data.careOf);
    setStreetHouseNumber(data.streetHouseNumber);
    setPostCodeLocation(data.postCodeLocation);
    setEmployeeFirstName(data.employeeFirstName);
    setEmployeeLastName(data.employeeLastName);
    setEmployeeBirthDate(data.employeeBirthDate);
  };

  const validateForm = (formData) => {
    console.log("check errors");
    const newErrors = {};
    if (!formData.signDate || formData.signDate.trim == "") {
      newErrors.signDate = t("required");
    }

    if (insuranceStartDate && formData.signDate) {
      const signDateObj = new Date(formData.signDate);
      const insuranceDateObj = new Date(insuranceStartDate);
      if (signDateObj >= insuranceDateObj) {
        newErrors.signDate =
          "Das Signaturdatum sollte vor dem Versicherungsdatum liegen.";
      }
    }

    if (!formData.signLocation || formData.signLocation.trim() === "") {
      newErrors.signLocation = t("required");
    }
    if (!formData.signUrl || formData.signUrl.trim() === "") {
      newErrors.signUrl = t("required");
    }
    if (
      !formData.receiverFirstName ||
      formData.receiverFirstName.trim() === ""
    ) {
      newErrors.receiverFirstName = t("required");
    }
    if (!formData.receiverLastName || formData.receiverLastName.trim() === "") {
      newErrors.receiverLastName = t("required");
    }
    if (!formData.employerName || formData.employerName.trim() === "") {
      newErrors.employerName = t("required");
    }
    if (
      !formData.receiverStreetHouseNumber ||
      formData.receiverStreetHouseNumber.trim() === ""
    ) {
      newErrors.receiverStreetHouseNumber = t("required");
    }
    if (
      !formData.receiverPostCodeLocation ||
      formData.receiverPostCodeLocation.trim() === ""
    ) {
      newErrors.receiverPostCodeLocation = t("required");
    }
    if (
      !formData.employeeFirstName ||
      formData.employeeFirstName.trim() === ""
    ) {
      newErrors.employeeFirstName = t("required");
    }
    if (!formData.employeeLastName || formData.employeeLastName.trim() === "") {
      newErrors.employeeLastName = t("required");
    }
    if (
      !formData.employeeBirthDate ||
      formData.employeeBirthDate.trim() === ""
    ) {
      newErrors.employeeBirthDate = t("required");
    }
    if (
      !formData.streetHouseNumber ||
      formData.streetHouseNumber.trim() === ""
    ) {
      newErrors.streetHouseNumber = t("required");
    }
    if (!formData.postCodeLocation || formData.postCodeLocation.trim() === "") {
      newErrors.postCodeLocation = t("required");
    }

    setErrors(newErrors);
    setHasErrors(Object.keys(newErrors).length === 0 ? false : true);
    console.log("Errors", newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async () => {
    const formData = {
      signDate,
      signLocation,
      signUrl,
      receiverFirstName,
      receiverLastName,
      employerName,
      receiverStreetHouseNumber,
      receiverPostCodeLocation,
      employeeFirstName,
      employeeLastName,
      employeeBirthDate,
      streetHouseNumber,
      careOf,
      postCodeLocation,
      provider: "VIACTIVKRANKENKASSE",
      formType: "CARD_DELIVERY_FORM",
    };
    if (validateForm(formData)) {
      setDisabled(true);
      setIsLoading(true);
      setEditMode(false);
      submitCardDeliveryForm(formData);
    }
  };

  const submitCardDeliveryForm = async (formData) => {
    try {
      const response = await submitCardDeliveryFormApi(
        localStorage.token,
        employeeId,
        formData
      );
      if (response) {
        toast.success(t("formSubmittedSuccessfully"));
        getViactivCardDeliveryForm();
      } else {
        toast.error(t("formSubmissionFailed"));
      }
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };
  const closeSignaturePad = () => {
    setSignaturePadOpen(false);
  };

  const setSignUrlFromPad = (url) => {
    setSignUrl(url);
  };

  const handleEdit = () => {
    setEditMode(true);
    setDisabled(false);
  };

  const handleCancel = () => {
    setEditMode(false);
    setDisabled(true);
  };

  const generateForm = async () => {
    try {
      const response = await generateCardDeliveryForm(
        employeeId,
        localStorage.token
      );
      const blob = new Blob([response.data], {
        type: response.headers["content-type"],
      });
      console.log("response.headers", response.headers);
      const contentDisposition = response.headers["content-disposition"];
      console.log("contentDisposition", contentDisposition);
      const filenameRegex = /filename=([^;]+)/;
      const matches = filenameRegex.exec(contentDisposition);
      console.log("matches", matches);
      const filename =
        matches && matches[1]
          ? matches[1].trim()
          : "Kartenliefer_Vollmacht_formular.pdf";
      console.log("filename", filename);
      const fileURL = URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.style.display = "none";
      a.href = fileURL;
      a.download = filename;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      URL.revokeObjectURL(fileURL);
    } catch (error) {
      console.log(error);
    }
  };

  function formatDateOnlyGerman(dateString) {
    const date = new Date(dateString);
    const germanDate = new Intl.DateTimeFormat("de-DE", {
      day: "numeric",
      month: "long",
      year: "numeric",
    }).format(date);

    return germanDate;
  }

  return (
    <div className="card">
      <div className="card-body p-2">
        {applicationSubmitted && (
          <div className="card-alert alert alert-success mb-2">
            {t("applicationSubmittedOn")} &nbsp;
            {formatDateOnlyGerman(submittedDateTime)}.&nbsp;&nbsp;
            <button className="btn btn-info" onClick={generateForm}>
              {t("viewFromPDF")}
            </button>
          </div>
        )}
        <div style={invalidFeedbackCardStyle}>
          <div className="card-alert alert alert-danger mb-4">
            {t("cardDeliveryFormError")}
          </div>
        </div>
        <p style={{ color: "#5A5279", fontSize: "16px" }}>
          <b>Absender</b> (bitte in Druckbuchstaben)
        </p>
        <div style={{ backgroundColor: "whitesmoke" }} className="p-2">
          <div className="row ml-2 mt-10">
            <div className="col-md-4 col-sm-12">
              <div className="form-group" style={{ marginTop: "5px" }}>
                <label className="form-label" style={{ color: "#282826" }}>
                  Name
                  <span
                    style={{
                      color: "red",
                      fontSize: "12px",
                      marginLeft: "1px",
                      marginRight: "4px",
                    }}
                  >
                    *
                  </span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="employeeLastName"
                  value={employeeLastName}
                  onChange={(e) => setEmployeeLastName(e.target.value)}
                  placeholder="Enter Here"
                  required
                  disabled={disabled}
                />
                {errors.employeeLastName && (
                  <div style={invalidFeedbackStyle}>
                    {errors.employeeLastName}
                  </div>
                )}
              </div>
            </div>
            <div className="col-md-4 col-sm-12">
              <div className="form-group" style={{ marginTop: "5px" }}>
                <label className="form-label" style={{ color: "#282826" }}>
                  Vorname
                  <span
                    style={{
                      color: "red",
                      fontSize: "12px",
                      marginLeft: "1px",
                      marginRight: "4px",
                    }}
                  >
                    *
                  </span>
                </label>
                <input
                  type="text"
                  name="employeeFirstName"
                  value={employeeFirstName}
                  onChange={(e) => setEmployeeFirstName(e.target.value)}
                  className="form-control"
                  disabled={disabled}
                />
                {errors.employeeFirstName && (
                  <div style={invalidFeedbackStyle}>
                    {errors.employeeFirstName}
                  </div>
                )}
              </div>
            </div>
            <div className="col-md-4 col-sm-12">
              <div className="form-group" style={{ marginTop: "5px" }}>
                <label className="form-label" style={{ color: "#282826" }}>
                  Geburtsdatum
                  <span
                    style={{
                      color: "red",
                      fontSize: "12px",
                      marginLeft: "1px",
                      marginRight: "4px",
                    }}
                  >
                    *
                  </span>
                </label>
                <input
                  type="date"
                  name="employeeBirthDate"
                  value={employeeBirthDate}
                  onChange={(e) => setEmployeeBirthDate(e.target.value)}
                  className="form-control"
                  disabled={disabled}
                />
                {errors.employeeBirthDate && (
                  <div style={invalidFeedbackStyle}>
                    {errors.employeeBirthDate}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="row ml-2 mt-10">
            <div className="col-md-4 col-sm-12">
              <div className="form-group" style={{ marginTop: "5px" }}>
                <label className="form-label" style={{ color: "#282826" }}>
                  C/O
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="careOf"
                  value={careOf}
                  onChange={(e) => setCareOf(e.target.value)}
                  placeholder="Enter Here"
                  required
                  disabled={disabled}
                />
              </div>
            </div>
            <div className="col-md-4 col-sm-12">
              <div className="form-group" style={{ marginTop: "5px" }}>
                <label className="form-label" style={{ color: "#282826" }}>
                  Straße, Hausnummer
                  <span
                    style={{
                      color: "red",
                      fontSize: "12px",
                      marginLeft: "1px",
                      marginRight: "4px",
                    }}
                  >
                    *
                  </span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="streetHouseNumber"
                  value={streetHouseNumber}
                  onChange={(e) => setStreetHouseNumber(e.target.value)}
                  placeholder="Enter Here"
                  required
                  disabled={disabled}
                />
                {errors.streetHouseNumber && (
                  <div style={invalidFeedbackStyle}>
                    {errors.streetHouseNumber}
                  </div>
                )}
              </div>
            </div>
            <div className="col-md-4 col-sm-12">
              <div className="form-group" style={{ marginTop: "5px" }}>
                <label className="form-label" style={{ color: "#282826" }}>
                  PLZ, Ort
                  <span
                    style={{
                      color: "red",
                      fontSize: "12px",
                      marginLeft: "1px",
                      marginRight: "4px",
                    }}
                  >
                    *
                  </span>
                </label>
                <input
                  type="text"
                  name="postCodeLocation"
                  value={postCodeLocation}
                  onChange={(e) => setPostCodeLocation(e.target.value)}
                  className="form-control"
                  disabled={disabled}
                />
                {errors.postCodeLocation && (
                  <div style={invalidFeedbackStyle}>
                    {errors.postCodeLocation}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        <p style={{ color: "#5A5279", fontSize: "16px", marginTop: "20px" }}>
          <b>
            Vollmacht zum Empfang der elektronischen Gesundheitskarte (eGK) der
            VIACTIV Krankenkasse
          </b>
          <br />
          Folgende/-r Empfänger/-in darf meine eGK entgegennehmen:
        </p>
        <p style={{ color: "#5A5279", fontSize: "16px", marginTop: "20px" }}>
          <b>Empfänger</b> (bitte in Druckbuchstaben)
        </p>
        <div style={{ backgroundColor: "whitesmoke" }} className="p-2">
          <div className="row ml-2 mt-10">
            <div className="col-md-4 col-sm-12">
              <div className="form-group" style={{ marginTop: "5px" }}>
                <label className="form-label" style={{ color: "#282826" }}>
                  Name
                  <span
                    style={{
                      color: "red",
                      fontSize: "12px",
                      marginLeft: "1px",
                      marginRight: "4px",
                    }}
                  >
                    *
                  </span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="receiverLastName"
                  value={receiverLastName}
                  onChange={(e) => setReceiverLastName(e.target.value)}
                  placeholder="Enter Here"
                  required
                  disabled={disabled}
                />
                {errors.receiverLastName && (
                  <div style={invalidFeedbackStyle}>
                    {errors.receiverLastName}
                  </div>
                )}
              </div>
            </div>
            <div className="col-md-4 col-sm-12">
              <div className="form-group" style={{ marginTop: "5px" }}>
                <label className="form-label" style={{ color: "#282826" }}>
                  Vorname
                  <span
                    style={{
                      color: "red",
                      fontSize: "12px",
                      marginLeft: "1px",
                      marginRight: "4px",
                    }}
                  >
                    *
                  </span>
                </label>
                <input
                  type="text"
                  name="receiverFirstName"
                  value={receiverFirstName}
                  onChange={(e) => setReceiverFirstName(e.target.value)}
                  className="form-control"
                  disabled={disabled}
                />
                {errors.receiverFirstName && (
                  <div style={invalidFeedbackStyle}>
                    {errors.receiverFirstName}
                  </div>
                )}
              </div>
            </div>
            <div className="col-md-4 col-sm-12">
              <div className="form-group" style={{ marginTop: "5px" }}>
                <label className="form-label" style={{ color: "#282826" }}>
                  Name/Bezeichnung des Arbeitgebers
                  <span
                    style={{
                      color: "red",
                      fontSize: "12px",
                      marginLeft: "1px",
                      marginRight: "4px",
                    }}
                  >
                    *
                  </span>
                </label>
                <input
                  type="text"
                  name="employerName"
                  value={employerName}
                  onChange={(e) => setEmployerName(e.target.value)}
                  className="form-control"
                  disabled={disabled}
                />
                {errors.employerName && (
                  <div style={invalidFeedbackStyle}>{errors.employerName}</div>
                )}
              </div>
            </div>
          </div>
          <div className="row ml-2 mt-10">
            <div className="col-md-4 col-sm-12">
              <div className="form-group" style={{ marginTop: "5px" }}>
                <label className="form-label" style={{ color: "#282826" }}>
                  Straße, Hausnummer
                  <span
                    style={{
                      color: "red",
                      fontSize: "12px",
                      marginLeft: "1px",
                      marginRight: "4px",
                    }}
                  >
                    *
                  </span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="streetHouseNumber"
                  value={receiverStreetHouseNumber}
                  onChange={(e) => setReceiverStreetHouseNumber(e.target.value)}
                  placeholder="Enter Here"
                  required
                  disabled={disabled}
                />
                {errors.receiverStreetHouseNumber && (
                  <div style={invalidFeedbackStyle}>
                    {errors.receiverStreetHouseNumber}
                  </div>
                )}
              </div>
            </div>
            <div className="col-md-4 col-sm-12">
              <div className="form-group" style={{ marginTop: "5px" }}>
                <label className="form-label" style={{ color: "#282826" }}>
                  PLZ, Ort
                  <span
                    style={{
                      color: "red",
                      fontSize: "12px",
                      marginLeft: "1px",
                      marginRight: "4px",
                    }}
                  >
                    *
                  </span>
                </label>
                <input
                  type="text"
                  name="postCodeLocation"
                  value={receiverPostCodeLocation}
                  onChange={(e) => setReceiverPostCodeLocation(e.target.value)}
                  className="form-control"
                  disabled={disabled}
                />
                {errors.receiverPostCodeLocation && (
                  <div style={invalidFeedbackStyle}>
                    {errors.receiverPostCodeLocation}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="row ml-2 mt-4">
            <div className="col-md-4 col-sm-12">
              <div className="form-group" style={{ marginTop: "5px" }}>
                <label className="form-label" style={{ color: "#282826" }}>
                  Ort
                  <span
                    style={{
                      color: "red",
                      fontSize: "12px",
                      marginLeft: "1px",
                      marginRight: "4px",
                    }}
                  >
                    *
                  </span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="signLocation"
                  value={signLocation}
                  onChange={(e) => setSignLocation(e.target.value)}
                  disabled={disabled}
                />
                {errors.signLocation && (
                  <div style={invalidFeedbackStyle}>{errors.signLocation}</div>
                )}
              </div>
            </div>
            <div className="col-md-4 col-sm-12">
              <div className="form-group" style={{ marginTop: "5px" }}>
                <label className="form-label" style={{ color: "#282826" }}>
                  Datum
                  <span
                    style={{
                      color: "red",
                      fontSize: "12px",
                      marginLeft: "1px",
                      marginRight: "4px",
                    }}
                  >
                    *
                  </span>
                </label>
                <input
                  type="date"
                  className="form-control"
                  name="signDate"
                  value={signDate}
                  onChange={(e) => setSignDate(e.target.value)}
                  disabled={disabled}
                />
                {errors.signDate && (
                  <div style={invalidFeedbackStyle}>{errors.signDate}</div>
                )}
              </div>
            </div>
            <div className="col-md-4 col-sm-12">
              <div className="form-group">
                <label className="form-label" style={{ color: "#282826" }}>
                  {t("Unterschrift")}
                  <span
                    style={{
                      color: "red",
                      fontSize: "12px",
                      marginLeft: "1px",
                      marginRight: "4px",
                    }}
                  >
                    *
                  </span>
                </label>
                <div>
                  <img src={signUrl}></img>
                  <button
                    className="btn btn-info"
                    style={{
                      height: "35px",
                      width: "100px",
                      padding: "2px",
                      marginTop: "10px",
                      fontWeight: 600,
                    }}
                    onClick={() => setSignaturePadOpen(true)}
                    disabled={disabled}
                  >
                    ZEICHEN
                  </button>
                  {errors.signUrl && (
                    <div style={invalidFeedbackStyle}>{errors.signUrl}</div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="card-footer text-right">
        <button
          className="btn btn-primary"
          style={{ textTransform: "uppercase" }}
          disabled={disabled}
          onClick={handleSubmit}
        >
          {t("submit")}
        </button>
        {disabled && (
          <>
            <button
              className="btn btn-primary ml-10"
              style={{ textTransform: "uppercase" }}
              onClick={handleEdit}
            >
              {t("edit")}
            </button>
          </>
        )}
        {editMode && (
          <>
            <button
              className="btn btn-info ml-10"
              style={{ textTransform: "uppercase" }}
              onClick={handleCancel}
            >
              {t("cancel")}
            </button>
          </>
        )}
      </div>
      <Dialog
        open={signaturePadOpen}
        style={{ backdropFilter: "blur(2px)" }}
        fullWidth
      >
        <div className="mx-auto" style={{ backgroundColor: "transparent" }}>
          <SignaturePad
            closeSignaturePad={closeSignaturePad}
            setSignUrlFromPad={setSignUrlFromPad}
          />
        </div>
      </Dialog>
      <Dialog
        open={isLoading}
        style={{ backdropFilter: "blur(2px)" }}
        fullWidth
      >
        <div
          className="flex flex-col justify-center"
          style={{ backgroundColor: "transparent" }}
        >
          <div className="mx-auto" style={{ backgroundColor: "transparent" }}>
            <Loading />
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default CardDeliveryForm;

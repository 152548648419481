import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import {
  getRaisedTickets,
  raiseSupportTicket,
  submitFeedBack,
} from "../../../services/apiService";
import { toast } from "react-toastify";

const Support = () => {
  const fixNavbar = useSelector((state) => state.settings.isFixNavbar);
  const [feedback, setFeedback] = useState("");
  const [supportIssue, setSupportIssue] = useState("");
  const [data, setData] = useState([]);
  const { t } = useTranslation("common");

  useEffect(() => {
    getRaisedTicketsData();
  }, []);

  useEffect(() => {}, [data]);

  const getRaisedTicketsData = async () => {
    try {
      const response = await getRaisedTickets(localStorage.token);
      setData(response);
    } catch (error) {
      console.log(error);
    }
  };

  const handleFeedbackSubmit = async (e) => {
    e.preventDefault();
    const feedbackRequest = {
      name: localStorage.userName,
      email: localStorage.userEmail,
      feedback: feedback,
    };
    const response = await submitFeedBack(localStorage.token, feedbackRequest);
    if (!response) {
      toast.error("Something went wrong. Feedback was not saved");
    } else {
      toast.success("Thank you for your feedback!");
    }
    setFeedback("");
  };

  const handleSupportSubmit = async (e) => {
    e.preventDefault();
    const request = {
      name: localStorage.userName,
      email: localStorage.userEmail,
      issue: supportIssue,
    };
    const response = await raiseSupportTicket(localStorage.token, request);
    if (!response) {
      toast.error("Something went wrong. Ticket was not saved");
    } else {
      toast.success("Request Raised successully!");
      getRaisedTicketsData();
    }
    setSupportIssue("");
  };

  return (
    <>
      <div>
        <div className={`section-body ${fixNavbar ? "marginTop" : ""} `}>
          <div className="container-fluid mt-4">
            <div className="row">
              <div className="col-md-6 col-lg-6">
                <div className="card">
                  <div className="card-header">
                    <h3 className="card-title">{t("contactInformation")}</h3>
                  </div>
                  <div className="card-body">
                    <p className="text-muted">{t("reachUsBy")}:</p>
                    <label className="form-label">Boardi.co GmbH</label>
                    <label className="form-label">Merkurring 82</label>
                    <label className="form-label">22143 Hamburg</label>
                    <label className="form-label">
                      Email: kundenservice@boardi.co
                    </label>
                    <h3 className="card-title mt-5">{t("giveFeedback")}</h3>
                    <form onSubmit={handleFeedbackSubmit}>
                      <div className="mb-3">
                        <label htmlFor="feedback" className="form-label">
                          {t("yourFeedback")}
                        </label>
                        <textarea
                          className="form-control"
                          id="feedback"
                          rows="4"
                          required
                          value={feedback}
                          onChange={(e) => setFeedback(e.target.value)}
                        />
                      </div>
                      <button type="submit" className="btn btn-primary">
                        {t("submitFeedback")}
                      </button>
                    </form>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-lg-6">
                <div className="card">
                  <div className="card-header">
                    <h3 className="card-title">{t("contactSupport")}</h3>
                  </div>
                  <div className="card-body">
                    <p className="text-muted">{t("needAssistance")}</p>
                    <form onSubmit={handleSupportSubmit}>
                      <div className="mb-3">
                        <label htmlFor="supportName" className="form-label">
                          {t("yourName")}
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="supportName"
                          required
                          defaultValue={localStorage.userName}
                          disabled
                        />
                      </div>
                      <div className="mb-3">
                        <label htmlFor="supportEmail" className="form-label">
                          {t("yourEmail")}
                        </label>
                        <input
                          type="email"
                          className="form-control"
                          id="supportEmail"
                          required
                          defaultValue={localStorage.userEmail}
                          disabled
                        />
                      </div>
                      <div className="mb-3">
                        <label htmlFor="supportIssue" className="form-label">
                          {t("describeYourIssue")}
                        </label>
                        <textarea
                          className="form-control"
                          id="supportIssue"
                          rows="4"
                          required
                          value={supportIssue}
                          onChange={(e) => setSupportIssue(e.target.value)}
                        />
                      </div>
                      <button type="submit" className="btn btn-primary mb-2">
                        {t("submitSupportRequest")}
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="card">
                <div className="card-header">
                  <h3 className="card-title">{t("raisedTickets")}</h3>
                  {/* <div className="card-options">
                                        <form>
                                            <div className="input-group">
                                                <input type="text" className="form-control form-control-sm" placeholder="Search something..." name="s" />
                                                <span className="input-group-btn ml-2"><button className="btn btn-icon" type="submit"><span className="fe fe-search" /></button></span>
                                            </div>
                                        </form>
                                    </div> */}
                </div>
                <div className="card-body">
                  <div className="table-responsive">
                    <table className="table table-hover table-striped table-vcenter mb-0">
                      <thead>
                        <tr>
                          <th className="w60">{t("ticketId")}</th>
                          <th className="w200">{t("issue")}</th>
                          <th className="w80">{t("raisedOn")}</th>
                          <th className="w80">Status</th>
                          <th className="w80">{t("response")}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {data.map((ticket) => (
                          <tr key={ticket.ticketId}>
                            <td>{ticket.ticketId}</td>
                            <td className="w200">{ticket.issue}</td>
                            <td className="w80">{ticket.raisedOn}</td>
                            <td className="w80">{ticket.status}</td>
                            <td className="w80">{ticket.response}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Support;

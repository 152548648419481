import axios from "axios";

const API_BASE_URL = "https://api.boardi.co";

const axiosInstance = (token) => {
  return axios.create({
    baseURL: API_BASE_URL,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};

const tokenApiInstance = (token) => {
  return axios.create({
    baseURL: API_BASE_URL,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};

export const createCompany = async (token, data) => {
  try {
    const response = await axiosInstance(token).post("/company", data);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getCompanyList = async (token) => {
  try {
    const response = await axiosInstance(token).get("/company/list");
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const editCompany = async (token, data) => {
  try {
    const response = await axiosInstance(token).post("/company/edit", data);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const deleteCompanyById = async (token, companyId) => {
  try {
    const response = await axiosInstance(token).get(
      "/company/" + companyId + "/delete"
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const validateToken = async (token) => {
  try {
    const response = await tokenApiInstance(token).get("/validate/token");
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getCompanyDropDownList = async (token) => {
  try {
    const response = await axiosInstance(token).get("/company/dropdown/list");
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getManagerDropDown = async (token) => {
  try {
    const response = await axiosInstance(token).get("/company/manager/list");
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getAgentDashboard = async (token) => {
  try {
    const response = await axiosInstance(token).get("/agent/dashboard");
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getCompanyDashboard = async (token) => {
  try {
    const response = await axiosInstance(token).get("/company/dashboard");
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getCompanyDetailsById = async (token, id) => {
  try {
    const response = await axiosInstance(token).get(
      "/company/" + id + "/details"
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const addManager = async (id, data, token) => {
  try {
    const response = await axiosInstance(token).post(
      "/company/" + id + "/manager",
      data
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

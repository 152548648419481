import React, { Component } from "react";
import { connect } from "react-redux";
import MetisMenu from "react-metismenu";
import { Switch, Route, NavLink } from "react-router-dom";
import Header from "../Shared/Header";
import Footer from "../Shared/Footer";
import DefaultLink from "./DefaultLink";
import { withTranslation } from "react-i18next";
import Employee from "../HRMS/Employee/Employee";
import Activities from "../HRMS/Activities/Activities";
import Charts from "../Charts/Charts";
import ChartsE from "../Charts/ChartsE";
import ChartsC3 from "../Charts/ChartsC3";
import ChartsKnob from "../Charts/ChartsKnob";
import ChartsSparkline from "../Charts/ChartsSparkline";
import Search from "../Search/Search";
import Login from "../Authentication/login";
import Signup from "../Authentication/signup";
import ForgotPassword from "../Authentication/forgotpassword";
import NotFound from "../Authentication/404";
import InternalServer from "../Authentication/500";
import Dashboard from "../HRMS/Dashboard/Dashboard";
import CompanyProfile from "../HRMS/Company/Company";
import Accounts from "../HRMS/Accounts/Accounts";
import { withRouter } from "react-router-dom";
import { compose } from "redux";
import ProjectDashboard from "../Project/Dashboard/Dashboard";
import JobPortalDashboard from "../JobPortal/Dashboard/Dashboard";

import Widgets from "../Widgets/Widgets";
import WCard from "../Widgets/WCard";
import WStatistics from "../Widgets/WStatistics";
import WData from "../Widgets/WData";
import WSocial from "../Widgets/WSocial";
import WOther from "../Widgets/WOther";
import Taskboard from "../Project/Taskboard/Taskboard";
import TicketList from "../Project/TicketList/TicketList";
import TicketDetails from "../Project/TicketDetails/TicketDetails";

import {
  darkModeAction,
  darkHeaderAction,
  fixNavbarAction,
  darkMinSidebarAction,
  darkSidebarAction,
  iconColorAction,
  gradientColorAction,
  rtlAction,
  fontAction,
  subMenuIconAction,
  menuIconAction,
  boxLayoutAction,
  statisticsAction,
  friendListAction,
  statisticsCloseAction,
  friendListCloseAction,
  toggleLeftMenuAction,
} from "../../actions/settingsAction";
import EmployeeDetails from "../HRMS/Employee/EmployeeDetails";
import EmailCenter from "../HRMS/EmailCenter/EmailCenter";
import UserActivity from "../HRMS/Activities/UserActivity";
import Support from "../HRMS/Support/Support";
import AccountSettings from "../HRMS/AccountSettings/AccountSettings";
import Companies from "../HRMS/Stores/Companies";
import { validateToken } from "../../services/companyService";
import AgentProfile from "../HRMS/Company/Agent";
import CompanyEmailCenter from "../HRMS/EmailCenter/CompanyEmailCenter";
import DataProtectionPolicy from "../Authentication/DataProtectionPolicy";
import TermsAndConditions from "../Authentication/TermsAndConditions";
import ManagerDashboard from "../HRMS/Dashboard/ManagerDashboard";
import DataImportPage from "../HRMS/Import/DataImportPage";
import CompanyDetails from "../HRMS/Stores/CompanyDetails";

const masterNone = {
  display: "none",
};

const masterBlock = {
  display: "block",
};

class Menu extends Component {
  constructor(props) {
    super(props);
    this.toggleLeftMenu = this.toggleLeftMenu.bind(this);
    this.toggleUserMenu = this.toggleUserMenu.bind(this);
    this.toggleRightSidebar = this.toggleRightSidebar.bind(this);
    this.toggleSubMenu = this.toggleSubMenu.bind(this);
    this.handleDarkMode = this.handleDarkMode.bind(this);
    this.handleFixNavbar = this.handleFixNavbar.bind(this);
    this.handleDarkHeader = this.handleDarkHeader.bind(this);
    this.handleMinSidebar = this.handleMinSidebar.bind(this);
    this.handleSidebar = this.handleSidebar.bind(this);
    this.handleIconColor = this.handleIconColor.bind(this);
    this.handleGradientColor = this.handleGradientColor.bind(this);
    this.handleRtl = this.handleRtl.bind(this);
    this.handleFont = this.handleFont.bind(this);
    this.handleStatistics = this.handleStatistics.bind(this);
    this.handleFriendList = this.handleFriendList.bind(this);
    this.closeFriendList = this.closeFriendList.bind(this);
    this.closeStatistics = this.closeStatistics.bind(this);
    this.handleBoxLayout = this.handleBoxLayout.bind(this);
    this.handler = this.handler.bind(this);
    this.windowWidth = window.innerWidth;
    this.state = {
      isToggleLeftMenu: false,
      isOpenUserMenu: false,
      isOpenRightSidebar: false,
      isBoxLayout: false,
      parentlink: null,
      childlink: null,
    };
  }

  componentDidMount() {
    this.validateUserLoggedIn();
    const { location } = this.props;
    const links = location.pathname.substring(1).split(/-(.+)/);
    const parentlink = links[0];
    const nochildlink = links[1];
    window.addEventListener("resize", this.handleResize);

    if (parentlink && nochildlink && nochildlink === "dashboard") {
      this.handler(parentlink, `${parentlink}${nochildlink}`);
    } else if (parentlink && nochildlink && nochildlink !== "dashboard") {
      this.handler(parentlink, nochildlink);
    } else if (parentlink) {
      this.handler(parentlink, "");
    } else {
      this.handler("hr", "dashboard");
    }
  }

  validateUserLoggedIn = async () => {
    const { history } = this.props;
    try {
      const token = localStorage.getItem("token");
      if (!token) {
        history.push("/login");
      } else {
        console.log("calling validate token");
        const response = await validateToken(token);
        console.log("validate token call ", response);
      }
    } catch (error) {
      if (error.isAxiosError && error.response) {
        console.error("Server returned an error:", error.response.data);
      } else if (error.isAxiosError) {
        console.error("Request failed without a response:", error.message);
      } else {
        console.error("Error setting up the request:", error.message);
      }
      history.push("/login");
    }
  };

  componentDidUpdate(prevprops, prevstate) {
    const { location } = this.props;
    const links = location.pathname.substring(1).split(/-(.+)/);
    const parentlink = links[0];
    const nochildlink = links[1];
    if (prevprops.location !== location) {
      if (parentlink && nochildlink && nochildlink === "dashboard") {
        this.handler(parentlink, `${parentlink}${nochildlink}`);
      } else if (parentlink && nochildlink && nochildlink !== "dashboard") {
        this.handler(parentlink, nochildlink);
      } else if (parentlink) {
        this.handler(parentlink, "");
      } else {
        this.handler("hr", "dashboard");
      }
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }

  handleResize = () => {
    this.setState({ windowWidth: window.innerWidth });
  };

  handler(parentlink, nochildlink) {
    this.setState({
      parentlink: parentlink,
      childlink: nochildlink,
    });
  }

  handleDarkMode(e) {
    this.props.darkModeAction(e.target.checked);
  }
  handleFixNavbar(e) {
    this.props.fixNavbarAction(e.target.checked);
  }
  handleDarkHeader(e) {
    this.props.darkHeaderAction(e.target.checked);
  }
  handleMinSidebar(e) {
    this.props.darkMinSidebarAction(e.target.checked);
  }
  handleSidebar(e) {
    this.props.darkSidebarAction(e.target.checked);
  }
  handleIconColor(e) {
    this.props.iconColorAction(e.target.checked);
  }
  handleGradientColor(e) {
    this.props.gradientColorAction(e.target.checked);
  }
  handleRtl(e) {
    this.props.rtlAction(e.target.checked);
  }
  handleFont(e) {
    this.props.fontAction(e);
  }
  handleFriendList(e) {
    this.props.friendListAction(e);
  }
  handleStatistics(e) {
    this.props.statisticsAction(e);
  }
  closeFriendList(e) {
    this.props.friendListCloseAction(e);
  }
  closeStatistics(e) {
    this.props.statisticsCloseAction(e);
  }
  handleSubMenuIcon(e) {
    this.props.subMenuIconAction(e);
  }
  handleMenuIcon(e) {
    this.props.menuIconAction(e);
  }
  handleBoxLayout(e) {
    this.props.boxLayoutAction(e.target.checked);
  }
  toggleLeftMenu(e) {
    this.props.toggleLeftMenuAction(e);
  }
  toggleRightSidebar() {
    this.setState({ isOpenRightSidebar: !this.state.isOpenRightSidebar });
  }
  toggleUserMenu() {
    this.setState({ isOpenUserMenu: !this.state.isOpenUserMenu });
  }

  logout = () => {
    const { history } = this.props;
    localStorage.removeItem("token");
    localStorage.removeItem("userName");
    localStorage.removeItem("userEmail");
    localStorage.removeItem("userCompany");
    localStorage.removeItem("authorities");
    localStorage.removeItem("token");
    localStorage.removeItem("companyId");
    history.push("/login");
  };

  toggleSubMenu(e) {
    let menucClass = "";
    if (e.itemId) {
      const subClass = e.items.map((menuItem, i) => {
        if (menuItem.to === this.props.location.pathname) {
          menucClass = "in";
        } else {
          menucClass = "collapse";
        }
        return menucClass;
      });
      return subClass;
      // return "collapse";
    } else {
      return e.visible ? "collapse" : "metismenu";
    }
  }

  render() {
    const { t } = this.props;

    const Routes = [
      {
        path: "/data/import",
        name: "dataImport",
        exact: true,
        pageTitle: t("dataImport"),
        component: DataImportPage,
      },
      {
        path: "/project-taskboard",
        name: "project-taskboard",
        exact: true,
        pageTitle: "Taskboard",
        component: Taskboard,
      },

      {
        path: "/project-ticket",
        name: "project-ticket",
        exact: true,
        pageTitle: "Ticket List",
        component: TicketList,
      },

      {
        path: "/project-ticket-details",
        name: "project-ticket-details",
        exact: true,
        pageTitle: "Ticket Details",
        component: TicketDetails,
      },
      {
        path: "/widgets",
        name: "widgets",
        exact: true,
        pageTitle: "Widgets",
        component: Widgets,
      },
      {
        path: "/w-card",
        name: "w-card",
        exact: true,
        pageTitle: "Widgets",
        component: WCard,
      },
      {
        path: "/w-statistics",
        name: "w-statistics",
        exact: true,
        pageTitle: "Widgets",
        component: WStatistics,
      },
      {
        path: "/w-data",
        name: "w-data",
        exact: true,
        pageTitle: "Widgets",
        component: WData,
      },
      {
        path: "/w-social",
        name: "w-social",
        exact: true,
        pageTitle: "Widgets",
        component: WSocial,
      },
      {
        path: "/w-other",
        name: "w-other",
        exact: true,
        pageTitle: "Widgets",
        component: WOther,
      },
      {
        path: "/company/details",
        name: "companyProfile",
        exact: true,
        pageTitle: "Company Profile",
        component: CompanyProfile,
      },
      {
        path: "/agent/details",
        name: "agentDetails",
        exact: true,
        pageTitle: "Agent Profile",
        component: AgentProfile,
      },
      {
        path: "/account/settings",
        name: "accountSettings",
        exact: true,
        pageTitle: t("accountSettings"),
        component: AccountSettings,
      },
      {
        path: "/support",
        name: "support",
        exact: true,
        pageTitle: "Support",
        component: Support,
      },
      {
        path: "/company/info",
        name: "companyDetails",
        exact: true,
        pageTitle: "Company Detail",
        component: CompanyDetails,
      },
      {
        path: "/user/activities",
        name: "userActivities",
        exact: true,
        pageTitle: t("activityList"),
        component: UserActivity,
      },
      {
        path: "/employee/details",
        name: "employeeDetails",
        exact: true,
        pageTitle: "Mitarbeiterdetails",
        component: EmployeeDetails,
      },
      {
        path: "/email/center",
        name: "emailCenter",
        exact: true,
        pageTitle: "Email Center",
        component: EmailCenter,
      },
      {
        path: "/company/email/center",
        name: "emailCenter",
        exact: true,
        pageTitle: "Email Center",
        component: CompanyEmailCenter,
      },
      {
        path: "/",
        name: "dashboard",
        exact: true,
        pageTitle: "Dashboard",
        component: Dashboard,
      },
      {
        path: "/manager/dashboard",
        name: "dashboard",
        exact: true,
        pageTitle: "Dashboard",
        component: ManagerDashboard,
      },
      {
        path: "/employee",
        name: "employee",
        exact: true,
        pageTitle: t("employees"),
        component: Employee,
      },
      {
        path: "/hr-activities",
        name: "activities",
        exact: true,
        pageTitle: "Activities",
        component: Activities,
      },
      {
        path: "/company-list",
        name: "companies",
        exact: true,
        pageTitle: t("companyList"),
        component: Companies,
      },
      {
        path: "/login",
        name: "login",
        exact: true,
        pageTitle: "Tables",
        component: Login,
      },
      {
        path: "/signup",
        name: "signup",
        exact: true,
        pageTitle: "Tables",
        component: Signup,
      },
      ,
      {
        path: "/data/protection/policy",
        name: "dataProtectionPolicy",
        exact: true,
        component: DataProtectionPolicy,
      },
      {
        path: "/terms/conditions",
        name: "termsAndConditions",
        exact: true,
        component: TermsAndConditions,
      },
      {
        path: "/forgotpassword",
        name: "forgotpassword",
        exact: true,
        pageTitle: "Tables",
        component: ForgotPassword,
      },
      {
        path: "/notfound",
        name: "notfound",
        exact: true,
        pageTitle: "Tables",
        component: NotFound,
      },
      {
        path: "/internalserver",
        name: "internalserver",
        exact: true,
        pageTitle: "Tables",
        component: InternalServer,
      },
      {
        path: "/charts",
        name: "charts",
        exact: true,
        pageTitle: "Charts",
        component: Charts,
      },
      {
        path: "/charts-e",
        name: "charts-e",
        exact: true,
        pageTitle: "Charts",
        component: ChartsE,
      },
      {
        path: "/charts-c3",
        name: "charts-c3",
        exact: true,
        pageTitle: "Charts",
        component: ChartsC3,
      },
      {
        path: "/charts-knob",
        name: "charts-knob",
        exact: true,
        pageTitle: "Charts",
        component: ChartsKnob,
      },
      {
        path: "/charts-sparkline",
        name: "charts-sparkline",
        exact: true,
        pageTitle: "Charts",
        component: ChartsSparkline,
      },
      {
        path: "/page-search",
        name: "page-search",
        exact: true,
        pageTitle: "Search",
        component: Search,
      },
      {
        path: "/accounts",
        name: "accounts",
        exact: true,
        pageTitle: t("accounting"),
        component: Accounts,
      },
      {
        path: "/project-dashboard",
        name: "projectDashboard",
        exact: true,
        pageTitle: "'Project Dashboard",
        component: ProjectDashboard,
      },
      {
        path: "/jobportal-dashboard",
        name: "jobportalDashboard",
        exact: true,
        pageTitle: "Job Dashboard",
        component: JobPortalDashboard,
      },
    ];

    const getRoleBasedContent = () => {
      // const employeeMenu = [
      //   {
      //     id: 1,
      //     icon: "icon-user",
      //     label: t("myProfile"),
      //     to: "/employee/details",
      //   },
      //   {
      //     id: 2,
      //     icon: "icon-home",
      //     label: t("storeDetails"),
      //     to: "/store/details",
      //   },
      //   {
      //     id: 42,
      //     icon: "icon-globe-alt",
      //     label: t("companyProfile"),
      //     to: "/company/details",
      //   },
      //   {
      //     id: 42,
      //     icon: "icon-support",
      //     label: "Support",
      //     to: "/support",
      //   },
      // ];

      const superAdminMenu = [
        // {
        // 	"id": 'Directories',
        // 	"label": "Directories"
        // },
        {
          id: 3,
          icon: "icon-bar-chart",
          label: "Dashboard",
          to: "/",
        },
        {
          id: 6,
          icon: "icon-users",
          label: t("employees"),
          to: "/employee",
        },
        {
          id: 40,
          icon: "icon-folder-alt",
          label: t("emailCenter"),
          to: "/email/center",
        },
        {
          id: 15,
          icon: "icon-home",
          label: t("companies"),
          to: "/company-list",
        },
        {
          id: 16,
          icon: "icon-wallet",
          label: t("accounting"),
          to: "/accounts",
        },
        {
          id: 45,
          icon: "icon-wrench",
          label: t("accountSettings"),
          to: "/account/settings",
        },
        {
          id: 50,
          icon: "icon-globe-alt",
          label: t("myProfile"),
          to: "/agent/details",
        },
        {
          id: 51,
          icon: "icon-cloud-upload",
          label: t("dataImport"),
          to: "/data/import",
        },
        {
          id: 52,
          icon: "icon-support",
          label: t("support"),
          to: "/support",
        },
      ];

      const adminMenu = [
        {
          id: 3,
          icon: "icon-bar-chart",
          label: "Dashboard",
          to: "/manager/dashboard",
        },
        {
          id: 6,
          icon: "icon-users",
          label: t("employees"),
          to: "/employee",
        },
        {
          id: 40,
          icon: "icon-folder-alt",
          label: "Email Center",
          to: "/company/email/center",
        },
        {
          id: 49,
          icon: "icon-globe-alt",
          label: t("companyProfile"),
          to: "/company/details",
        },
        {
          id: 51,
          icon: "icon-cloud-upload",
          label: t("dataImport"),
          to: "/data/import",
        },
        {
          id: 52,
          icon: "icon-support",
          label: "Support",
          to: "/support",
        },
      ];

      return localStorage.role === "ROLE_ADMIN" ? adminMenu : superAdminMenu;
    };

    const content = getRoleBasedContent();

    const { isOpenRightSidebar, isOpenUserMenu, windowWidth } = this.state;
    const {
      darkMinSidebar,
      istoggleLeftMenu,
      friendListOpen,
      statisticsOpen,
      statisticsClose,
      friendListClose,
    } = this.props;
    const pageHeading = Routes.filter(
      (route) => route.path === this.props.location.pathname
    );

    return (
      <>
        <div className={`${istoggleLeftMenu ? "offcanvas-active" : ""}`}>
          <div
            style={this.state.parentlink === "login" ? masterNone : masterBlock}
          >
            <div
              id="header_top"
              className={`header_top ${darkMinSidebar && "dark"}`}
            >
              <div className="container">
                <div className="hleft">
                  <NavLink
                    to={
                      localStorage.role === "ROLE_ADMIN"
                        ? "/manager/dashboard"
                        : "/"
                    }
                    //onClick={() => this.handler("hr", "dashboard")}
                    className="header-brand"
                  >
                    <img
                      src="https://boardi.co/assets/images/logo_icon_1.png"
                      className="img-fluid"
                      alt="/"
                      style={{ width: "35px", height: "35px" }}
                    ></img>
                  </NavLink>
                  <div
                    className="dropdown"
                    style={{ display: windowWidth < 780 ? "none" : "block" }}
                  >
                    {istoggleLeftMenu &&
                      content.slice(0, 9).map((item) => (
                        <>
                          <NavLink
                            key={item.id}
                            to={item.to}
                            className="nav-link icon"
                          >
                            <i
                              className={item.icon}
                              style={{ fontSize: "16px", fontWeight: 600 }}
                            />
                          </NavLink>
                        </>
                      ))}
                    {/* <NavLink to="/app-calendar" className="nav-link icon app_inbox">
											<i className="fa fa-calendar" />
										</NavLink>
										<NavLink to="/app-contact" className="nav-link icon xs-hide">
											<i className="fa fa-id-card-o" />
										</NavLink>
										<NavLink to="/app-chat" className="nav-link icon xs-hide">
											<i className="fa fa-comments-o" />
										</NavLink>
										<NavLink to="/app-filemanager" className="nav-link icon app_file xs-hide">
											<i className="fa fa-folder-o" />
										</NavLink> */}
                  </div>
                </div>
                <div className="hright">
                  <div className="dropdown">
                    {/* <a href="#!" className="nav-link icon theme_btn mb-2">
										<i
											className="fa fa-paint-brush"
											data-toggle="tooltip"
											data-placement="right"
											title="Themes"
										></i>
										</a> */}
                    <span
                      className="nav-link icon mb-1"
                      onClick={() => {
                        window.location.reload(true);
                      }}
                    >
                      <i className="icon-refresh" title="Refresh" />
                    </span>
                    <span className="nav-link icon mb-1" onClick={this.logout}>
                      <i className="icon-logout" title="Logout" />
                    </span>
                    {/* <span className="nav-link icon settingbar mb-3" onClick={this.toggleRightSidebar}>
											<i
												className="fa fa-gear fa-spin"
												data-toggle="tooltip"
												data-placement="right"
												title="Settings"
											/>
										</span> */}
                    <p
                      className="nav-link user_btn mb-0 p-0"
                      onClick={this.toggleUserMenu}
                    >
                      <img
                        className="avatar"
                        src="/assets/images/user.png"
                        alt="fake_alr"
                        data-toggle="tooltip"
                        data-placement="right"
                        title="User Menu"
                      />
                    </p>
                    <p
                      className="nav-link icon menu_toggle mb-1"
                      onClick={() => this.toggleLeftMenu(!istoggleLeftMenu)}
                    >
                      <i className="fa  fa-align-left" />
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div
              id="rightsidebar"
              className={`right_sidebar ${isOpenRightSidebar && "open"}`}
            >
              <span
                className="p-3 settingbar float-right"
                onClick={this.toggleRightSidebar}
              >
                <i className="fa fa-close" />
              </span>
              <ul className="nav nav-tabs" role="tablist">
                <li className="nav-item">
                  <a
                    className="nav-link active"
                    data-toggle="tab"
                    href="#Settings"
                    aria-expanded="true"
                  >
                    Settings
                  </a>
                </li>
                {/* <li className="nav-item">
									<a className="nav-link" data-toggle="tab" href="#activity" aria-expanded="false">
										Activity
								</a>
								</li> */}
              </ul>
              <div className="tab-content">
                <div
                  role="tabpanel"
                  className="tab-pane vivify fadeIn active"
                  id="Settings"
                  aria-expanded="true"
                >
                  <div className="mb-4">
                    <h6 className="font-14 font-weight-bold text-muted">
                      Font Style
                    </h6>
                    <div className="custom-controls-stacked font_setting">
                      <label className="custom-control custom-radio custom-control-inline">
                        <input
                          type="radio"
                          className="custom-control-input"
                          name="font"
                          defaultChecked
                          defaultValue="font-opensans"
                          onChange={() => this.handleFont("font-opensans")}
                        />
                        <span className="custom-control-label">
                          Open Sans Font
                        </span>
                      </label>
                      <label className="custom-control custom-radio custom-control-inline">
                        <input
                          type="radio"
                          className="custom-control-input"
                          name="font"
                          defaultValue="font-montserrat"
                          onChange={() => this.handleFont("font-montserrat")}
                        />
                        <span className="custom-control-label">
                          Montserrat Google Font
                        </span>
                      </label>
                      <label className="custom-control custom-radio custom-control-inline">
                        <input
                          type="radio"
                          className="custom-control-input"
                          name="font"
                          onChange={() => this.handleFont("font-roboto")}
                        />
                        <span className="custom-control-label">
                          Robot Google Font
                        </span>
                      </label>
                    </div>
                  </div>
                  <div className="mb-4">
                    <h6 className="font-14 font-weight-bold text-muted">
                      Selected Menu Icon
                    </h6>
                    <div className="custom-controls-stacked arrow_option">
                      <label className="custom-control custom-radio custom-control-inline">
                        <input
                          type="radio"
                          className="custom-control-input"
                          name="marrow"
                          defaultValue="arrow-a"
                          onChange={() => this.handleMenuIcon("list-a")}
                        />
                        <span className="custom-control-label">A</span>
                      </label>
                      <label className="custom-control custom-radio custom-control-inline">
                        <input
                          type="radio"
                          className="custom-control-input"
                          name="marrow"
                          defaultValue="arrow-b"
                          onChange={() => this.handleMenuIcon("list-b")}
                        />
                        <span className="custom-control-label">B</span>
                      </label>
                      <label className="custom-control custom-radio custom-control-inline">
                        <input
                          type="radio"
                          className="custom-control-input"
                          name="marrow"
                          defaultValue="arrow-c"
                          defaultChecked
                          onChange={() => this.handleMenuIcon("list-c")}
                        />
                        <span className="custom-control-label">C</span>
                      </label>
                    </div>

                    <h6 className="font-14 font-weight-bold mt-4 text-muted">
                      SubMenu List Icon
                    </h6>
                    <div className="custom-controls-stacked list_option">
                      <label className="custom-control custom-radio custom-control-inline">
                        <input
                          type="radio"
                          className="custom-control-input"
                          name="listicon"
                          defaultValue="list-a"
                          defaultChecked
                          onChange={() => this.handleSubMenuIcon("list-a")}
                        />
                        <span className="custom-control-label">A</span>
                      </label>
                      <label className="custom-control custom-radio custom-control-inline">
                        <input
                          type="radio"
                          className="custom-control-input"
                          name="listicon"
                          defaultValue="list-b"
                          onChange={() => this.handleSubMenuIcon("list-b")}
                        />
                        <span className="custom-control-label">B</span>
                      </label>
                      <label className="custom-control custom-radio custom-control-inline">
                        <input
                          type="radio"
                          className="custom-control-input"
                          name="listicon"
                          defaultValue="list-c"
                          onChange={() => this.handleSubMenuIcon("list-c")}
                        />
                        <span className="custom-control-label">C</span>
                      </label>
                    </div>
                  </div>
                  <div>
                    <h6 className="font-14 font-weight-bold mt-4 text-muted">
                      General Settings
                    </h6>
                    <ul className="setting-list list-unstyled mt-1 setting_switch">
                      <li>
                        <label className="custom-switch">
                          <span className="custom-switch-description">
                            Night Mode
                          </span>
                          <input
                            type="checkbox"
                            name="custom-switch-checkbox"
                            className="custom-switch-input btn-darkmode"
                            onChange={(e) => this.handleDarkMode(e)}
                          />
                          <span className="custom-switch-indicator" />
                        </label>
                      </li>
                      <li>
                        <label className="custom-switch">
                          <span className="custom-switch-description">
                            Fix Navbar top
                          </span>
                          <input
                            type="checkbox"
                            name="custom-switch-checkbox"
                            className="custom-switch-input btn-fixnavbar"
                            onChange={(e) => this.handleFixNavbar(e)}
                          />
                          <span className="custom-switch-indicator" />
                        </label>
                      </li>
                      <li>
                        <label className="custom-switch">
                          <span className="custom-switch-description">
                            Header Dark
                          </span>
                          <input
                            type="checkbox"
                            name="custom-switch-checkbox"
                            className="custom-switch-input btn-pageheader"
                            onChange={(e) => this.handleDarkHeader(e)}
                          />
                          <span className="custom-switch-indicator" />
                        </label>
                      </li>
                      <li>
                        <label className="custom-switch">
                          <span className="custom-switch-description">
                            Min Sidebar Dark
                          </span>
                          <input
                            type="checkbox"
                            name="custom-switch-checkbox"
                            className="custom-switch-input btn-min_sidebar"
                            onChange={(e) => this.handleMinSidebar(e)}
                          />
                          <span className="custom-switch-indicator" />
                        </label>
                      </li>
                      <li>
                        <label className="custom-switch">
                          <span className="custom-switch-description">
                            Sidebar Dark
                          </span>
                          <input
                            type="checkbox"
                            name="custom-switch-checkbox"
                            className="custom-switch-input btn-sidebar"
                            onChange={(e) => this.handleSidebar(e)}
                          />
                          <span className="custom-switch-indicator" />
                        </label>
                      </li>
                      <li>
                        <label className="custom-switch">
                          <span className="custom-switch-description">
                            Icon Color
                          </span>
                          <input
                            type="checkbox"
                            name="custom-switch-checkbox"
                            className="custom-switch-input btn-iconcolor"
                            onChange={(e) => this.handleIconColor(e)}
                          />
                          <span className="custom-switch-indicator" />
                        </label>
                      </li>
                      <li>
                        <label className="custom-switch">
                          <span className="custom-switch-description">
                            Gradient Color
                          </span>
                          <input
                            type="checkbox"
                            name="custom-switch-checkbox"
                            className="custom-switch-input btn-gradient"
                            onChange={(e) => this.handleGradientColor(e)}
                          />
                          <span className="custom-switch-indicator" />
                        </label>
                      </li>

                      {/* <li>
												<label className="custom-switch">
													<span className="custom-switch-description">RTL Support</span>
													<input
														type="checkbox"
														name="custom-switch-checkbox"
														className="custom-switch-input btn-rtl"
														onChange={(e) => this.handleRtl(e)}
													/>
													<span className="custom-switch-indicator" />
												</label>
											</li> */}
                    </ul>
                  </div>
                  <hr />
                  {/* <div className="form-group">
										<label className="d-block">
											Storage <span className="float-right">77%</span>
										</label>
										<div className="progress progress-sm">
											<div
												className="progress-bar"
												role="progressbar"
												aria-valuenow={77}
												aria-valuemin={0}
												aria-valuemax={100}
												style={{ width: '77%' }}
											/>
										</div>
										<button type="button" className="btn btn-primary btn-block mt-3">
											Upgrade Storage
									</button>
									</div> */}
                </div>
                <div
                  role="tabpanel"
                  className="tab-pane vivify fadeIn"
                  id="activity"
                  aria-expanded="false"
                >
                  <ul className="new_timeline mt-3">
                    <li>
                      <div className="bullet pink" />
                      <div className="time">11:00am</div>
                      <div className="desc">
                        <h3>Attendance</h3>
                        <h4>Computer Class</h4>
                      </div>
                    </li>
                    <li>
                      <div className="bullet pink" />
                      <div className="time">11:30am</div>
                      <div className="desc">
                        <h3>Added an interest</h3>
                        <h4>“Volunteer Activities”</h4>
                      </div>
                    </li>
                    <li>
                      <div className="bullet green" />
                      <div className="time">12:00pm</div>
                      <div className="desc">
                        <h3>Developer Team</h3>
                        <h4>Hangouts</h4>
                        <ul className="list-unstyled team-info margin-0 p-t-5">
                          <li>
                            <img
                              src="/assets/images/xs/avatar1.jpg"
                              alt="Avatar"
                            />
                          </li>
                          <li>
                            <img
                              src="/assets/images/xs/avatar2.jpg"
                              alt="Avatar"
                            />
                          </li>
                          <li>
                            <img
                              src="/assets/images/xs/avatar3.jpg"
                              alt="Avatar"
                            />
                          </li>
                          <li>
                            <img
                              src="/assets/images/xs/avatar4.jpg"
                              alt="Avatar"
                            />
                          </li>
                        </ul>
                      </div>
                    </li>
                    <li>
                      <div className="bullet green" />
                      <div className="time">2:00pm</div>
                      <div className="desc">
                        <h3>Responded to need</h3>
                        <a href="#!">“In-Kind Opportunity”</a>
                      </div>
                    </li>
                    <li>
                      <div className="bullet orange" />
                      <div className="time">1:30pm</div>
                      <div className="desc">
                        <h3>Lunch Break</h3>
                      </div>
                    </li>
                    <li>
                      <div className="bullet green" />
                      <div className="time">2:38pm</div>
                      <div className="desc">
                        <h3>Finish</h3>
                        <h4>Go to Home</h4>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="theme_div">
              <div className="card">
                <div className="card-body">
                  <ul className="list-group list-unstyled">
                    <li className="list-group-item mb-2">
                      <p>Default Theme</p>
                      <a href="../main/index.html">
                        <img
                          src="/assets/images/themes/default.png"
                          className="img-fluid"
                          alt="fake_url"
                        />
                      </a>
                    </li>
                    <li className="list-group-item mb-2">
                      <p>Night Mode Theme</p>
                      <a href="../dark/index.html">
                        <img
                          src="/assets/images/themes/dark.png"
                          className="img-fluid"
                          alt="fake_url"
                        />
                      </a>
                    </li>
                    <li className="list-group-item mb-2">
                      <p>RTL Version</p>
                      <a href="../rtl/index.html">
                        <img
                          src="/assets/images/themes/rtl.png"
                          className="img-fluid"
                          alt="fake_url"
                        />
                      </a>
                    </li>
                    <li className="list-group-item mb-2">
                      <p>Theme Version2</p>
                      <a href="../theme2/index.html">
                        <img
                          src="/assets/images/themes/theme2.png"
                          className="img-fluid"
                          alt="fake_url"
                        />
                      </a>
                    </li>
                    <li className="list-group-item mb-2">
                      <p>Theme Version3</p>
                      <a href="../theme3/index.html">
                        <img
                          src="/assets/images/themes/theme3.png"
                          className="img-fluid"
                          alt="fake_url"
                        />
                      </a>
                    </li>
                    <li className="list-group-item mb-2">
                      <p>Theme Version4</p>
                      <a href="../theme4/index.html">
                        <img
                          src="/assets/images/themes/theme4.png"
                          className="img-fluid"
                          alt="fake_url"
                        />
                      </a>
                    </li>
                    <li className="list-group-item mb-2">
                      <p>Horizontal Version</p>
                      <a href="../horizontal/index.html">
                        <img
                          src="/assets/images/themes/horizontal.png"
                          className="img-fluid"
                          alt="fake_url"
                        />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className={`user_div ${isOpenUserMenu && "open"}`}>
              <h5 className="brand-name mb-4">
                <img
                  src="https://boardi.co/assets/images/logo_2.png"
                  className="img-fluid"
                  alt="/"
                  style={{ width: "200px" }}
                ></img>
                <p className="user_btn" onClick={this.toggleUserMenu}>
                  <i className="icon-logout" />
                </p>
              </h5>
              <div className="card">
                <div className="card-body">
                  <div className="media">
                    <img
                      className="avatar avatar-xl mr-3"
                      src={localStorage.imageUrl || "/assets/images/user.png"}
                      alt="avatar"
                    />
                    <div className="media-body">
                      <h5 className="m-0">{localStorage.userName}</h5>
                      <p className="text-muted mb-0">
                        {localStorage.userEmail}
                      </p>
                      {/* <ul className="social-links list-inline mb-0 mt-2">
                        <li className="list-inline-item">
                          <a
                            href="#!"
                            title="fake_title"
                            data-toggle="tooltip"
                            data-original-title="Facebook"
                          >
                            <i className="fa fa-facebook" />
                          </a>
                        </li>
                        <li className="list-inline-item">
                          <a
                            href="#!"
                            title="fake_title"
                            data-toggle="tooltip"
                            data-original-title="Twitter"
                          >
                            <i className="fa fa-twitter" />
                          </a>
                        </li>
                        <li className="list-inline-item">
                          <a
                            href="#!"
                            title="fake_title"
                            data-toggle="tooltip"
                            data-original-title={1234567890}
                          >
                            <i className="fa fa-phone" />
                          </a>
                        </li>
                        <li className="list-inline-item">
                          <a
                            href="#!"
                            title="fake_title"
                            data-toggle="tooltip"
                            data-original-title="@skypename"
                          >
                            <i className="fa fa-skype" />
                          </a>
                        </li>
                      </ul> */}
                    </div>
                  </div>
                </div>
              </div>
              {/* {statisticsClose ?
								<div className={`card ${statisticsOpen ? 'card-collapsed' : ""}`}>
									< div className="card-header">
										<h3 className="card-title">Statistics</h3>
										<div className="card-options">
											<span className="card-options-collapse" data-toggle="card-collapse" onClick={() => this.handleStatistics(!statisticsOpen)}>
												<i className="fe fe-chevron-up" />
											</span>
											<span className="card-options-remove" data-toggle="card-remove" onClick={() => this.closeStatistics(false)}>
												<i className="fe fe-x" />
											</span>
										</div>
									</div>
									<div className="card-body">
										<div className="text-center">
											<div className="row">
												<div className="col-6 pb-3">
													<label className="mb-0">Balance</label>
													<h4 className="font-30 font-weight-bold">$545</h4>
												</div>
												<div className="col-6 pb-3">
													<label className="mb-0">Growth</label>
													<h4 className="font-30 font-weight-bold">27%</h4>
												</div>
											</div>
										</div>
										<div className="form-group">
											<label className="d-block">
												Total Income<span className="float-right">77%</span>
											</label>
											<div className="progress progress-xs">
												<div
													className="progress-bar bg-blue"
													role="progressbar"
													aria-valuenow={77}
													aria-valuemin={0}
													aria-valuemax={100}
													style={{ width: '77%' }}
												/>
											</div>
										</div>
										<div className="form-group">
											<label className="d-block">
												Total Expenses <span className="float-right">50%</span>
											</label>
											<div className="progress progress-xs">
												<div
													className="progress-bar bg-danger"
													role="progressbar"
													aria-valuenow={50}
													aria-valuemin={0}
													aria-valuemax={100}
													style={{ width: '50%' }}
												/>
											</div>
										</div>
										<div className="form-group mb-0">
											<label className="d-block">
												Gross Profit <span className="float-right">23%</span>
											</label>
											<div className="progress progress-xs">
												<div
													className="progress-bar bg-green"
													role="progressbar"
													aria-valuenow={23}
													aria-valuemin={0}
													aria-valuemax={100}
													style={{ width: '23%' }}
												/>
											</div>
										</div>
									</div>
								</div> : ""}
							{friendListClose ?
								<div className={`card ${friendListOpen ? 'card-collapsed' : ""}`}>
									<div className="card-header">
										<h3 className="card-title">Friends</h3>
										<div className="card-options">
											<span className="card-options-collapse" data-toggle="card-collapse" onClick={() => this.handleFriendList(!friendListOpen)}>
												<i className="fe fe-chevron-up" />
											</span>
											<span className="card-options-remove" data-toggle="card-remove" onClick={() => this.closeFriendList(false)}>
												<i className="fe fe-x" />
											</span>
										</div>
									</div>
									<div className="card-body">
										<ul className="right_chat list-unstyled">
											<li className="online">
												<a href="#!">
													<div className="media">
														<img className="media-object " src="../assets/images/xs/avatar4.jpg" alt="fake_alr" />
														<div className="media-body">
															<span className="name">Donald Gardner</span>
															<span className="message">Designer, Blogger</span>
															<span className="badge badge-outline status" />
														</div>
													</div>
												</a>
											</li>
											<li className="online">
												<a href="#!">
													<div className="media">
														<img
															className="media-object "
															src="/assets/images/xs/avatar5.jpg"
															alt="fake_alr"
														/>
														<div className="media-body">
															<span className="name">Wendy Keen</span>
															<span className="message">Java Developer</span>
															<span className="badge badge-outline status" />
														</div>
													</div>
												</a>
											</li>
											<li className="offline">
												<a href="#!">
													<div className="media">
														<img
															className="media-object "
															src="/assets/images/xs/avatar2.jpg"
															alt="fake_alr"
														/>
														<div className="media-body">
															<span className="name">Matt Rosales</span>
															<span className="message">CEO, Epic Theme</span>
															<span className="badge badge-outline status" />
														</div>
													</div>
												</a>
											</li>
										</ul>
									</div>
								</div>
								: ""} */}
              {/* <div className="card b-none">
								<ul className="list-group">
									<li className="list-group-item d-flex">
										<div className="box-icon sm rounded bg-blue">
											<i className="fa fa-credit-card" />{' '}
										</div>
										<div className="ml-3">
											<div>+$29 New sale</div>
											<a href="#!">Bespoke Agent Portal</a>
											<div className="text-muted font-12">5 min ago</div>
										</div>
									</li>
									<li className="list-group-item d-flex">
										<div className="box-icon sm rounded bg-pink">
											<i className="fa fa-upload" />{' '}
										</div>
										<div className="ml-3">
											<div>Project Update</div>
											<a href="#!">New HTML page</a>
											<div className="text-muted font-12">10 min ago</div>
										</div>
									</li>
									<li className="list-group-item d-flex">
										<div className="box-icon sm rounded bg-teal">
											<i className="fa fa-file-word-o" />{' '}
										</div>
										<div className="ml-3">
											<div>You edited the file</div>
											<a href="#!">reposrt.doc</a>
											<div className="text-muted font-12">11 min ago</div>
										</div>
									</li>
									<li className="list-group-item d-flex">
										<div className="box-icon sm rounded bg-cyan">
											<i className="fa fa-user" />{' '}
										</div>
										<div className="ml-3">
											<div>New user</div>
											<a href="#!">Puffin web - view</a>
											<div className="text-muted font-12">17 min ago</div>
										</div>
									</li>
								</ul>
							</div> */}
            </div>
            <div
              id="left-sidebar"
              className="sidebar"
              style={{ paddingTop: "10px" }}
            >
              <h5 className="brand-name">
                <img
                  src="https://boardi.co/assets/images/logo_1.png"
                  className="img-fluid"
                  alt="/"
                  style={{ width: "150px" }}
                />
              </h5>
              <nav id="left-sidebar-nav" className="sidebar-nav mt-4">
                <MetisMenu
                  className=""
                  content={content}
                  noBuiltInClassNames={true}
                  classNameContainer={(e) => this.toggleSubMenu(e)}
                  classNameContainerVisible="in"
                  classNameItemActive="active"
                  classNameLinkActive="active"
                  // classNameItemHasActiveChild="active"
                  classNameItemHasVisibleChild="active"
                  classNameLink="has-arrow arrow-c"
                  // classNameIcon
                  // classNameStateIcon

                  iconNamePrefix=""
                  // iconNameStateHidden=""
                  LinkComponent={(e) => <DefaultLink itemProps={e} />}
                  // toggleSubMenu={this.toggleSubMenu}
                />
              </nav>
            </div>
          </div>

          <div className="page">
            <Header
              dataFromParent={this.props.dataFromParent}
              dataFromSubParent={pageHeading[0].pageTitle}
            />
            <Switch>
              {Routes.map((layout, i) => {
                return (
                  <Route
                    key={i}
                    exact={layout.exact}
                    path={layout.path}
                    component={layout.component}
                  ></Route>
                );
              })}
              {/* <Route path="/employee/details/:id"exact={true} component={EmployeeDetails} /> */}
            </Switch>
            <Footer />
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  darkMinSidebar: state.settings.isMinSidebar,
  statisticsOpen: state.settings.isStatistics,
  friendListOpen: state.settings.isFriendList,
  statisticsClose: state.settings.isStatisticsClose,
  friendListClose: state.settings.isFriendListClose,
  istoggleLeftMenu: state.settings.isToggleLeftMenu,
});

const mapDispatchToProps = (dispatch) => ({
  darkModeAction: (e) => dispatch(darkModeAction(e)),
  darkHeaderAction: (e) => dispatch(darkHeaderAction(e)),
  fixNavbarAction: (e) => dispatch(fixNavbarAction(e)),
  darkMinSidebarAction: (e) => dispatch(darkMinSidebarAction(e)),
  darkSidebarAction: (e) => dispatch(darkSidebarAction(e)),
  iconColorAction: (e) => dispatch(iconColorAction(e)),
  gradientColorAction: (e) => dispatch(gradientColorAction(e)),
  rtlAction: (e) => dispatch(rtlAction(e)),
  fontAction: (e) => dispatch(fontAction(e)),
  subMenuIconAction: (e) => dispatch(subMenuIconAction(e)),
  menuIconAction: (e) => dispatch(menuIconAction(e)),
  boxLayoutAction: (e) => dispatch(boxLayoutAction(e)),
  statisticsAction: (e) => dispatch(statisticsAction(e)),
  friendListAction: (e) => dispatch(friendListAction(e)),
  statisticsCloseAction: (e) => dispatch(statisticsCloseAction(e)),
  friendListCloseAction: (e) => dispatch(friendListCloseAction(e)),
  toggleLeftMenuAction: (e) => dispatch(toggleLeftMenuAction(e)),
});
export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation("menu")
)(Menu);
